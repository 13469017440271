<layout-container [mode]="LayoutContainerModes.fullWidth" class="page-padding">
  <div class="timeline-page-container flex flex-col">
    <div class="flex max-md:flex-col-reverse flex-center-start gap-36">
      <news-list *authCan="timelineNewsFeature" [newsList]="newsList" class="flex-[1_2_auto]"></news-list>
      <aside *authCanSome="timelineAsideFeatures" class="flex flex-col flex-initial gap-36">
        <cv-box *authCan="timelineCvBoxFeature"></cv-box>
        <event-box *authCan="timelineEventBoxFeature"></event-box>
        <video-update-box *authCan="timelineVideoUpdateBoxFeature" 
                          [preview]="prieviewMode$ | async" 
                          [id]="videoUpdateId"></video-update-box>
        <request-transfer *authCan="timelineRequestTransferFeature"></request-transfer>
      </aside>
    </div>
  </div>
</layout-container>
