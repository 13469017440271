<div class="content-filter-container flex flex-center-center"
     *ngIf="isFilterVisible$ | async">
  <div class="flex flex-start-center gap-12">
    <div>Sorting:</div>
    <mat-button-toggle-group name="sortingMode" 
                             aria-label="Content mode" 
                             *ngIf="sortingMode$ | async as sortingMode"
                             (change)="sortingModeUpdated($event)">
      <mat-button-toggle [disableRipple]="true" 
                         color="primary" 
                         [value]="CmsSortingModes.NewestFirst"
                         [checked]="sortingMode === CmsSortingModes.NewestFirst">
        Newest first
      </mat-button-toggle>
      <mat-button-toggle [disableRipple]="true" 
                         color="primary" 
                         [value]="CmsSortingModes.OldestFirst"
                         [checked]="sortingMode === CmsSortingModes.OldestFirst">
        Oldest first
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<div class="cards-container">
  <cms-card [details]="card" 
            *ngFor="let card of cardsList; index as i; trackBy: trackByCardId"></cms-card>
</div>
