import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, combineLatest, defer, filter, of, switchMap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { AuthorizationService } from '../services/authorization.service';
import { UserProfileService } from '../services/user-profile.service';
import { NavigationService } from '../services/navigation.service';
import { INavigationItem } from '../interfaces/navigation-item';
import { SPECIAL_REDIRECT } from '../interfaces/authorization';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authorizationSevice: AuthorizationService,
    private authenticationService: AuthenticationService,
    private userProfile: UserProfileService,
    private navigationService: NavigationService
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.canActivate(route, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const requiredPrivilegesSome = route.data['privileges'] || [];
    const requiredPrivilegesAll = route.data['privilegesAll'] || [];
    const specialRedirect: string = route.data.specialRedirect;

    return combineLatest([
      this.authenticationService.isInitializing$,
      this.authenticationService.authenticated$,
      this.authenticationService.postLoginUrl$,
      this.authorizationSevice.canSome(requiredPrivilegesSome),
      this.authorizationSevice.canAll(requiredPrivilegesAll),
      this.checkForB2CError(),
      this.checkForTerms(route),
      this.checkForWelcome(route),
      this.navigationService.primaryNavigation$,
      this.authenticationService.oauthError$
    ]).pipe(
      filter(([isInitializing]) => !isInitializing),
      switchMap(
        ([
          isInitializing,
          isAuthenticated,
          postLoginUrl,
          canSome,
          canAll,
          b2cError,
          hasAgreedToTerms,
          hasSeenWelcomeScreen,
          navItems,
          oauthError
        ]) => {
          if (oauthError) {
            return this.redirectToB2CErrorPage('oauth_error');
          }

          if (!isAuthenticated) {
            return of(this.router.parseUrl('/unauthorized'));
          }

          if (b2cError) {
            return this.redirectToB2CErrorPage(b2cError);
          }

          if (!canSome || !canAll) {
            console.debug(
              `[AuthGuard] Current user has no privileges to view this page: ${state.url}`,
              route.data.privileges
            );
            return this.redirectToFirstAvailablePage(specialRedirect, navItems);
          }

          if (postLoginUrl) {
            this.authenticationService.clearPostLoginUrl(); // clear so it works only once
            return this.redirectToPostLoginUrl(postLoginUrl);
          }

          if (!hasAgreedToTerms) {
            return this.redirectToAgreementPage();
          }

          if (!hasSeenWelcomeScreen) {
            return this.redirectToWelcomePage();
          }

          return of(true);
        }
      )
    );
  }

  private checkForB2CError(): Observable<string | null> {
    const isB2CError = location.href.match(/error_description=([A-Z0-9]+)/);
    if (isB2CError && isB2CError[1]) {
      return of(isB2CError[1]);
    } else {
      return of(null);
    }
  }

  private redirectToB2CErrorPage(errorCode: string): Observable<boolean> {
    console.debug(
      `[AuthGuard] Redirected to B2C error page with code ${errorCode}`
    );
    this.router.navigate([`/b2c-error/${errorCode}`], {
      skipLocationChange: true
    });

    return of(false);
  }

  private checkForTerms(route: ActivatedRouteSnapshot): Observable<boolean> {
    return defer(() => this.userProfile.hasAgreedToTerms()).pipe(
      switchMap((hasAgreed) => {
        if (!route.data.noTerms && !hasAgreed) {
          return of(false);
        } else {
          return of(true);
        }
      })
    );
  }

  private checkForWelcome(route: ActivatedRouteSnapshot): Observable<boolean> {
    return defer(() => this.userProfile.hasSeenWelcomeScreen()).pipe(
      switchMap((hasSeenWelcomeScreen) => {
        if (!route.data.noWelcome && !hasSeenWelcomeScreen) {
          return of(false);
        } else {
          return of(true);
        }
      })
    );
  }

  private redirectToAgreementPage(): Observable<boolean> {
    this.redirectToPage('/agreement');
    return of(false);
  }

  private redirectToWelcomePage(): Observable<boolean> {
    this.redirectToPage('/welcome');
    return of(false);
  }

  private redirectToPostLoginUrl(postLoginUrl: string): Observable<boolean> {
    this.redirectToPage(postLoginUrl);
    return of(false);
  }

  private redirectToPage(page: string) {
    console.debug(`[AuthGuard] Redirected to the ${page} page`);
    this.router.navigateByUrl(page);
  }

  private redirectToFirstAvailablePage(
    specialRedirect: string,
    navItems: Array<INavigationItem>
  ): Observable<boolean> {
    if (specialRedirect === SPECIAL_REDIRECT && navItems?.length > 0) {
      this.redirectToPage(navItems[0].link);
    } else {
      this.redirectToPage('/unauthorized');
    }

    return of(false);
  }
}
