import { Injectable } from '@angular/core';
import { ContentfulClientApi, createClient } from 'contentful';
import { AppConfig } from '../app-config';
import { CMS_SPACE_ID } from '../interfaces/cms';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-html-renderer';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  private _cdaClient: ContentfulClientApi;
  private _previewClient: ContentfulClientApi; // preview client for drafts
  private readonly API_CMS_VERSION = 'v1';
  private readonly API_CMS_PATH = 'cms-proxy';
  private readonly API_CMS_SERVICE =
    this.appConfig.serviceUrl(ServiceNames.Cms, this.API_CMS_VERSION) +
    this.API_CMS_PATH;

  readonly defaultSectionRendererOptions: Partial<Options> = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        const { url } = node.data.target.fields.file;
        return `<img src=${url} />`;
      }
    }
  };

  private accessToken: string;

  get cdaClient(): ContentfulClientApi {
    return this._cdaClient;
  }

  get previewClient(): ContentfulClientApi {
    return this._previewClient;
  }

  constructor(
    private appConfig: AppConfig,
    private authenticationService: AuthenticationService
  ) {
    this.initTokenSubscriptions();
  }

  initClients() {
    this._cdaClient = this.createClient();
    this._previewClient = this.createClient(true);
  }

  private initTokenSubscriptions() {
    this.authenticationService.token$.subscribe((token) => {
      if (token) {
        this.accessToken = token;
        this.initClients();
      }
    });
  }

  private createClient(preview = false): ContentfulClientApi {
    // thanks to the adapter feature of SDK, we can inject our own configuration
    // frist, we have to extract some strings which we will use in the SDK configuration
    const serviceHost = this.API_CMS_SERVICE.split('/')[2];
    const protocol = this.API_CMS_SERVICE.split('/')[0]; // http: or https:

    return createClient({
      space: CMS_SPACE_ID,
      accessToken: this.accessToken,
      host: serviceHost,
      adapter: async (config) => {
        config.url = config.baseURL + '/' + config.url; // fix for Angular 9+
        const url = new URL(`${config?.url}`);
        const previewUrlNode = preview ? '/preview' : '';
        url.pathname = `/api/${this.API_CMS_VERSION}/${
          this.API_CMS_PATH + previewUrlNode + url.pathname
        }`;
        url.protocol = protocol;

        if (config.params) {
          for (const key of Object.keys(config.params)) {
            url.searchParams.append(key, config.params[key]);
          }
        }

        const request = new Request(url.href, {
          method: config.method ? config.method.toUpperCase() : 'GET',
          body: config.data,
          redirect: 'manual',
          headers: config.headers ? config.headers : {}
        });

        const response = await fetch(request);

        return {
          data: await response.json(),
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          config: config,
          request: request
        };
      }
    });
  }
}
