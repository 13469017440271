export const DEFAULT_VALID_FILE_EXT: string[] = [
  'jpg',
  'jpeg',
  'gif',
  'png',
  'docx',
  'doc',
  'pdf',
  'xls',
  'xlsx',
  'txt'
].map((x) => x.toLocaleUpperCase());

export const CV_VALID_FILE_EXT: string[] = ['docx', 'doc', 'pdf'].map((x) =>
  x.toLocaleUpperCase()
);

export const BILLING_ATTACHMENT_VALID_FILE_EXT: string[] = ['pdf', 'png', 'jpg', 'jpeg', 'xls', 'xlsx'].map((x) =>
  x.toLocaleUpperCase()
);

export const AVATAR_VALID_FILE_EXT: string[] = ['png', 'jpg', 'jpeg'].map((x) =>
  x.toLocaleUpperCase()
);

export const PDF_VALID_FILE_EXT: string = 'pdf'.toLocaleUpperCase();

export interface IFileItem {
  Name: string;
  Size: number;
  Type: string;
  BlobUrl: string;
}
