import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
  VerticalConnectionPos
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';

// Services
import { ProfileStrengthService } from '../../../services/profile-strength.service';

@Component({
  selector: 'popover',
  templateUrl: './popover.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PopoverComponent implements OnInit, OnDestroy {
  @Input() position: string;
  @Input() addLabel: string;
  @Input() editLabel = '';
  @Input('activate-with') activateWith?: string;
  @ViewChild('popoverActivation', { read: ElementRef, static: true })
  popoverActivation: ElementRef;
  @ContentChild('popoverContent', { static: true })
  popoverContent: TemplatePortal<any>;
  @Input()
  set empty(value: boolean) {
    this._empty = value;
    this.label = this._empty ? this.addLabel : this.editLabel;
  }

  get empty() {
    return this._empty;
  }

  panelClass: Array<string> = ['popover'];
  lockBody = false;
  editMode = false;
  label = '';

  private _empty: boolean;
  private overlayRef: OverlayRef;
  private profileStrengthSubscription: Subscription;
  private overlayBackdropClickSubscription: Subscription;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private profileStrengthService: ProfileStrengthService = null
  ) {}

  ngOnInit() {
    if (this.profileStrengthService) {
      this.profileStrengthSubscription =
        this.profileStrengthService.activate$.subscribe((section) => {
          if (this.activateWith && section === this.activateWith) {
            this.activate();
          }
        });
    }

    this.preapareOverlayClasses();
  }

  activate() {
    if (!this.editMode) {
      const positionStrategy: FlexibleConnectedPositionStrategy =
        this.createPositionStrategy(this.popoverActivation);

      if (this.lockBody) {
        document.body.classList.add('popup-opened');
      }

      this.overlayRef = this.overlay.create({
        panelClass: this.panelClass,
        positionStrategy: positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
        hasBackdrop: true,
        backdropClass: ['cdk-overlay-transparent-backdrop', 'show-on-mobile']
      });

      this.overlayBackdropClickSubscription = this.overlayRef
        .backdropClick()
        .subscribe((event) => {
          // Possible to prevent all
          // event.preventDefault();
          // event.stopImmediatePropagation();
          //
          // but we want to close everything...
          this.closePopover({
            result: 'cancel'
          });
        });

      this.overlayRef.keydownEvents().subscribe((value) => {
        if (value.keyCode === 27) {
          // ESCape cannot use key name here because browsers name it differently
          console.debug('[PopoverComponent] closing popover with ESC key');
          this.closePopover({
            result: 'cancel'
          });
        }
      });

      // !!!!!!!!!!
      // Passing popover context with $implicit gives ability to close popover using components placed inside
      this.popoverContent.context = { $implicit: this };
      this.overlayRef.attach(this.popoverContent);

      this.editMode = true;
    }
  }

  closePopover(result) {
    this.overlayBackdropClickSubscription.unsubscribe();
    this.overlayRef.detach();
    this.overlayRef.dispose();
    this.overlayRef = null;
    this.editMode = false;
    document.body.classList.remove('popup-opened');
  }

  updatePopoverPosition() {
    if (this.overlayRef) {
      this.overlayRef.updatePosition();
    }
  }

  private readonly POPOVER_ARROW_SIZE = 15;

  preapareOverlayClasses() {
    this.panelClass.push(this.position);
  }

  private createPositionStrategy(
    elementRef: ElementRef
  ): FlexibleConnectedPositionStrategy {
    let offsetX = 0;
    let offsetY = 0;
    let overlayX: HorizontalConnectionPos = 'center';
    let overlayY: VerticalConnectionPos = 'center';
    let originX: HorizontalConnectionPos = 'center';
    let originY: VerticalConnectionPos = 'center';

    switch (this.position) {
      case 'right':
        offsetX = this.POPOVER_ARROW_SIZE;
        overlayX = 'start';
        originX = 'end';
        break;
      case 'left':
        offsetX = -this.POPOVER_ARROW_SIZE;
        overlayX = 'end';
        originX = 'start';
        break;
      case 'top':
        offsetY = -this.POPOVER_ARROW_SIZE;
        overlayY = 'bottom';
        originY = 'top';
        break;
      case 'bottom':
        offsetY = this.POPOVER_ARROW_SIZE;
        overlayY = 'top';
        originY = 'bottom';
        break;
    }

    return this.overlayPositionBuilder
      .flexibleConnectedTo(elementRef)
      .withPositions([
        {
          originX: originX,
          overlayX: overlayX,
          originY: originY,
          overlayY: overlayY
        }
      ])
      .withDefaultOffsetX(offsetX)
      .withDefaultOffsetY(offsetY);
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
    }

    if (this.profileStrengthSubscription) {
      this.profileStrengthSubscription.unsubscribe();
    }
  }
}
