<ng-select class="skills-autocomplete"
           [formControl]="control"
           [clearable]="false"
           [items]="skills"
           [multiple]="!single"
           [virtualScroll]="true"
           [maxSelectedItems]="maxSelectedItems"
           [ngClass]="{'chip-limit-reached': skillsSelectedCount == maxSelectedItems, 'last-selected-for-deletion': lastItemSelectedForDeletion}"
           [appendTo]="appendTo"
           (blur)="onTouched()"
           (open)="onDropdownOpen($event)"
           bindLabel="Name"
           placeholder="e.g. HTML" #skillsNgSelect>
  <ng-template ng-multi-label-tmp let-items="items" *ngIf="!single" let-clear="clear">
    <div class="ng-value skill pill" *ngFor="let item of items; let i = index" [ngClass]="{'last': i === items.length - 1 }">
      <span class="ng-value-label">{{ item['Name']}}</span>
      <span class="pill-remove"><mat-icon (click)="clear(item)" aria-hidden="true" svgIcon="close_tiny"></mat-icon></span>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <div class="ng-option-label multiline">
      <div class="title">
        <span [innerHTML]="item['Name']" [ngOptionHighlight]="search" [attr.title]="item['Name']"></span>
      </div>
      <div class="subtext">
        <span>{{ item['CategoryName'] }}</span>
      </div>
    </div>
  </ng-template>
  <ng-template ng-notfound-tmp>
    <div class="skill-not-found">No skills found</div>
  </ng-template>
  <ng-template ng-footer-tmp *ngIf="allowAddingSkills">
    <span class="skills-autocomplete-footer-text item-create-skill" *ngIf="skillsNgSelect.searchTerm" (click)="createSkill(skillsNgSelect.searchTerm)">Add new skill &quot;{{skillsNgSelect.searchTerm}}&quot;</span>
    <span class="skills-autocomplete-footer-text skill-hint" *ngIf="!skillsNgSelect.searchTerm">Start typing to find your skill or create a custom one</span>
  </ng-template>
</ng-select>
