import {Injectable} from '@angular/core';
import {ProfileStrengthKeys} from '../../interfaces/profile-strength';
import {CvCommonService} from './cv-common.service';

@Injectable()
export class CvAdditionalInformationService {
  constructor(private cvCommonService: CvCommonService) {}

  updateApplicationType = this.cvCommonService.commonPutPostUpdate(ProfileStrengthKeys.AdditionalInformations, '/apptypes/');
  updateNetwork = this.cvCommonService.commonPutPostUpdate(ProfileStrengthKeys.Networks, '/networks/');
  updateAward = this.cvCommonService.commonPutPostUpdate(ProfileStrengthKeys.Awards, '/awards/');

  removeApplicationType = this.cvCommonService.commonRemove('/apptypes/');
  removeNetwork = this.cvCommonService.commonRemove('/networks/');
  removeAward = this.cvCommonService.commonRemove('/awards/');
}
