<div #newsContainer
     class="news-container"
     data-test-id="news-container"
     [ngClass]="{ 'has-video': hasVideo }">

  <!-- news image -->
  <div class="news-image flex"
       *ngIf="hasImage && !hasVideo && !hasGallery"
       data-test-id="news-container-news-image"
       [ngStyle]="{
         'width': imageSize.width + 'px',
         'height': imageSize.height + 'px',
         'background-image': 'url(' + bannerImage + ')'
       }">
  </div>

  <!-- news video -->
  <div class="news-video iframe-video"
       *ngIf="hasVideo && !hasGallery"
       data-test-id="news-container-news-video"
       [ngStyle]="{
         'width': videoPlayerConfig.containerWidth + 'px',
         'height': videoPlayerConfig.containerHeight + 'px'
       }"
  >
    <div class="video-plug"
         (click)="show()"
         *ngIf="!showVideo"
         data-test-id="news-container-news-video-plug"
         [ngStyle]="{
           'width': videoPlayerConfig.containerWidth + 'px',
           'height': videoPlayerConfig.containerHeight + 'px',
           'background-image': 'url(' + bannerImage + ')'
         }">
    </div>
    <my7n-video-player *ngIf="showVideo"
                       [videoUrl]="news?.VideoUrl"
                       [module]="LogVideoModules.News" 
                       [config]="videoPlayerConfig"
                       [title]="news?.Title"
                       [articleId]="news?.Id"
                       data-test-id="news-container-news-video-player"></my7n-video-player>
  </div>

  <!-- gallery -->
  <ng-container *ngIf="hasGallery && galleryPhotos$ | async as gallery">
    <div class="news-gallery"
         data-test-id="news-gallery"
         *ngIf="gallery.FilesData.length">
      <sharepoint-gallery [items]="gallery.FilesData"
                          [newsId]="newsId"
                          [config]="galleryConfig"></sharepoint-gallery>
    </div>
  </ng-container>

  <div class="news-content flex flex-col">
    <!-- news title -->
    <h3 class="news-title"
        *ngIf="news.Title"
        data-test-id="news-container-news-title">
      <a [routerLink]="detailsUrl">{{news.Title | truncate: titleLimit: true}}</a>
    </h3>

    <!-- news description with more link -->
    <div class="news-description" data-test-id="news-container-news-description">
      <p class="news-description-text"
         *ngIf="news.Description"
         [innerHTML]="news.Description | removeByTags: ['table', 'a'] | truncate: descriptionLimit:true"
         data-test-id="news-container-news-description-text"></p>
      <a [routerLink]="detailsUrl" 
         type="button" 
         mat-button 
         color="primary"
         data-test-id="news-description-read-more">Read more</a>
    </div>
    <hr/>
    <!-- news footer -->
    <div class="news-footer flex flex-row flex-between-center"
         data-test-id="news-container-news-footer">
      <div class="footer-author-container flex max-sm:flex-col sm:gap-24 max-sm:flex-start-start sm:max-md:flex-between-center md:flex-start-center">
         <!-- news author info -->
        <div class="news-author-info flex flex-start-center md:gap-12">
          <div class="news-author-info-avatar max-md:hidden"
              data-test-id="news-container-news-author-info-avatar">
            <avatar [avatarUrl]="news.AuthorAvatar" [title]="news.AuthorName"></avatar>
          </div>
          <div class="news-author-info-name-posted flex flex-col flex-[1_1_0%]"
              data-test-id="news-container-news-author-info-name-posted">
            <span class="news-author-info-name">{{news.AuthorName}}</span>
            <span class="news-author-info-job-title">{{news.AuthorJobTitle}}</span>
          </div>
        </div>

        <!-- news dates -->
        <div class="news-dates flex flex-start-center gap-12"
            data-test-id="news-container-news-dates">
          <mat-icon class="max-md:hidden" svgIcon="calendar_big"></mat-icon>
          <span class="news-author-info-posted">{{news.Published | defaultDate: 'minute': true}}</span>
        </div>
      </div>
      <reactions [contentId]="news.Id" [contentType]="contentType" data-test-id="reactions"></reactions>
    </div>
  </div>
</div>
