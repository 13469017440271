import { Component, OnInit, Input, Inject, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IMy7nEnvConfig } from '../../../interfaces/my7n-env-config';
import { MY7N_ENV_CONFIG } from '../../../functions/my7n-env-config';

@Component({
  selector: 'google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnChanges {
  @Input() location: string;

  iframeSrc: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer,
              @Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig) { }

  ngOnInit() {
  }

  ngOnChanges() {
    const escapedLocation = encodeURI(this.location.replace(/^ul\./, '').trim());
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.google.com/maps/embed/v1/place?q=' + escapedLocation + '&key=' + this.envConfig.GOOGLE_API_KEY);
  }

}
