import {NgModule} from '@angular/core';
import {CvService} from '../services/cv/cv.service';
import {CvListService} from '../services/cv/cv-list.service';
import {CvCommonService} from '../services/cv/cv-common.service';
import {CvParseService} from '../services/cv/cv-parse.service';
import {CvSkillsService} from '../services/cv/cv-skills.service';
import {CvProfileSummaryService} from '../services/cv/cv-profile-summary.service';
import {CvEducationService} from '../services/cv/cv-education.service';
import {CvCareerService} from '../services/cv/cv-career.service';
import {CvUserExperienceService} from '../services/cv/cv-user-experience.service';
import {CvAdditionalInformationService} from '../services/cv/cv-additional-information.service';

@NgModule({
  imports: [],
  providers: [
    CvService,
    CvListService,
    CvCommonService,
    CvParseService,
    CvSkillsService,
    CvProfileSummaryService,
    CvEducationService,
    CvCareerService,
    CvUserExperienceService,
    CvAdditionalInformationService
  ]
})
export class My7nCvCommonModule {}
