import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICommonEditSave } from '../interfaces/common-edit-save';

// services
import { AgentService } from './agent.service';
import { UserProfileService } from './user-profile.service';
import {IAddress} from "../interfaces/address";

@Injectable()
export class AddressEditService {

  servicesMethods: object = {
    // consultant view
    updateAddress: (address: IAddress, consultantId: string) => {
      return this.agentService.updateAddress(address, consultantId);
    },

    // profile view
    updateAddressProfile: (address) => {
      return this.userProfileService.updateAddress(address);
    },
  };

  constructor(private agentService: AgentService,  private userProfileService: UserProfileService) { }

  addressEditSave(fieldValue: any, methodName: string, consultantId?: string) {
    return this.servicesMethods[methodName](fieldValue, consultantId);
  }

}
