export enum BusinessUnit {
  Poland = 'Poland',
  Denmark = 'Denmark',
  India = 'India',
  Finland = 'Finland',
  Norway = 'Norway',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  USA = 'USA',
  International = 'International'
}

export const NON_INTERNATIONAL_BU = [
  BusinessUnit.Poland,
  BusinessUnit.Denmark,
  BusinessUnit.India
];
