import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FabAgentService } from '../../../services/fab-agent.service';
import { Router } from '@angular/router';
import { AppConfig } from '../../../app-config';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { IUserAgentData } from '../../../interfaces/user-agent-data';
import { IUserPreferences } from '../../../interfaces/user-preferences';

@Component({
  selector: 'fab-agent',
  templateUrl: './fab-agent.component.html',
  styleUrls: ['./fab-agent.component.scss'],
  animations: [
  trigger('msgContainerVisibility', [
    state('visible', style({
      opacity: 1,
      display: 'block'
    })),
    state('invisible', style ({
      opacity: 0,
      display: 'none'
    })),
    transition('visible <=> invisible', animate('.2s ease-in'))
  ])
]
})
export class FabAgentComponent implements OnInit {
  /**
   * Preferences of currently logged user.
   */
  @Input('user') CurrentUser: IUserPreferences;

  /**
   * Reference to the message container
   */
  @ViewChild('fabAgentMessageContainer') messageContainer;

  /**
   * Available message types.
   * @type {{UPDATE_REMINDER: number; CONTACT_ME: number}}
   */
  static readonly MESSAGE_TYPES = {
    UPDATE_REMINDER: 0,
    CONTACT_ME: 1
  };

  /**
   * Message container visibility states.
   * @type {string}
   */
  static readonly VISIBLE_STATE = 'visible';
  static readonly INVISIBLE_STATE = 'invisible';

  /**
   * Indicates if FabAgent is active;
   */
  active: boolean;

  /**
   * Represents type of message.
   */
  messageType: number;

  /**
   * Agent data.
   */
  Agent: IUserAgentData;

  /**
   * Visibility state of message container.
   * Acceptable values are: visible or invisible.
   * This prop is used to trigger animations.
   * @type {string}
   */
  msgContainerVisibilityState = FabAgentComponent.INVISIBLE_STATE;

  constructor(private router: Router, private fabAgentService: FabAgentService, private config: AppConfig) {
    this.active = false;
    this.messageType = FabAgentComponent.MESSAGE_TYPES.CONTACT_ME;
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.CurrentUser) {
      this.Agent = this.CurrentUser.Agent;

      this.fabAgentService.getReminder()
        .then((reminderState) => {
          this.active = reminderState;
          if (reminderState) {
            this.messageType = FabAgentComponent.MESSAGE_TYPES.UPDATE_REMINDER;
            this.msgContainerVisibilityState = FabAgentComponent.VISIBLE_STATE;
          } else {
            this.messageType = FabAgentComponent.MESSAGE_TYPES.CONTACT_ME;
            this.msgContainerVisibilityState = FabAgentComponent.INVISIBLE_STATE;
          }
        });
    }
  }

  /**
   * Hides agent's message and saves information in config to show it again in the future.
   */
  remindLater() {
    console.debug('[FabAgentComponent] Remind later');
    this.fabAgentService.remindLater();

    // change status to
    this.active = false;

    // hide fab agent message
    this.msgContainerVisibilityState = FabAgentComponent.INVISIBLE_STATE;
  };

  /**
   * Navigates to user profile details.
   */
  updateContactData() {
    console.debug('[FabAgentComponent] updateContactData');
    this.remindLater();
    this.router.navigateByUrl('/profile/details');
  };

  /**
   * Shows/hides message container.
   * If user clicked element inside message container this action will be ignored.
   * @param {Event} event
   */
  toggleMessageView(event) {
    if (this.isMsgContainerChild(event.target)) {
        console.debug('[FabAgentComponent] Clicked from message container! Action aborted.');
        event.stopImmediatePropagation(); // preventDefault prevented from opening mailto and tel links
        return;
    }

    console.debug('[FabAgentComponent] Clicked OUTSIDE message container! change visibility', this.active, !this.active);

    this.active = !this.active;

    if (!this.active) {
      this.msgContainerVisibilityState = FabAgentComponent.INVISIBLE_STATE;
    } else {
      this.msgContainerVisibilityState = FabAgentComponent.VISIBLE_STATE;
    }
  };

  /**
   * Animation end handler.
   * @param {CustomEvent} event
   */
  onAnimationDone(event) {
    if (event.fromState === FabAgentComponent.VISIBLE_STATE && event.toState === FabAgentComponent.INVISIBLE_STATE) {
      this.messageType = FabAgentComponent.MESSAGE_TYPES.CONTACT_ME;
    }
  }

  /**
   * Check if given element is child of message container.
   * @param {HTMLElement} elem
   * @returns {boolean}
   */
  isMsgContainerChild(elem) {
    // Get the closest matching element
    const selector = 'fab-agent-message-container';
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
      if ( elem.classList.contains( selector ) ) return true;
    }
    return false;
  }
}
