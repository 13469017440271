<mat-card class="request-transfer" 
          *ngIf="!requestAlreadySentToday" 
          data-test-id="request-transfer-container">
    <h5 class="font-bold">It is all about perfect match!</h5>
    <p>Are you thinking about something new? Let us know.</p>
    <button class="request-transfer-button"
            mat-flat-button
            color="primary"
            data-test-id="request-transfer-container-button"
            (click)="requestTransfer()"
            [loading]="requestPending">
            I want to change my project.
   </button>
</mat-card>

<mat-card class="request-transfer-sent" 
          *ngIf="requestAlreadySentToday" 
          data-test-id="request-transfer-sent-container">
    <h5 class="font-bold">Thank you for your feedback.</h5>
    <p>Please expect a call from your agent!</p>
</mat-card>
