import { ICheckboxCategoryTreeItem } from './checkbox-category-tree-item';
import { IFilterSkill } from './filter-skill';
import { IFilterAvailabilityOption } from './filter-availability-option';

/**
 * This interface describes data structure stored by search filters.
 */
export interface ISearchFiltersData {
  relationshipTypes: Array<ICheckboxCategoryTreeItem>;
  consultantStatuses: Array<ICheckboxCategoryTreeItem>;
  consultantActiveStatuses: Array<ICheckboxCategoryTreeItem>;
  competenceAreas: Array<ICheckboxCategoryTreeItem>;
  workRoles: Array<ICheckboxCategoryTreeItem>;
  locations: Array<ICheckboxCategoryTreeItem>;
  industryFocus: Array<ICheckboxCategoryTreeItem>;
  owners: Array<ICheckboxCategoryTreeItem>;
  availabilityRange: {
    availabilityFromDate: number,
    availabilityToDate: number
  };
  availability: IFilterAvailabilityOption;
  skills: {
    skillsRestricted: boolean;
    skillsList: Array<IFilterSkill>;
  };
  languageSkills: {
    languagesRestricted: boolean;
    languagesList: Array<IFilterSkill>;
  };
  privateAddressCountry: Array<ICheckboxCategoryTreeItem>;
  cvLanguages: Array<ICheckboxCategoryTreeItem>;
}
export interface ISearchFavouriteFiltersResponse {
  Filters: Array<ISearchFavouriteFilter>;
}
export interface ISearchFavouriteFilter {
  Name: string;
  Query: string;
}

export interface ISearchFavouriteFilterWithId extends ISearchFavouriteFilter {
  id: number;
}

export interface IFavouriteFiltersStatus {
  isWorking: boolean;
  loading: boolean;
  loadingError: boolean;
}

export interface IFavouriteFiltersSelected {
  selected: ISearchFavouriteFilter;
  draft: ISearchFavouriteFilter;
}

export const IFAVOURITE_FILTER_NAME_MAX_LENGTH = 50;
