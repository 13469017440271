import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TooltipHtmlDialogComponent } from './tooltip-html-dialog/tooltip-html-dialog.component';

@Component({
  selector: 'tooltip-html',
  templateUrl: './tooltip-html.component.html'
})
export class TooltipHtmlComponent implements OnInit {
  @Input() html: string;
  @Input() position: string;
  isOpen = false;

  constructor(public dialog: MatDialog, private element: ElementRef) { }

  ngOnInit() {
  }

  openDialog(event: Event): void {
    if (!this.isOpen) {
      const dialogRef = this.dialog.open(TooltipHtmlDialogComponent, {
        data: { html: this.html, relativeTo: this.element.nativeElement, position: this.position },
        hasBackdrop: true,
        backdropClass: 'show-on-mobile',
        closeOnNavigation: true,
        panelClass: ['tooltip-html-panel', this.position]
      });
      this.isOpen = true;

      dialogRef.afterClosed().subscribe(result => {
        this.isOpen = false;
        console.debug('[TooltipHtmlComponent] The dialog was closed');
      });
    }
    event.stopPropagation();
    event.preventDefault();
  }

}
