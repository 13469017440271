<div class="file-list-element flex flex-start-center">
  <a mat-button
     type="button"
     color="primary"
     class="file-name"
     data-test-id="file-upload-element-fileName"
     [attr.title]="file.name"
     [attr.href]="blobUrl | trustedUrl"
     target="_blank">
    {{ file.name }}
  </a>
  <button mat-icon-button
          type="button"
          color="warn"
          class="color-on-hover remove-file-btn"
          (click)="emitFileRemoval()"
          [disableRipple]="true"
          data-test-id="file-upload-element-removeFileBtn">
    <mat-icon svgIcon="close_tiny"
              class="no-transition"></mat-icon>
  </button>
</div>
