import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { StringUtils } from '../utils/string-utils';

@Directive({
  selector: `input[sanitize-control-char], textarea[sanitize-control-char]`
})
export class InputSanitizeControlCharactersDirective {
  constructor(
    private ngControl: NgControl,
    private el: ElementRef
  ) {}

  @HostListener('blur', ['$event'])
  removeControlCharacters() {
    const input = this.el.nativeElement as HTMLInputElement;
    if (typeof input.value === 'string') {
      const cleanValue = StringUtils.removeControlCharacters(input.value);
      this.ngControl.control.setValue(cleanValue);
      input.value = cleanValue;
    }
  }
}
