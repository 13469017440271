import { Component, Input, OnInit } from '@angular/core';
// Services
import { CvListService } from '../../../services/cv/cv-list.service';
// Interfaces
import { ICv } from '../../../interfaces/cv';

@Component({
  selector: 'cv-box',
  templateUrl: './cv-box.component.html',
  styleUrls: ['./cv-box.component.scss']
})
export class CvBoxComponent implements OnInit {
  cv: ICv = null;

  constructor(
    private cvListService: CvListService
  ) {}

  ngOnInit() {
    this.cvListService.getPrimaryCv().then((result) => {
      this.cv = result;
    }, error => {
      console.error('[CvBoxComponent] Failed to load primary CV');
      return null;
    });
  }
}
