<ng-container *ngIf="(mediaQueryService.breakpoints$ | async) as breakpoints">
  <div [ngClass]="{'side-panel': sidePanel}">
      <div *ngIf="!editMode" class="address-details" data-test-id="address-edit-address-details-container">
        <div class="flex flex-between-center">
          <div class="address-label">Private address</div>
          <ng-container *ngIf="!readonly && sidePanel && canEdit">
            <button mat-button color="primary" class="small-button"
                    *ngIf="isEmpty"
                    (click)="activate()"
                    data-test-id="address-edit-address-details-sidepanel-add-button">Add</button>
            <button mat-button color="primary" class="small-button"
                    *ngIf="!isEmpty"
                    (click)="activate()"
                    data-test-id="address-edit-address-details-sidepanel-edit-button">Edit</button>
          </ng-container>
        </div>

        <div class="flex flex-between-start">
          <div class="address-value flex flex-col flex-start-start">
            <div *ngIf="!!address.AddressLine1 || !!address.AddressLine2">
              <span class="line1 multiline" *ngIf="!!address.AddressLine1" data-test-id="address-edit-address-line1-value">{{address.AddressLine1}}</span>
              <span *ngIf="!!address.AddressLine1 && !!address.AddressLine2">&nbsp;</span>
              <span class="line2 multiline" *ngIf="!!address.AddressLine2" data-test-id="address-edit-address-line2-value">{{address.AddressLine2}}</span>
            </div>
            <div *ngIf="!!address.CountryName || !!address.City || !!address.PostalCode">
              <span *ngIf="!!address.PostalCode" class="zip multiline" data-test-id="address-edit-address-zip-value">{{address.PostalCode}}</span>
              <span *ngIf="!!address.PostalCode && (!!address.City || !!address.CountryName)">, </span>
              <span *ngIf="!!address.City" class="city multiline" data-test-id="address-edit-address-city-value">{{address.City}}</span>
              <span *ngIf="!!address.City && !!address.CountryName">, </span>
              <span *ngIf="!!address.CountryName" class="country" data-test-id="address-edit-address-country-value">{{address.CountryName}}</span>
            </div>
          </div>
          <ng-container *ngIf="!readonly && !sidePanel && canEdit">
            <button mat-button color="primary"
                    class="small-button action-btn"
                    *ngIf="isEmpty"
                    (click)="activate()"
                    data-test-id="address-edit-address-details-add-button">Add</button>
            <button mat-button color="primary"
                    class="small-button action-btn"
                    *ngIf="!isEmpty"
                    (click)="activate()"
                    data-test-id="address-edit-address-details-edit-button">Edit</button>
          </ng-container>
        </div>
      </div>
      <div *ngIf="editMode" class="address-edit-form-groups">
        <form class="edit-form"
              [ngClass]="{loading: loading}"
              name="addressEditForm"
              [formGroup]="addressEditForm"
              (ngSubmit)="update()"
              novalidate
              (keydown)="keyListener($event)"
              (keypress)="keyListener($event)"
              data-test-id="address-edit-form">
          <h4 class="font-bold">Edit private address</h4>
          <div class="content">
            <div class="ng-select-flex-content-wrapper">
              <ng-select formControlName="country"
                         [items]="countryList"
                         [multiple]="false"
                         [clearable]="false"
                         [closeOnSelect]="true"
                         [virtualScroll]="true"
                         bindLabel="Name"
                         placeholder="Select Country"
                         data-test-id="address-edit-form-country-select">
              </ng-select>
              <label class="ng-select-label">Country</label>
            </div>
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>City</mat-label>
              <input matInput
                     id="input-city"
                     formControlName="city"
                     type="text"
                     placeholder="City"
                     autocomplete="address-level2"
                     name="city"
                     maxlength="100"
                     data-test-id="address-edit-form-city-input"/>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Zip/Postal Code</mat-label>
              <input matInput
                     id="input-postalCode"
                     formControlName="postalCode"
                     type="text"
                     placeholder="Zip/Postal code"
                     autocomplete="address-level2"
                     name="city"
                     maxlength="20"
                     data-test-id="address-edit-form-zip-input"/>
            </mat-form-field>

            <!-- address line 1 -->
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Address line 1</mat-label>
              <input matInput
                     id="input-addressLine1"
                     formControlName="addressLine1"
                     type="text"
                     placeholder="Address line 1"
                     name="addressLine1"
                     maxlength="100"
                     data-test-id="address-edit-form-addressLine1-input"/>
            </mat-form-field>

            <!-- address line 2 -->
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Address line 2</mat-label>
              <input matInput
                     id="input-addressLine2"
                     formControlName="addressLine2"
                     type="text"
                     placeholder="Address line 2"
                     name="addressLine2"
                     maxlength="100"
                     data-test-id="address-edit-form-addressLine2-input"/>
            </mat-form-field>
          </div>
          <div class="form-actions">
            <button mat-stroked-button
                    color="primary"
                    class="small-button"
                    type="submit"
                    [loading]="loading"
                    data-test-id="address-edit-form-save-button">
              {{ breakpoints.ltMd ? COMMON_BUTTONS_TEXTS.SAVE_CHANGES_MOBILE : COMMON_BUTTONS_TEXTS.SAVE_CHANGES_DESKTOP}}
            </button>
            <button mat-button
                    color="primary"
                    class="small-button cancel-button"
                    type="button"
                    (click)="reset()"
                    data-test-id="address-edit-form-discard-button">
              {{ breakpoints.ltMd ? COMMON_BUTTONS_TEXTS.DISCARD_CHANGES_MOBILE: COMMON_BUTTONS_TEXTS.DISCARD_CHANGES_DESKTOP}}
            </button>
          </div>
        </form>
      </div>
  </div>
</ng-container>
