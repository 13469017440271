import { Pipe, PipeTransform } from '@angular/core';
import { AppConfig } from '../app-config';
import * as moment from 'moment';


const DEFAULT_PRECISION = 'day';

@Pipe({
  name: 'countToDate'
})
export class CountToDatePipe implements PipeTransform {


  transform(value: any, precision = DEFAULT_PRECISION): any {
    let outputFormat;

    outputFormat = AppConfig.DEFAULT_DATE_OUTPUT_FORMATS[precision.toLowerCase()]
      || AppConfig.DEFAULT_DATE_OUTPUT_FORMATS[DEFAULT_PRECISION];

    if (!value) {
      return undefined;
    }

    const date = moment.utc(value);

    if (!date.isValid()) {
      console.error(`[countToDate] Date ${value} has invalid format`);
      return undefined;
    }

    return date.local().calendar(null, {
      sameDay: function (now) {
        if (this.isAfter(now)) {
          return '[Today]';
        } else {
          return outputFormat;
        }
      },
      nextDay: '[Tomorrow]',
      nextWeek: outputFormat,
      lastDay: outputFormat,
      lastWeek: outputFormat,
      sameElse: outputFormat
  });
  }
}
