import {
  AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NgControl
} from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ICvSkill } from '../../../interfaces/cv-skill';
import { NgSelectDecorator } from '../../../classes/ng-select-decorator';
import { SkillsService } from '../../../services/skills.service';
import { CvSkill } from '../../../classes/cv-skill';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ISkill } from '../../../interfaces/skill';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { StringUtils } from '../../../utils/string-utils';

const SKILL_NAME_MAXLENGTH = 100;

@Component({
  selector: 'skills-selector',
  templateUrl: './skills-selector.component.html',
  styleUrls: ['./skills-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkillsSelectorComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {

  @Input() skills: Array<ISkill> = [];
  @Input() maxSelectedItems = 10;
  @Input() single = false;
  @Input() allowAddingSkills = true;
  @Input() appendTo: string;
  @Output() skillCreated: EventEmitter<Partial<ICvSkill>> = new EventEmitter<Partial<ICvSkill>>();
  @ViewChild('skillsNgSelect') skillsNgSelect: NgSelectComponent;

  lastItemSelectedForDeletion = false;

  control: UntypedFormControl = new UntypedFormControl();

  get skillsSelectedCount(): number {
    const controlValue = this.control.value;

    if (controlValue && !this.single) {
      return controlValue.length;
    } else {
      return controlValue ? 1 : 0;
    }
  }

  onTouched: () => void;

  private backspaceListener: EventListener;
  private onChange: (value: Array<Partial<ICvSkill>>) => void;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private originalMarkAsTouchedFn: (opts?: {
    onlySelf?: boolean;
  }) => void;
  private originalResetFn: (value?: any, options?: Object) => void;

  constructor(@Optional() @Self() public ngControl: NgControl,
              private skillsService: SkillsService,
              private snackBarService: SnackBarService) {
    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.control.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (this.onChange) {
          this.onChange(value);
        }
      });

    // EXPLANATION
    // ng-select has styles for invalid look, as this component is mostly a wrapper, it's easier to override
    // it's object method than to write complicated styles which take into account that ng-select may or may not be a part of
    // skills-selector.
    // There is no way to listen for touch change
    this.originalMarkAsTouchedFn = this.ngControl.control.markAsTouched.bind(this.ngControl.control);
    this.ngControl.control.markAsTouched = (opts?: { onlySelf?: boolean; }) => {
      this.originalMarkAsTouchedFn(opts);
      // This may trigger twice on blur in ng-select, shouldn't impact performance very much
      this.control.markAsTouched({onlySelf: true});
    };

    this.originalResetFn = this.ngControl.control.reset.bind(this.ngControl.control);
    this.ngControl.control.reset = (value?: any, options?: Object) => {
      this.originalResetFn(value, options);
      this.control.reset(value);
    };

    this.ngControl.statusChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      // Copy errors to internal FormControl to make ng-valid and ng-invalid classes visible
      this.control.setErrors(this.ngControl.errors);
    });
  }

  ngAfterViewInit() {
    const skillSelectDecorator = new NgSelectDecorator(this.skillsNgSelect);

    this.backspaceListener = skillSelectDecorator.addBackspaceListener();

    skillSelectDecorator.lastItemSelectedForDeletion
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.lastItemSelectedForDeletion = value;
      });

    this.skillsNgSelect.searchInput.nativeElement.setAttribute('maxlength', '' + SKILL_NAME_MAXLENGTH);
  }


  onDropdownOpen(event) {
    if (this.skillsNgSelect) {
      this.skillsNgSelect.itemsList.unmarkItem(); // Prevent scrolling to marked item after open
    }
  }

  createSkill(skillName: string) {
    console.debug('[SkillsSelectorComponent] Create custom skill `' + skillName + '`');
    skillName = StringUtils.removeControlCharacters(skillName.trim());

    if (skillName.length === 0) {
      this.snackBarService.open({
        message: 'Your skill name cannot contain only whitespaces',
        type: SnackBarTypes.Notification
      });

      return;
    }

    const customSkill = new CvSkill(skillName, null, this.skillsService.CUSTOM_SKILL_CATEGORY_NAME, this.skillsService.customSkillCategoryId);

    let skillsControlValue: Array<Partial<ICvSkill>> = this.control.value;

    // add and select value to ng-select items list
    const customSkillNgOption = this.skillsNgSelect.itemsList.addItem(customSkill);

    if (this.single) {
      this.skillsNgSelect.select(customSkillNgOption);
    } else {
      if (skillsControlValue) {
        skillsControlValue = [...skillsControlValue, customSkill];
      } else {
        skillsControlValue = [ customSkill ];
      }

      this.control.setValue(skillsControlValue);
    }

    if (!this.single && this.skillsNgSelect.searchTerm) {
      // clear text entered in search field
      this.skillsNgSelect.searchTerm = null;
      this.skillsNgSelect.itemsList.resetFilteredItems();
      this.skillsNgSelect.searchInput.nativeElement.focus();
    }

    // add to local stored skills
    this.skillsService.addLocalSkill(customSkill);
    // emit if any output listens to
    this.skillCreated.emit(customSkill);

    // report to value ancestor
    this.onChange(this.control.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.skillsNgSelect) {
      this.skillsNgSelect.setDisabledState(isDisabled);
    }
  }

  focusTextInput() {
    if (this.skillsNgSelect && this.skillsNgSelect.searchInput && this.skillsNgSelect.searchInput.nativeElement) {
      setTimeout(() => {
        this.skillsNgSelect.searchInput.nativeElement.focus();
      }, 0);
    }
  }

  writeValue(skills: ICvSkill | Array<ICvSkill>): void {
    this.control.setValue(skills);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();

    this.ngControl.control.markAsTouched = this.originalMarkAsTouchedFn;
    this.originalMarkAsTouchedFn = null;

    this.ngControl.control.reset = this.originalResetFn;
    this.originalMarkAsTouchedFn = null;

    const nativeElement = this.skillsNgSelect.searchInput.nativeElement;
    nativeElement.removeEventListener('keydown', this.backspaceListener);
  }
}
