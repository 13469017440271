import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'files-upload-element',
  templateUrl: './files-upload-element.component.html',
  styleUrls: ['./files-upload-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilesUploadElementComponent {
  @Input() file: File;
  @Input() index: number;
  @Input() currentValidationMessage: { error: string; message: string };
  @Input() controlErrorObject: ValidationErrors;

  @Output() removeFile: EventEmitter<number> = new EventEmitter();

  get markError(): boolean {
    const error = this.controlErrorObject
      ? this.controlErrorObject[this.currentValidationMessage.error]
      : null;
    return error && Array.isArray(error) ? error.includes(this.index) : false;
  }

  constructor() {}

  emitFileRemoval() {
    this.removeFile.emit(this.index);
  }
}
