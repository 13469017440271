import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ICvSkill} from '../../interfaces/cv-skill';
import {ICvSkillCreateResponse} from '../../interfaces/cv-skill-create-response';
import {ICvBaseResponse} from '../../interfaces/cv-base-response';
import {lastValueFrom, Observable, of, throwError} from 'rxjs';
import {ICvSkillAddManyResponse} from '../../interfaces/cv-skill-add-many-response';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {ICvSkillCore} from '../../interfaces/cv-skill-core';
import {CvCommonService} from './cv-common.service';
import {ServiceNames} from '../../interfaces/my7n-env-config';
import {AppConfig} from '../../app-config';

@Injectable()
export class CvSkillsService {
  readonly API_CV: string;
  readonly API_CV_NEW: string;
  constructor(private http: HttpClient,
              private cvCommonService: CvCommonService,
              private appConfig: AppConfig) {
    this.API_CV = appConfig.serviceUrl(ServiceNames.Core) + 'cvs/';
    this.API_CV_NEW = appConfig.serviceUrl(ServiceNames.Cv, 'v1') + 'cv/';
  }

  // Skills  ---------------------------------------------------------------------
  // Please note API does not require CategoryName, it's not omitted here for convenience
  // @TODO proper types/interfaces for requests should be created
  updateSkill(cvId: number, fields: Omit<ICvSkill, 'CvId'>): Promise<ICvSkillCreateResponse | ICvBaseResponse> {
    const skillId = fields.Id,
      self = this;

    if (!cvId) {
      console.error('[CvService] updateSkill failed: Missing cvId');
      return Promise.reject('updateSkill failed: Missing cvId');
    }

    if (!skillId) {
      return lastValueFrom(this.http.post<ICvSkillCreateResponse>(this.API_CV + cvId + '/skill/', fields))
        .then((result) => {
          if (!result.Id) {
            console.error('[CvService][Skills] Response is missing .Id');
          }

          fields.Id = result.Id;
          fields.SkillId = result.SkillId;
          self.cvCommonService.updateProfileStrength(cvId);
          return result;
        }, (error) => {
          console.error('[CvService] Failed to update Skill ', error);
          return Promise.reject(error);
        });
    } else {
      return lastValueFrom(this.http.put<ICvBaseResponse>(this.API_CV + cvId + '/skill/' + skillId, fields))
        .then(function (result) {
          self.cvCommonService.updateProfileStrength(cvId);
          return result;
        }, (error) => {
          console.error('[CvService] Failed to update Skill entry with Id ' + skillId, error);
          return Promise.reject(error);
        });
    }

  }

  addManySkills(cvId: number, skills: Array<Omit<ICvSkill, 'CvId'>>): Observable<ICvSkillAddManyResponse> {
    if (!cvId) {
      console.error('[CvService] addManySkills failed: Missing cvId');
      return throwError(() => '[CvService] addManySkills failed: Missing cvId');
    }

    return this.http.put<ICvSkillAddManyResponse>(this.API_CV_NEW + cvId + '/skills/', { Skills: skills }).pipe(
      tap(() => {
        this.cvCommonService.updateProfileStrength(cvId);
      }),
      switchMap((result) => {
        if (!result.AddedSkills) {
          console.error('[CvService][Skills][addManySkills] Response is missing .AddedSkills array');
          return throwError(() => '[CvService][Skills][addManySkills] Response is missing .AddedSkills array');
        }

        if (!Array.isArray(result.AddedSkills)) {
          console.error('[CvService][Skills][addManySkills] Response .AddedSkills is not array');
          return throwError(() => '[CvService][Skills][addManySkills] Response .AddedSkills is not array');
        }

        skills.map((skill) => {
          const foundSkill = result.AddedSkills.find((addedSkill) => addedSkill.SkillId === skill.SkillId);
          skill.Id = foundSkill.Id;
        });

        return of(result);
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(
          `[CvService] Failed to addManySkills Skills'`,
          error
        );
        return throwError(() => error);
      }));

  }

  removeManySkills(skillIds: Array<number>, cvId: number): Observable<ICvBaseResponse> {
    return this.http.delete<ICvBaseResponse>(this.API_CV_NEW + cvId + '/skills', {
      body: skillIds
    }).pipe(
      tap(() => {
        this.cvCommonService.updateProfileStrength(cvId);
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(
          `[CvService] Failed to remove skills`,
          error
        );
        return throwError(() => error);
      })
    );
  }

  // Languages  ------------------------------------------------------------------
  updateLanguageSkill(cvId: number, fields: ICvSkillCore) {
    const languageId = fields.Id,
      self = this;

    if (!cvId) {
      console.error('[CvService] updateLanguages failed: Missing cvId');
      return Promise.reject('updateLanguages failed: Missing cvId');
    }

    if (!languageId) {
      return lastValueFrom(this.http.post<ICvSkill>(this.API_CV + cvId + '/skill/', fields))
        .then((result) => {
          if (!result.Id) {
            console.error('[CvService][Languages] Response is missing .Id');
          }

          fields.Id = result.Id;
          fields.SkillId = result.SkillId;

          self.cvCommonService.updateProfileStrength(cvId);
          return result;
        }, (error) => {
          console.error('[CvService] Failed to update Languages', error);
          return Promise.reject(error);
        });
    } else {
      return lastValueFrom(this.http.put(this.API_CV + cvId + '/skill/' + languageId, fields))
        .then(function (result) {
          self.cvCommonService.updateProfileStrength(cvId);
          return result;
        }, (error) => {
          console.error('[CvService] Failed to update Languages entry with Id ' + languageId, error);
          return Promise.reject(error);
        });
    }

  }

  removeLanguageSkill = this.cvCommonService.commonRemove('/skill/');
}
