import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
// Interfaces
import { ICmsCard } from '../../../interfaces/cms-card';
import { CmsSortingModes } from '../../../interfaces/cms';
// Services
import { CmsCardListService } from '../../../services/cms-card-list.service';

@Component({
  selector: 'cms-card-list',
  templateUrl: './cms-card-list.component.html',
  styleUrls: ['./cms-card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmsCardListComponent {
  @Input() cardsList: Array<ICmsCard>;
  isFilterVisible$ = this.cmsCardListService.isFilterVisible$;
  sortingMode$ = this.cmsCardListService.sortingMode$;

  readonly CmsSortingModes = CmsSortingModes;

  constructor(private cmsCardListService: CmsCardListService) {}

  trackByCardId(index: number, card: ICmsCard): string {
    return card.id;
  }

  sortingModeUpdated(change: MatButtonToggleChange) {
    this.cmsCardListService.updateSortingMode(change.value);
  }
}
