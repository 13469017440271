import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app-config';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class ConsultantListService {

  readonly API_SEARCH: string;
  readonly API_SEARCH_FILTER: string;
  readonly API_DICT: string;

  skillFakeId: number;

  constructor(private http: HttpClient,
              private appConfig: AppConfig) {
    const apiPrefix = this.appConfig.serviceUrl(ServiceNames.Core);

    this.API_SEARCH = apiPrefix + 'search/';
    this.API_SEARCH_FILTER = apiPrefix + 'search/consultants/filter/';
    this.API_DICT = apiPrefix + 'dictionaries/';
  }

  getOwners() {
    return lastValueFrom(this.http.get<any>(this.API_DICT + 'agents')).then( (result) => result)
      .catch((error) => {
        console.error('[ConsultantListService] Failed to get Agents', error);
        return [];
      });
  }

  getRelationshipTypes() {
    return lastValueFrom(this.http.get<any>(this.API_SEARCH_FILTER + 'relationshipTypes')).then((result) => result.RelationshipTypes)
      .catch((error) => {
        console.error('[ConsultantListService] Failed to get relationship types', error);
        return [];
      });
  }

  getConsultantStatuses() {
    return lastValueFrom(this.http.get<any>(this.API_SEARCH_FILTER + 'consultantStatuses')).then((result) => result.Statuses)
      .catch( (error) => {
        console.error('[ConsultantListService] Failed to get consultant statuses', error);
        return [];
      });
  }

  getConsultantActiveStatuses() {
    return lastValueFrom(this.http.get<any>(this.API_SEARCH_FILTER + 'consultantActiveStatuses')).then((result) => result.Statuses)
      .catch( (error) => {
        console.error('[ConsultantListService] Failed to get consultant active statuses', error);
        return [];
      });
  }

  getCvLanguages() {
    return lastValueFrom(this.http.get<any>(this.API_SEARCH_FILTER + 'cvLanguages')).then((result) => result.CvLanguages)
      .catch( (error) => {
        console.error('[ConsultantListService] Failed to get cv languages', error);
        return [];
    });
  }

  updateSkillFakeId(value) {
    this.skillFakeId = value;
  }

  // fake calls for apis. It's needed for usage skills component as filter for advanced search
  fakeUpdateSkill(fields): Promise<void> {
    return new Promise(resolve => {
      window.setTimeout(() => {
        if (fields.Id === undefined || fields.Id === null) {
          fields.Id = this.skillFakeId;
          this.skillFakeId++;
        }

        console.debug('[ConsultantListService] Created fakeSkill "' + fields.Name + '" with Id: ' + fields.Id);
        resolve();
      }, 10);
    });
  }
}
