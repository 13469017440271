import { Component, OnInit, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tooltip-html-dialog',
  templateUrl: './tooltip-html-dialog.component.html',
  host: {
    '(window:resize)': 'updateDialogPosition($event)',
    '(window:scroll)': 'updateDialogPosition($event)'
  }
})
export class TooltipHtmlDialogComponent implements OnInit, AfterViewInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<TooltipHtmlDialogComponent>, private el: ElementRef) {
  }


  element = this.el.nativeElement;
  relativeElement: any;
  relativeElementRect: any;

  popoverDisplayChangeBreakpoint = 767;

  ngOnInit() { }

  cancelDialog(): void {
    this.dialogRef.close(false);
  }

  updateDialogPosition($event?: Event) {
    this.relativeElement = this.data.relativeTo;
    this.relativeElementRect = this.relativeElement.getBoundingClientRect();
    let horizontalOffset = (this.relativeElement.offsetWidth - this.element.offsetWidth) / 2,
      verticalOffset = (this.relativeElement.offsetHeight - this.element.offsetHeight) / 2,
      offset = 0;

    if (window.innerWidth > this.popoverDisplayChangeBreakpoint) {
      switch (this.data.position) {
        case 'bottom':
          this.dialogRef.updatePosition({ top: this.relativeElementRect.top + this.relativeElement.offsetHeight + offset + 'px', left: this.relativeElementRect.left + horizontalOffset + 'px' });
          break;
        case 'left':
          this.dialogRef.updatePosition({ top: this.relativeElementRect.top + verticalOffset + 'px', left: this.relativeElementRect.left - this.element.offsetWidth - offset + 'px' });
          break;
        case 'right':
          this.dialogRef.updatePosition({ top: this.relativeElementRect.top + verticalOffset + 'px', left: this.relativeElementRect.left + this.relativeElement.offsetWidth + offset + 'px' });
          break;
        case 'top':
          this.dialogRef.updatePosition({ top: this.relativeElementRect.top - this.relativeElement.offsetHeight - this.element.offsetHeight - offset + 'px', left: this.relativeElementRect.left + horizontalOffset + 'px' });
          break;

        default:
          console.error('[TooltipHtmlDialogComponent] Unknown position: ' + this.data.position);
          break;
      }
    } else {
      this.dialogRef.updatePosition({ top: this.relativeElementRect.top + this.relativeElement.offsetHeight + offset + 'px', left: '0px' });
    }
  }

  ngAfterViewInit() {
    this.updateDialogPosition();
  }
}
