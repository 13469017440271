import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {ICareerPosition} from '../../interfaces/career-position';
import {IProject} from '../../interfaces/project';
import {CvCommonService} from './cv-common.service';
import {ServiceNames} from '../../interfaces/my7n-env-config';
import {AppConfig} from '../../app-config';

@Injectable()
export class CvCareerService {
  readonly API_CV: string;
  constructor(private http: HttpClient,
              private cvCommonService: CvCommonService,
              private appConfig: AppConfig) {
    this.API_CV = appConfig.serviceUrl(ServiceNames.Core) + 'cvs/';
  }

  updateCareer(cvId: number, fields) {
    const careerId = fields.Id,
      self = this;

    if (!careerId) {
      return lastValueFrom(this.http.post<ICareerPosition>(this.API_CV + cvId + '/career', fields))
        .then((result) => {
          if (!result.Id) {
            console.error('[CvService] Response is missing Career Id');
          }
          self.cvCommonService.updateProfileStrength(cvId);
          return result;
        }, (error) => {
          console.error('[CvService] Failed to update Career ', error);
          return Promise.reject(error);
        });
    } else {
      return lastValueFrom(this.http.put<ICareerPosition>(this.API_CV + cvId + '/career/' + careerId, fields))
        .then(function (result) {
          self.cvCommonService.updateProfileStrength(cvId);
          return result;
        }, (error) => {
          console.error('[CvService] Failed to update Career entry with Id ' + careerId, error);
          return Promise.reject(error);
        });
    }

  }

  removeCareer = this.cvCommonService.commonRemove('/career/');

  updateProject(cvId: number, careerId: number, fields) {
    const projectId = fields.Id,
      self = this;

    if (!cvId) {
      console.error('[CvService] updateProject failed: Missing cvId');
      return Promise.reject('updateProject failed: Missing cvId');
    }

    if (!careerId) {
      console.error('[CvService] updateProject failed: Missing careerId');
      return Promise.reject('updateProject failed: Missing careerId');
    }

    if (!projectId) {
      return lastValueFrom(this.http.post<IProject>(this.API_CV + cvId + '/career/' + careerId + '/project', fields))
        .then((result) => {
          if (!result.Id) {
            console.error('[CvService] Response is missing Project Id');
          }
          self.cvCommonService.updateProfileStrength(cvId);
          return result;
        }, (error) => {
          console.error('[CvService] Failed to update Project ', error);
          return Promise.reject(error);
        });
    } else {
      return lastValueFrom(this.http.put<IProject>(this.API_CV + cvId + '/career/' + careerId + '/project/' + projectId, fields))
        .then((result) => {
          self.cvCommonService.updateProfileStrength(cvId);
          return result;
        }, (error) => {
          console.error('[CvService] Failed to update Project entry with Id ' + projectId, error);
          return Promise.reject(error);
        });
    }
  }

  removeProject(cvId: number, careerId: number, projectId: number) {
    const self = this;

    return lastValueFrom(this.http.delete(this.API_CV + cvId + '/career/' + careerId + '/project/' + projectId))
      .then((result) => {
        self.cvCommonService.updateProfileStrength(cvId);
        return result;
      });
  }

}
