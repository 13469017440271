import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';


@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(text: string, phrase: string): SafeHtml {

    if (phrase) {
      // Escape regex unsafe characters
      // Based on: http://stackoverflow.com/a/6969486
      phrase = phrase.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

      // And create regex out of it
      text = text.replace(new RegExp('(' + phrase + ')', 'gi'), '<span class="highlight">$1</span>');
    }

    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

}
