import * as fromMy7n from '../index';
import { createReducer, on, Action } from '@ngrx/store';
import {
  IBusinessDetails, IConsultantsTenantDetails,
  IPersonalDetails,
  ITermsAndConditions
} from '../../../interfaces/user-profile';
import * as UserProfileActions from '../../actions/user-profile.actions';
import { ILocation } from '../../../interfaces/location';
import { INotificationSettings } from '../../../interfaces/notification-settings';

export interface FeatureState extends fromMy7n.State {
  userProfile: State;
}

export interface State {
  personalDetails: IPersonalDetails;
  personalDetailsLoading: boolean;
  personalDetailsRequestTimestamp: number;
  businessDetails: IBusinessDetails;
  businessDetailsLoading: boolean;
  businessDetailsRequestTimestamp: number;
  preferredWorkLocations: Array<ILocation>;
  preferredWorkLocationsLoading: boolean;
  preferredWorkLocationsRequestTimestamp: number;
  termsAndConditions: ITermsAndConditions;
  termsAndConditionsLoading: boolean;
  termsAndConditionsRequestTimestamp: number;
  notificationSettings: INotificationSettings;
  notificationSettingsLoading: boolean;
  notificationSettingsSaving: boolean;
  notificationSettingsRequestTimestamp: number;
  consultantsTenantDetails: IConsultantsTenantDetails;
  consultantsTenantDetailsLoading: boolean;
  consultantsTenantDetailsRequestTimestamp: number;
}

export const initialState: State = {
  personalDetails: null,
  personalDetailsLoading: false,
  personalDetailsRequestTimestamp: null,
  businessDetails: null,
  businessDetailsLoading: false,
  businessDetailsRequestTimestamp: null,
  preferredWorkLocations: null,
  preferredWorkLocationsLoading: false,
  preferredWorkLocationsRequestTimestamp: null,
  termsAndConditions: null,
  termsAndConditionsLoading: false,
  termsAndConditionsRequestTimestamp: null,
  notificationSettings: null,
  notificationSettingsLoading: false,
  notificationSettingsSaving: false,
  notificationSettingsRequestTimestamp: null,
  consultantsTenantDetails: {
    ConsultantUPN: '',
    IsActive: false,
    PrivateMail: ''
  },
  consultantsTenantDetailsLoading: false,
  consultantsTenantDetailsRequestTimestamp: null
};

const reducer = createReducer(
  initialState,
  on(UserProfileActions.queryPersonalDetails, (state) => ({
    ...state,
    personalDetailsLoading: true
  })),
  on(
    UserProfileActions.personalDetailsLoaded,
    (state, { personalDetails }) => ({
      ...state,
      personalDetailsLoading: false,
      personalDetails,
      personalDetailsRequestTimestamp: Date.now()
    })
  ),
  on(UserProfileActions.personalDetailsLoadedFromCache, (state) => ({
    ...state,
    personalDetailsLoading: false
  })),
  on(UserProfileActions.queryPersonalDetailsError, (state) => ({
    ...state,
    personalDetailsLoading: false
  })),
  on(UserProfileActions.queryBusinessDetails, (state) => ({
    ...state,
    businessDetailsLoading: true
  })),
  on(
    UserProfileActions.businessDetailsLoaded,
    (state, { businessDetails }) => ({
      ...state,
      businessDetailsLoading: false,
      businessDetails,
      businessDetailsRequestTimestamp: Date.now()
    })
  ),
  on(UserProfileActions.businessDetailsLoadedFromCache, (state) => ({
    ...state,
    businessDetailsLoading: false
  })),
  on(UserProfileActions.queryBusinessDetailsError, (state) => ({
    ...state,
    businessDetailsLoading: false
  })),
  on(UserProfileActions.queryPreferredWorkLocations, (state) => ({
    ...state,
    preferredWorkLocationsLoading: true
  })),
  on(
    UserProfileActions.preferredWorkLocationsLoaded,
    (state, { preferredWorkLocations: preferredWorkLocations }) => ({
      ...state,
      preferredWorkLocationsLoading: false,
      preferredWorkLocations,
      preferredWorkLocationsRequestTimestamp: Date.now()
    })
  ),
  on(UserProfileActions.preferredWorkLocationsLoadedFromCache, (state) => ({
    ...state,
    preferredWorkLocationsLoading: false
  })),
  on(UserProfileActions.queryPreferredWorkLocationsError, (state) => ({
    ...state,
    preferredWorkLocationsLoading: false
  })),
  on(UserProfileActions.queryTermsAndConditions, (state) => ({
    ...state,
    termsAndConditionsLoading: true
  })),
  on(
    UserProfileActions.termsAndConditionsLoaded,
    (state, { termsAndConditions }) => ({
      ...state,
      termsAndConditionsLoading: false,
      termsAndConditions,
      termsAndConditionsRequestTimestamp: Date.now()
    })
  ),
  on(UserProfileActions.termsAndConditionsLoadedFromCache, (state) => ({
    ...state,
    termsAndConditionsLoading: false
  })),
  on(UserProfileActions.queryTermsAndConditionsError, (state) => ({
    ...state,
    termsAndConditionsLoading: false
  })),
  on(UserProfileActions.queryNotificationSettings, (state) => ({
    ...state,
    notificationSettingsLoading: true
  })),
  on(
    UserProfileActions.notificationSettingsLoaded,
    (state, { notificationSettings }) => ({
      ...state,
      notificationSettingsLoading: false,
      notificationSettings,
      notificationSettingsRequestTimestamp: Date.now()
    })
  ),
  on(UserProfileActions.notificationSettingsLoadedFromCache, (state) => ({
    ...state,
    notificationSettingsLoading: false
  })),
  on(UserProfileActions.queryNotificationSettingsError, (state) => ({
    ...state,
    notificationSettingsLoading: false
  })),
  on(
    UserProfileActions.updateNotificationSettingsSubscription,
    (state, { wantToReceive }) => ({
      ...state,
      notificationSettings: {
        ...state.notificationSettings,
        WantToReceive: wantToReceive
      },
      notificationSettingsRequestTimestamp: Date.now()
    })
  ),
  on(
    UserProfileActions.updateNotificationSettingsSubscriptionError,
    (state) => ({
      ...state,
      notificationSettings: {
        ...state.notificationSettings,
        WantToReceive: !state.notificationSettings.WantToReceive // restore the previous value
      }
    })
  ),
  on(UserProfileActions.updatePersonalDetailsEmail, (state, { email }) => ({
    ...state,
    personalDetails: {
      ...state.personalDetails,
      Email: email
    },
    personalDetailsRequestTimestamp: Date.now()
  })),
  on(UserProfileActions.updatePersonalDetailsPhones, (state, { phones }) => ({
    ...state,
    personalDetails: {
      ...state.personalDetails,
      Phones: phones
    },
    personalDetailsRequestTimestamp: Date.now()
  })),
  on(UserProfileActions.updatePersonalDetailsAddress, (state, { address }) => ({
    ...state,
    personalDetails: {
      ...state.personalDetails,
      Address: address
    },
    personalDetailsRequestTimestamp: Date.now()
  })),
  on(
    UserProfileActions.updateBusinessDetailsCompanyName,
    (state, { companyName }) => ({
      ...state,
      businessDetails: {
        ...state.businessDetails,
        CompanyName: companyName
      },
      businessDetailsRequestTimestamp: Date.now()
    })
  ),
  on(
    UserProfileActions.updateBusinessDetailsCompanyWebsite,
    (state, { companyWebsite }) => ({
      ...state,
      businessDetails: {
        ...state.businessDetails,
        CompanyWebsite: companyWebsite
      },
      businessDetailsRequestTimestamp: Date.now()
    })
  ),
  on(
    UserProfileActions.updatePreferredWorkLocations,
    (state, { locations }) => ({
      ...state,
      preferredWorkLocations: locations,
      preferredWorkLocationsRequestTimestamp: Date.now()
    })
  ),
  on(
    UserProfileActions.updateTermsPublicationWebsiteSubscription,
    (state, { accepted }) => ({
      ...state,
      termsAndConditions: {
        ...state.termsAndConditions,
        AcceptPublicationDataOnWebsiteBy7n: accepted
      },
      termsAndConditionsRequestTimestamp: Date.now()
    })
  ),
  on(
    UserProfileActions.updateTermsPublicationWebsiteSubscriptionError,
    (state) => ({
      ...state,
      termsAndConditions: {
        ...state.termsAndConditions,
        AcceptPublicationDataOnWebsiteBy7n:
          !state.termsAndConditions.AcceptPublicationDataOnWebsiteBy7n // restore the previous value
      }
    })
  ),
  on(
    UserProfileActions.updateTermsEventInvitationSubscription,
    (state, { accepted }) => ({
      ...state,
      termsAndConditions: {
        ...state.termsAndConditions,
        AcceptMy7nEventInvitation: accepted
      },
      termsAndConditionsRequestTimestamp: Date.now()
    })
  ),
  on(
    UserProfileActions.updateTermsEventInvitationSubscriptionError,
    (state) => ({
      ...state,
      termsAndConditions: {
        ...state.termsAndConditions,
        AcceptMy7nEventInvitation:
          !state.termsAndConditions.AcceptMy7nEventInvitation // restore the previous value
      }
    })
  ),
  on(
    UserProfileActions.updateTermsNewsletterSubscription,
    (state, { accepted }) => ({
      ...state,
      termsAndConditions: {
        ...state.termsAndConditions,
        AcceptMy7nNewsletter: accepted
      },
      termsAndConditionsRequestTimestamp: Date.now()
    })
  ),
  on(UserProfileActions.updateTermsNewsletterSubscriptionError, (state) => ({
    ...state,
    termsAndConditions: {
      ...state.termsAndConditions,
      AcceptMy7nNewsletter: !state.termsAndConditions.AcceptMy7nNewsletter // restore the previous value
    }
  })),
  on(
    UserProfileActions.updateTermsStoringDataSubscription,
    (state, { accepted }) => ({
      ...state,
      termsAndConditions: {
        ...state.termsAndConditions,
        AcceptStoringAndSendingDataBy7n: accepted
      },
      termsAndConditionsRequestTimestamp: Date.now()
    })
  ),
  on(UserProfileActions.updateTermsStoringDataSubscriptionError, (state) => ({
    ...state,
    termsAndConditions: {
      ...state.termsAndConditions,
      AcceptStoringAndSendingDataBy7n:
        !state.termsAndConditions.AcceptStoringAndSendingDataBy7n // restore the previous value
    }
  })),
  on(UserProfileActions.updateNotificationSettingsFrequency, (state) => ({
    ...state,
    notificationSettingsSaving: true
  })),
  on(
    UserProfileActions.updateNotificationSettingsFrequencySuccess,
    (state, { settings }) => ({
      ...state,
      notificationSettings: settings,
      notificationSettingsSaving: false,
      notificationSettingsRequestTimestamp: Date.now()
    })
  ),
  on(UserProfileActions.updateNotificationSettingsFrequencyError, (state) => ({
    ...state,
    notificationSettingsSaving: false
  })),
  on(UserProfileActions.queryConsultantsTenantDetails, (state) => ({
      ...state,
      consultantsTenantDetailsLoading: true
    })
  ),
  on(
    UserProfileActions.consultantsTenantDetailsLoaded,
    (state, { details }) => ({
      ...state,
      consultantsTenantDetailsLoading: false,
      consultantsTenantDetails: details,
      consultantsTenantDetailsRequestTimestamp: Date.now()
    })
  ),
  on(
    UserProfileActions.consultantsTenantDetailsLoadedFromCache,
    (state) => ({
      ...state,
      consultantsTenantDetailsLoading: false,
    })
  )
);

export function userProfileReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

export const getPersonalDetails = (state: State) => {
  return state.personalDetails;
};

export const getPersonalDetailsRequestTimestamp = (state: State) => {
  return state.personalDetailsRequestTimestamp;
};

export const getPersonalDetailsLoading = (state: State) => {
  return state.personalDetailsLoading;
};

export const getBusinessDetails = (state: State) => {
  return state.businessDetails;
};

export const getBusinessDetailsRequestTimestamp = (state: State) => {
  return state.businessDetailsRequestTimestamp;
};

export const getBusinessDetailsLoading = (state: State) => {
  return state.businessDetailsLoading;
};

export const getPreferredWorkLocations = (state: State) => {
  return state.preferredWorkLocations;
};

export const getPreferredWorkLocationsRequestTimestamp = (state: State) => {
  return state.preferredWorkLocationsRequestTimestamp;
};

export const getPreferredWorkLocationsLoading = (state: State) => {
  return state.preferredWorkLocationsLoading;
};

export const getTermsAndConditions = (state: State) => {
  return state.termsAndConditions;
};

export const getTermsAndConditionsRequestTimestamp = (state: State) => {
  return state.termsAndConditionsRequestTimestamp;
};

export const getTermsAndConditionsLoading = (state: State) => {
  return state.termsAndConditionsLoading;
};

export const getNotificationSettings = (state: State) => {
  return state.notificationSettings;
};

export const getNotificationSettingsRequestTimestamp = (state: State) => {
  return state.notificationSettingsRequestTimestamp;
};

export const getNotificationSettingsLoading = (state: State) => {
  return state.notificationSettingsLoading;
};

export const getNotificationSettingsSaving = (state: State) => {
  return state.notificationSettingsSaving;
};

export const getConsultantsTenantDetailsLoading = (state: State) => {
  return state.consultantsTenantDetailsLoading;
};

export const getConsultantsTenantDetails = (state: State) => {
  return state.consultantsTenantDetails;
};

export const getConsultantsTenantDetailsRequestTimestamp = (state: State) => {
  return state.consultantsTenantDetailsRequestTimestamp;
};
