export interface IVideoProviders {
  [key: string]: {
    domains: Array<string>;
    idSeparators: Array<string>;
    getEmbeddedUrl: Function;
  };
}

export enum VideoProviders {
  Youtube = 'youtube',
  Vimeo = 'vimeo'
}

export enum LogVideoEvents {
  Played = 'played',
  Ended = 'ended'
}

export enum LogVideoModules {
  Academy = 'Academy',
  ConsultantGuide = 'ConsultantGuide',
  News = 'News',
  Other = 'Other',
  VideoUpdateBox = 'VideoUpdateBox',
  Welcome = 'Welcome'
}

export interface IVideoPlayerLog {
  videoId: string;
  videoProvider: VideoProviders;
  module: LogVideoModules;
  event: LogVideoEvents;
  articleId: string; // like Contentful ID
  title: string; // article title
}

export interface IVideoPlayerConfig {
  containerWidth?: number;
  containerHeight?: number;
  frameborder?: number;
  allow?: string;
  webkitallowfullscreen?: boolean;
  mozallowfullscreen?: boolean;
  allowfullscreen?: boolean;
  autoplay: boolean;
  showLoader: boolean;
}

export const videoPlayerDefaultConfig: IVideoPlayerConfig = {
  allow: 'autoplay',
  allowfullscreen: true,
  containerHeight: 512,
  containerWidth: 909,
  frameborder: 0,
  mozallowfullscreen: true,
  webkitallowfullscreen: true,
  autoplay: true,
  showLoader: true
};
