<div class="social-links-container flex flex-col flex-center-center">
  <div class="social-links flex flex-between-center">
    <a href="https://www.linkedin.com/company/7n/mycompany/"
       target="_blank"
       class="link-linkedin flex flex-center-center"
       rel="noopener noreferrer">
       <mat-icon svgIcon="linkedin"></mat-icon>
    </a>
    <a href="https://www.youtube.com/c/7NPoland"
       target="_blank"
       class="link-youtube flex flex-center-center"
       rel="noopener noreferrer">
       <mat-icon svgIcon="youtube"></mat-icon>
    </a>
    <a href="https://www.7n.com/newsroom"
       target="_blank"
       class="link-website flex flex-center-center"
       rel="noopener noreferrer">
       <mat-icon>public</mat-icon>
    </a>
  </div>
</div>
