import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IMessageBusMessage } from '../interfaces/message-bus-message';

@Injectable({
  providedIn: 'root'
})
export class MessageBusService {

  /**
   * Available message types.
   */
  static readonly MESSAGES = {
    'SKILLS_FORM_UPDATED': 'SKILLS_FORM_UPDATED',
    'SKILLS_SAVE_TRIGGER': 'SKILLS_SAVE_TRIGGER',
    'SKILLS_ADDED_FROM_EXTERNAL_SOURCE': 'SKILLS_ADDED_FROM_EXTERNAL_SOURCE',
    'SKILLS_EDIT_FORM_INVALID': 'SKILLS_EDIT_FORM_INVALID',
    'LANGUAGE_SKILLS_FORM_UPDATED': 'LANGUAGE_SKILLS_FORM_UPDATED',
    'LANGUAGE_SKILLS_SAVE_TRIGGER': 'LANGUAGE_SKILLS_SAVE_TRIGGER',
    'LANGUAGE_SKILLS_EDIT_FORM_INVALID': 'LANGUAGE_SKILLS_EDIT_FORM_INVALID',
    'SEARCH_COMPONENT_DETACHED': 'SEARCH_COMPONENT_DETACHED',
    'SEARCH_COMPONENT_ATTACHED': 'SEARCH_COMPONENT_ATTACHED',
    'SEARCH_QUERY_SAVE_TRIGGER': 'SEARCH_QUERY_SAVE_TRIGGER',
    'SEARCH_QUERY_SAVE_TRIGGER_DIALOG': 'SEARCH_QUERY_SAVE_TRIGGER_DIALOG',
    'SEARCH_UPDATE_FILTERS_FORM_FROM_DIALOG': 'SEARCH_UPDATE_FILTERS_FORM_FROM_DIALOG'
  };

  private subject = new Subject<IMessageBusMessage>();

  sendMessage(msgName: string, payload = {}) {
    if (!MessageBusService.MESSAGES.hasOwnProperty(msgName)) {
      console.warn(`Message ${msgName} is not declared in MessageBusService`);
      return;
    }

    this.subject.next({
      name: msgName,
      payload: payload
    });
  }

  getMessage(): Observable<IMessageBusMessage> {
    return this.subject.asObservable();
  }
}
