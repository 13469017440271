<ng-container *ngIf="(mediaQueryService.breakpoints$ | async) as breakpoints">
  <div class="cv-parse-container flex flex-col flex-start-stretch gap-16">
  
    <p data-test-id="cv-parse-paragraph-text">
      Upload your file (PDF) to create and fill out your CV automatically. It can take up to 3 minutes.
    </p>
  
    <ng-content select="validation-messages"></ng-content>
  
    <input #fileUploadInput 
           class="file-input" 
           type="file"
           data-test-id="cv-parse-file-input" />
    <button #fileUploadButton
            data-test-id="cv-parse-choose-button"
            mat-flat-button
            color="primary"
            type="button"
            cdkFocusInitial
            [ngClass]="{ 'small-button': breakpoints.ltMd }"
            (click)="fileUploadInput.click()">
      Choose File
    </button>
  </div>
</ng-container>
