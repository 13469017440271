import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  OnDestroy,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, take } from 'rxjs';
// Services
import { CvService } from '../../../../../services/cv/cv.service';
import { CvLanguageService } from '../../../../../services/cv-language.service';
import { AuthorizationService } from '../../../../../services/authorization.service';
// Components
import { CvRenameDialogComponent } from './cv-rename-dialog/cv-rename-dialog.component';
import { CvPrimaryDialogComponent } from './cv-primary-dialog/cv-primary-dialog.component';
import { ConfirmDialogComponent, ThemeColors } from '@my7n/ui';
// Interfaces
import { ICvListItem } from '../../../../../interfaces/cv-list-item';
// Classes
import { BaseCvElement } from '../../../../../classes/base-cv-element';
// Config
import { CvFeatures } from '@my7n/features';

@Component({
  selector: 'cv-list-element',
  templateUrl: './cv-list-element.component.html',
  styleUrls: ['./cv-list-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvListElementComponent extends BaseCvElement implements OnDestroy {
  @HostBinding('class.raw') get isProgressRaw(): boolean { return this.cv.Progress <= 40; }
  @HostBinding('class.average') get isProgressAverage(): boolean { return this.cv.Progress > 40 && this.cv.Progress <= 80; }
  @HostBinding('class.perfect') get isProgressPerfect(): boolean { return this.cv.Progress > 80; }
  @HostBinding('class.draft') get isDraft(): boolean { return !this.cv.IsSent; }
  @HostBinding('class.sent') get isSent(): boolean { return this.cv.IsSent; }

  get headerListImage(): string {
    if (this.cv.IsSent) {
      return '/assets/images/cv-list-view/header-sent.png';
    }

    return  '/assets/images/cv-list-view/header-draft.png';
  }

  get cvEditFeature(): string {
    return CvFeatures.ItemEdit;
  }

  get cvRenameFeature(): string {
    return CvFeatures.ItemRename;
  }

  get cvDuplicateFeature(): string {
    return CvFeatures.ItemDuplicate;
  }

  get cvDeleteFeature(): string {
    return CvFeatures.ItemDelete;
  }

  get cvViewFeature(): string {
    return CvFeatures.ItemView;
  }

  get canViewCv(): Observable<boolean> {
    return this.authService.can(this.cvViewFeature);
  }

  get showMenuButton$() {
    const menuFeatures = [
      this.cvEditFeature,
      this.cvRenameFeature,
      this.cvDuplicateFeature,
      this.cvDeleteFeature
    ];

    return this.authService.canSome(menuFeatures);
  }

  @Output() cvDuplicated = new EventEmitter<ICvListItem>(); // @TODO consider changing this to service with observables
  @Output() cvRemoved = new EventEmitter<ICvListItem>(); // @TODO consider changing this to service with observables

  private renameDialogRef: MatDialogRef<CvRenameDialogComponent> = null;
  private removeDialogRef: MatDialogRef<ConfirmDialogComponent> = null;
  private primaryDialogRef: MatDialogRef<CvPrimaryDialogComponent> = null;

  constructor(
    cvLanguageService: CvLanguageService,
    cdr: ChangeDetectorRef,
    private cvService: CvService,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthorizationService
  ) {
    super(cvLanguageService, cdr);
   }

  editCv() {
    this.router.navigate(['/cv/' + this.cv.Id]);
  }

  showRenameDialog() {
    this.renameDialogRef = this.dialog.open(CvRenameDialogComponent, {
      maxWidth: '95%',
      minWidth: '25%',
      data: {
        name: this.cv.Name
      }
    });

    this.renameDialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result && result.changed) {
        this.cvService.renameCV(this.cv, result.name).then(() => {
          this.cv.Name = result.name;
          this.cdr.markForCheck();
        }, function (err) {
          console.error('[CvListElementComponent] Failed updating CV title', err);
        });
      } else {
        console.debug('[CvListElementComponent] Rename dialog was closed without confirmation');
      }
    });
  }

  duplicateCv() {
    this.cvDuplicated.emit(this.cv);
  }

  deleteCv() {
    if (this.cv.IsPrimary) {
      this.primaryDialogRef = this.dialog.open(CvPrimaryDialogComponent, {
        maxWidth: '95%',
        minWidth: '25%'
      });
    } else {
      this.removeDialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          dialogHTML: `Do you want to delete <strong>&quot;${this.cv.Name}&quot;</strong>?`,
          headerText: 'Delete CV',
          confirmButtonText: 'Delete CV',
          buttonsColor: ThemeColors.WARN
        }
      });

      this.removeDialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result) {
          this.cvRemoved.emit(this.cv);
        } else {
          console.debug('[CvListElementComponent] Remove dialog was closed without confirmation');
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.renameDialogRef) {
      this.renameDialogRef.close();
    }

    if (this.removeDialogRef) {
      this.removeDialogRef.close();
    }
  }
}
