import 'reflect-metadata';
import { FrontendFeature, FrontendFeaturesModule } from '../feature';
import { Feature } from '../interfaces/feature';

@FrontendFeaturesModule('Benefits')
export class BenefitsFeatures {
  @FrontendFeature({
    summary: 'Default benefits page'
  })
  static readonly Default: Feature = 'benefits';

  @FrontendFeature({
    summary: 'Go to benefits button'
  })
  static readonly GoTo: Feature = 'benefits/go-to';

  @FrontendFeature({
    summary: 'Benefits Dk offers list view page'
  })
  static readonly DkOffers: Feature = 'benefits/dk/offers';

  @FrontendFeature({
    summary: 'Benefits Dk Offer detail view page'
  })
  static readonly DkOffersItemView: Feature = 'benefits/dk/offers/item/view';

  @FrontendFeature({
    summary: 'Benefits Dk Offer detail preview page'
  })
  static readonly DkOffersItemPreview: Feature = 'benefits/dk/offers/item/preview';
}
