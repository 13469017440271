import { Injectable } from '@angular/core';
import { IVideoPlayerLog } from '../interfaces/video-player';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app-config';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { BehaviorSubject, Observable, catchError, of } from 'rxjs';

/** 
 Based on: 
  YT:
 https://developers.google.com/youtube/iframe_api_reference
 https://betterprogramming.pub/integrate-youtubes-iframe-player-api-in-angular-4e87f0ec98f4
 https://github.com/angular/components/tree/main/src/youtube-player#readme

 Vimeo:
 https://developer.vimeo.com/player/sdk/embed
*/

@Injectable({
  providedIn: 'root'
})
export class VideoPlayerService {
  private _ytApiLoaded = new BehaviorSubject<boolean>(false);
  readonly API_USER: string;
  readonly YT_API = 'https://www.youtube.com/iframe_api';

  get ytApiLoaded$(): Observable<boolean> {
    return this._ytApiLoaded.asObservable();
  }

  constructor(private http: HttpClient, private appConfig: AppConfig) {
    const userApiPrefix = this.appConfig.serviceUrl(ServiceNames.User, 'v1');
    this.API_USER = userApiPrefix + 'user/';
  }

  initYtIframeApi() {
    // https://github.com/angular/components/tree/main/src/youtube-player#readme
    if (!this._ytApiLoaded.getValue()) {
      console.debug('🟠 loading Youtube Iframe API');
      const tag = document.createElement('script');
      tag.src = this.YT_API;
      tag.addEventListener('load', () => {
        console.debug('🟢 YT API loaded');
        this._ytApiLoaded.next(true);
      });
      document.body.appendChild(tag);
    }
  }

  logVideoEvent(log: Partial<IVideoPlayerLog>) {
    if (log) {
      this.http
        .post<void>(`${this.API_USER}activity/played-video`, log)
        .pipe(
          catchError((err) => {
            console.error('[VideoPlayerService] Log error: ', err);
            return of(err);
          })
        )
        .subscribe();
    } else {
      console.error('[VideoPlayerService] No log data provided!');
    }
  }
}
