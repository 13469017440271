import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  from,
  share,
  switchMap,
  takeUntil
} from 'rxjs';
import { ISkill } from '../../../../interfaces/skill';
import { SkillsService } from '../../../../services/skills.service';

@Injectable({
  providedIn: 'root'
})
export class SkillsDictionaryService implements OnDestroy {
  listRefreshing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  allSkillList$: Observable<Array<ISkill>>;
  refreshSkill$: Subject<void> = new Subject();

  private allSkillListSource$: BehaviorSubject<Array<ISkill>> =
    new BehaviorSubject<Array<ISkill>>([]);

  private unsubscribe$ = new Subject<void>();

  constructor(private skillsService: SkillsService) {
    this.allSkillList$ = this.allSkillListSource$.asObservable();

    this.refreshSkill$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() => {
          this.listRefreshing$.next(true);
          return from(this.skillsService.getAllSkills());
        }),
        share()
      )
      .subscribe({
        next: (value) => {
          this.allSkillListSource$.next(value);
          this.listRefreshing$.next(false);
        },
        error: () =>  {
          this.listRefreshing$.next(false);
        } 
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }
}
