import { Injectable } from '@angular/core';
import {
  AuthenticatedResult,
  EventTypes,
  OidcSecurityService,
  PublicEventsService
} from 'angular-auth-oidc-client';
import {
  BehaviorSubject,
  Observable,
  defer,
  filter,
  fromEvent,
  retry,
  switchMap,
  take,
  zip
} from 'rxjs';
import { GlobalFacadeService } from './facades/global-facade.service';
import { ApplicationInsightsService } from './application-insights.service';
import { UserService } from './user.service';
import { OAUTH_CONFIG_ID } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  readonly MAX_TRIES = 3;
  readonly REDIRECT_URL_PROPERTY = 'redirectUrl';
  private _authenticated$ = new BehaviorSubject<boolean>(false);
  private _tries = 0;
  private _token$ = new BehaviorSubject<string>(null);
  private _allowedFeatures$ = new BehaviorSubject<Array<string>>([]);
  private _isInitializing$ = new BehaviorSubject<boolean>(false);
  private _postLoginUrl = new BehaviorSubject<string>(null);
  private _isTokenClaimsSet = false;
  private _oauthError = new BehaviorSubject<boolean>(false);

  localStorageEvents$ = fromEvent<StorageEvent>(window, 'storage');

  checkAuth$ = defer(() => this.oidcSecurityService.checkAuth()); // defer to prevent calling checkAuth on init

  get isTokenClaimsSet(): boolean {
    return this._isTokenClaimsSet;
  }

  get authenticated$(): Observable<boolean> {
    return this._authenticated$.asObservable();
  }

  get token$(): Observable<string> {
    return this._token$.asObservable().pipe(filter((token) => !!token));
  }

  get allowedFeatures$(): Observable<Array<string>> {
    return this._allowedFeatures$.asObservable();
  }

  get isInitializing$(): Observable<boolean> {
    return this._isInitializing$.asObservable();
  }

  get postLoginUrl$(): Observable<string> {
    return this._postLoginUrl.asObservable();
  }

  get oauthError$(): Observable<boolean> {
    return this._oauthError.asObservable();
  }

  updateAuthenticated(isAuthenticated: boolean) {
    this._authenticated$.next(isAuthenticated);
  }

  updateAllowedFeatures(allowedFeatures: Array<string>) {
    this._allowedFeatures$.next(allowedFeatures);
    this._isInitializing$.next(false);
  }

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private eventService: PublicEventsService,
    private globalFacade: GlobalFacadeService,
    private appInsightService: ApplicationInsightsService,
    private userService: UserService
  ) {}

  init() {
    this.registerOauthStateRedirectUrl();
    this.registerTokenAndClaims();
    this.registerLogoutListener();
    this.registerTokenRefreshListener();
    this.registerSuccessfulLoginListener();
    this.registerOAuthErrorsListener();

    // check if it is a redirect from b2c login page after sign up
    const signUpFlow = window.location.href.includes('#id_token');
    if (signUpFlow) {
      this.oidcSecurityService.setState(this.getCurrentRoute(), OAUTH_CONFIG_ID).subscribe((v)=>{
        // TODO: it doesn't cause automatic sing-in after sign-up (only redirects to the b2c sing-in page - needs to be improved with Angular 16 upgrade
        this.oidcSecurityService.authorize(OAUTH_CONFIG_ID);
      });
    } else {
      this.initAuthorization();
    }
  }

  clearPostLoginUrl() {
    // used after the user is redirected to it from the b2c login page
    this._postLoginUrl.next(null);
  }

  logout() {
    // user is redirected to the b2c logout page (postLogoutRedirectUri in the oauth config)
    this.oidcSecurityService.logoff();
  }

  private initAuthorization() {
    this._isInitializing$.next(true);
    this.checkAuth$.pipe(retry(2)).subscribe({
      next: ({ isAuthenticated, accessToken, errorMessage }) => {
        if (!isAuthenticated && !errorMessage && this._tries < this.MAX_TRIES) {
          this.setOauthState();
          this.oidcSecurityService.authorize(OAUTH_CONFIG_ID);
          this._tries++;
        }

        if (isAuthenticated && accessToken) {
          this._authenticated$.next(true);
          this._token$.next(accessToken);
        }
        this._isInitializing$.next(false);
      },
      error: (error) => {
        // only after trying to check auth 3 times
        console.error('[AuthenticationService] Error checking auth', error);
      }
    });
  }

  private registerLogoutListener() {
    // listens to the localstorage event and logs out the user if the authnResult is not present
    // needed if the user logs out from another tab
    this.localStorageEvents$
      .pipe(
        filter(
          (e) =>
            e.key === OAUTH_CONFIG_ID &&
            e.newValue !== null &&
            JSON.parse(e.newValue)['authnResult'] === undefined
        )
      )
      .subscribe(() => {
        this.logout();
      });
  }

  private registerTokenAndClaims() {
    this.oidcSecurityService.isAuthenticated$
      .pipe(
        filter(
          (isAuthenticated: AuthenticatedResult) =>
            isAuthenticated && isAuthenticated.isAuthenticated === true
        ),
        switchMap(() => {
          return this.oidcSecurityService
            .getPayloadFromAccessToken()
            .pipe(filter((payload) => payload !== null));
        })
      )
      .subscribe({
        next: (payload) => {
          if (Object.keys(payload).length > 0) {
            this.globalFacade.updateTokenClaims(payload);
            this.appInsightService.setClaimsData(payload);
            this._isTokenClaimsSet = true;
          }
        },
        error: (error) => {
          console.error(
            '[AuthenticationService] Error getting token claims',
            error
          );
        }
      });
  }

  private registerOauthStateRedirectUrl() {
    // this will update token in service when silent refresh regenerate token
    const newAuthResult$ = this.eventService
      .registerForEvents()
      .pipe(
        filter(
          (notification) =>
            notification.type === EventTypes.NewAuthenticationResult
        )
      );

    zip([newAuthResult$, this.oidcSecurityService.getState(OAUTH_CONFIG_ID)])
      .pipe(filter(([v, state]) => !!v && !!state))
      .subscribe(([v, state]) => {
        if (v.value?.isRenewProcess === false) {
          this._postLoginUrl.next(window.atob(state)); // set custom url after b2c login redirect only when it is not silent refresh
        }
      });
  }

  private getCurrentRoute(): string {
    return window.btoa(
      window.location.pathname + window.location.search + window.location.hash
    );
  }

  private setOauthState() {
    this.oidcSecurityService
      .setState(this.getCurrentRoute(), OAUTH_CONFIG_ID)
      .pipe(take(1))
      .subscribe();
  }

  private registerTokenRefreshListener() {
    this.eventService
      .registerForEvents()
      .pipe(
        filter(
          (notification) =>
            notification.type === EventTypes.NewAuthenticationResult &&
            notification.value.isRenewProcess === true &&
            notification.value.isAuthenticated === true
        ),
        switchMap(() => this.oidcSecurityService.getAccessToken())
      )
      .subscribe((accessToken: string) => {
        this._token$.next(accessToken);
      });
  }

  private registerSuccessfulLoginListener() {
    this.eventService
      .registerForEvents()
      .pipe(
        filter(
          (notification) =>
            notification.type === EventTypes.NewAuthenticationResult &&
            notification.value.isRenewProcess === false &&
            notification.value.isAuthenticated === true
        ),
        switchMap(() => this.userService.logSuccessfulLogin())
      )
      .subscribe();
  }

  private registerOAuthErrorsListener() {
    this.eventService
      .registerForEvents()
      .pipe(
        filter(
          (notification) =>
            notification.type === EventTypes.CheckingAuthFinishedWithError ||
            notification.type === EventTypes.ConfigLoadingFailed
        )
      )
      .subscribe((notification) => {
        console.error('[AuthenticationService] OAuth error', notification);
        this._oauthError.next(true);
        this.redirectToUnauthorizedHtml();
      });
  }

  private redirectToUnauthorizedHtml() {
    // moved to an external method only to avoid redirect in tests
    window.location.href = '/unauthorized.html';
  }
}
