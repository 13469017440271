<h4 mat-dialog-title>
  Parse CV<sup>(beta)</sup>
  <button mat-button
          isIconButton="true"
          (click)="closeDialog(true)"
          class="close-btn"
          data-test-id="cv-parse-dialog-close-button">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</h4>
<form [formGroup]="cvParseForm" name="cvParseForm" novalidate>
  <div mat-dialog-content class="flex flex-col">
    <section class="cv-parse-box" [formGroup]="cvParseForm">
      <cv-parse formControlName="cvToParse"
                data-test-id="form-cv-UploadedFiles">
        <validation-messages [field]="cvToParseControl"
                             [messages]="['fileSizeLimit', 'fileExtension']"
                             data-test-id="cv-parse-dialog-validationMessages"
                             [texts]="{
                               fileSizeLimit:
                                 'File size can\n’t be more then ' + CV_FILE_MAX_SIZE + 'MB.',
                               fileExtension:
                                 'Wrong file extension (we only accept ' +
                                 CV_VALID_FILE_EXT.join(', ').toLowerCase() +
                                 ').'
                             }">
        </validation-messages>
      </cv-parse>
    </section>
  </div>
</form>
