import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';

// COMPONENTS
import { TimelineComponent } from './components/timeline/timeline.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import {
  UnauthorizedErrorComponent,
  NotFoundErrorComponent,
  ServerErrorComponent,
  BadRequestErrorComponent,
  GoneErrorComponent,
  B2cErrorComponent
} from '@my7n/ui';
import { TermsAgreementComponent } from './components/terms-agreement/terms-agreement.component';
import { NewsViewComponent } from './components/timeline/news/news-page/news-view/news-view.component';
import { CmsNewsViewComponent } from './components/timeline/news/news-page/cms-news-view/cms-news-view.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { LoginRequiredErrorComponent } from './components/info-pages/login-required-error/login-required-error.component';

// RESOLVERS
import { NewsContentResolver } from './resolvers/news-content.resolver';
import { CmsNewsContentResolver } from './resolvers/cms-news-content.resolver';
import { UpdateAndReloadResolver } from './resolvers/update-and-reload.resolver';

// GUARDS
import { AuthGuard } from './guards/auth.guard';
import {ModuleEnabledGuard} from './guards/module-enabled.guard';

// INTERFACES
import { UserProfileFeatures, TimelineFeatures } from '@my7n/features';
import { SPECIAL_REDIRECT } from './interfaces/authorization';

const authProtectedRoutes: Routes = [{
    path: '',
    component: TimelineComponent,
    // this cannot use TimelineRedirectGuard because it redirect before the main AuthGuard manages to finish resolving promises
    // which results in redirecting first to /cv and then /unauthorized
    data: {
      viewClass: 'timeline-page',
      privileges: [TimelineFeatures.Default],
      specialRedirect: SPECIAL_REDIRECT,
      title: 'Timeline'
    },
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    component: WelcomePageComponent,
    data: {
      viewClass: '',
      noWelcome: true,
      title: 'Welcome page'
    }
  },
  {
    path: 'agreement',
    component: TermsAgreementComponent,
    data: {
      viewClass: '',
      noWelcome: true,
      noTerms: true,
      title: 'Agreement'
    }
  },
  {
    path: 'agent',
    loadChildren: () => import('./modules/my7n-agent.module').then(m => m.My7nAgentModule),
    canLoad: [ModuleEnabledGuard]
  },
  {
    path: 'cv',
    loadChildren: () => import('./modules/my7n-cv.module').then(m => m.My7nCvModule),
    canLoad: [ModuleEnabledGuard]
  },
  {
    path: 'events',
    loadChildren: () => import('./modules/my7n-events.module').then(m => m.My7nEventsModule),
    canLoad: [ModuleEnabledGuard]
  },
  {
    path: 'news/:newsId',
    component: NewsViewComponent,
    data: {
      viewClass: 'news-page',
      privileges: [TimelineFeatures.ItemView]
    },
    resolve: {
      news: NewsContentResolver
    }
  },
  {
    path: 'content/news/:newsId',
    component: CmsNewsViewComponent,
    data: {
      viewClass: 'news-page',
      privileges: [TimelineFeatures.ItemView]
    },
    resolve: {
      news: CmsNewsContentResolver
    }
  },
  {
    path: 'content/news/:newsId/preview',
    component: CmsNewsViewComponent,
    data: {
      viewClass: 'news-page',
      privileges: [TimelineFeatures.ItemPreview]
    },
    resolve: {
      news: CmsNewsContentResolver
    }
  },
  {
    path: 'profile/details',
    component: UserProfileComponent,
    data: {
      viewClass: 'user-profile-page',
      privileges: [UserProfileFeatures.Default],
      title: 'Profile details'
    }
  },
  {
    path: 'referrals',
    loadChildren: () => import('./modules/my7n-referrals.module').then(m => m.My7nReferralsModule),
    canLoad: [ModuleEnabledGuard]
  },
  {
    path: 'time-reporting', // Check [ADR 0007] to know why we use time-reporting name
    loadChildren: () => import('./modules/my7n-billing.module').then(m => m.My7nBillingModule),
    canLoad: [ModuleEnabledGuard]
  },
  {
    path: 'benefits',
    loadChildren: () => import('./modules/my7n-benefits.module').then(m => m.My7nBenefitsModule),
    canLoad: [ModuleEnabledGuard]
  },
  {
    path: 'evaluations',
    loadChildren: () => import('./modules/my7n-evaluations.module').then(m => m.My7nEvaluationsModule),
    canLoad: [ModuleEnabledGuard]
  },
  {
    path: 'academy',
    loadChildren: () => import('./modules/my7n-academy.module').then(m => m.My7nAcademyModule),
    canLoad: [ModuleEnabledGuard]
  },
  {
    path: 'career-dev-program',
    loadChildren: () => import('./modules/my7n-career-dev-program.module').then(m => m.My7nCareerDevProgramModule),
    canLoad: [ModuleEnabledGuard]
  },
  {
    // standalone lazy loaded component
    path: 'consultant-guide',
    loadComponent: () => import('./components/consultant-guide/consultant-guide.component').then(m => m.ConsultantGuideComponent),
    loadChildren: () => import('./components/consultant-guide/routes').then(m => m.CONSULTANT_GUIDE_ROUTES),
    canLoad: [ModuleEnabledGuard]
  },
];

const routes: Routes = [
  {
    path: 'unauthorized',
    component: UnauthorizedErrorComponent
  },
  {
    path: 'login-required',
    component: LoginRequiredErrorComponent
  },
  {
    path: 'not-found',
    component: NotFoundErrorComponent
  },
  {
    path: 'server-error',
    component: ServerErrorComponent
  },
  {
    path: 'bad-request',
    component: BadRequestErrorComponent
  },
  {
    path: 'gone',
    component: GoneErrorComponent
  },
  {
    path: 'b2c-error/:errorCode',
    component: B2cErrorComponent
  },
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: authProtectedRoutes
  },
  {
    path: '**',
    component: NotFoundErrorComponent,
    resolve: {
      updateAvailable: UpdateAndReloadResolver
    }
  }
];

export function my7nScrollOffset(): [number, number] {
  // @TODO need to adjust for second navbar and scaling using fonts (pix2rem equivalent)
  return [0, 80];
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    onSameUrlNavigation: 'reload',
    enableTracing: false,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: my7nScrollOffset
  })],
  exports: [RouterModule],
  providers: []
})
export class My7nAppRoutingModule { }
