import { Injectable } from '@angular/core';
import * as fromUserProfile from '../../store/reducers/user-profile/index';
import * as UserProfileActions from '../../store/actions/user-profile.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  IBusinessDetails,
  IConsultantsTenantDetails,
  IPersonalDetails,
  ITermsAndConditions
} from '../../interfaces/user-profile';
import { ILocation } from '../../interfaces/location';
import { INotificationSettings } from '../../interfaces/notification-settings';
import { IPhones } from '../../interfaces/phones';
import { IAddress } from '../../interfaces/address';

@Injectable({
  providedIn: 'root'
})
export class UserProfileFacadeService {
  constructor(private store: Store<fromUserProfile.State>) {}

  get personalDetails$(): Observable<IPersonalDetails> {
    return this.store.select(fromUserProfile.selectPersonalDetails);
  }

  get personalDetailsLoading$(): Observable<boolean> {
    return this.store.select(fromUserProfile.selectPersonalDetailsLoading);
  }

  get businessDetails$(): Observable<IBusinessDetails> {
    return this.store.select(fromUserProfile.selectBusinessDetails);
  }

  get businessDetailsLoading$(): Observable<boolean> {
    return this.store.select(fromUserProfile.selectBusinessDetailsLoading);
  }

  get preferredWorkLocations$(): Observable<Array<ILocation>> {
    return this.store.select(fromUserProfile.selectPreferredWorkLocations);
  }

  get preferredWorkLocationsLoading$(): Observable<boolean> {
    return this.store.select(
      fromUserProfile.selectPreferredWorkLocationsLoading
    );
  }

  get termsAndConditions$(): Observable<ITermsAndConditions> {
    return this.store.select(fromUserProfile.selectTermsAndConditions);
  }

  get termsAndConditionsLoading$(): Observable<boolean> {
    return this.store.select(fromUserProfile.selectTermsAndConditionsLoading);
  }

  get notificationSettings$(): Observable<INotificationSettings> {
    return this.store.select(fromUserProfile.selectNotificationSettings);
  }

  get notificationSettingsLoading$(): Observable<boolean> {
    return this.store.select(fromUserProfile.selectNotificationSettingsLoading);
  }

  get notificationSettingsSaving$(): Observable<boolean> {
    return this.store.select(fromUserProfile.selectNotificationSettingsSaving);
  }

  get consultantsTenantDetails$(): Observable<IConsultantsTenantDetails> {
    return this.store.select(fromUserProfile.selectConsultantsTenantDetails);
  }

  get consultantsTenantDetailsLoading$(): Observable<boolean> {
    return this.store.select(fromUserProfile.selectConsultantsTenantDetailsLoading);
  }

  queryPersonalDetails() {
    this.store.dispatch(UserProfileActions.queryPersonalDetails());
  }

  queryBusinessDetails() {
    this.store.dispatch(UserProfileActions.queryBusinessDetails());
  }

  queryPreferredWorkLocations() {
    this.store.dispatch(UserProfileActions.queryPreferredWorkLocations());
  }

  queryTermsAndConditions() {
    this.store.dispatch(UserProfileActions.queryTermsAndConditions());
  }

  queryNotificationSettings() {
    this.store.dispatch(UserProfileActions.queryNotificationSettings());
  }

  queryConsultantsTenantDetails() {
    this.store.dispatch(UserProfileActions.queryConsultantsTenantDetails());
  }

  updateNotificationSettingsSubscription(isAccepted: boolean) {
    this.store.dispatch(
      UserProfileActions.updateNotificationSettingsSubscription({
        wantToReceive: isAccepted
      })
    );
  }

  updatePersonalDetailsEmail(email: string) {
    this.store.dispatch(
      UserProfileActions.updatePersonalDetailsEmail({
        email
      })
    );
  }

  updatePersonalDetailsPhones(phones: IPhones) {
    this.store.dispatch(
      UserProfileActions.updatePersonalDetailsPhones({
        phones
      })
    );
  }

  updatePersonalDetailsAddress(address: IAddress) {
    this.store.dispatch(
      UserProfileActions.updatePersonalDetailsAddress({
        address
      })
    );
  }

  updateBusinessDetailsCompanyName(name: string) {
    this.store.dispatch(
      UserProfileActions.updateBusinessDetailsCompanyName({
        companyName: name
      })
    );
  }

  updateBusinessDetailsCompanyWebsite(name: string) {
    this.store.dispatch(
      UserProfileActions.updateBusinessDetailsCompanyWebsite({
        companyWebsite: name
      })
    );
  }

  updatePreferredWorkLocations(locations: Array<ILocation>) {
    this.store.dispatch(
      UserProfileActions.updatePreferredWorkLocations({
        locations
      })
    );
  }

  updateTermsPublicationWebsiteSubscription(accepted: boolean) {
    this.store.dispatch(
      UserProfileActions.updateTermsPublicationWebsiteSubscription({
        accepted
      })
    );
  }

  updateTermsEventInvitationSubscription(accepted: boolean) {
    this.store.dispatch(
      UserProfileActions.updateTermsEventInvitationSubscription({
        accepted
      })
    );
  }

  updateTermsNewsletterSubscription(accepted: boolean) {
    this.store.dispatch(
      UserProfileActions.updateTermsNewsletterSubscription({
        accepted
      })
    );
  }

  updateTermsStoringDataSubscription(accepted: boolean) {
    this.store.dispatch(
      UserProfileActions.updateTermsStoringDataSubscription({
        accepted
      })
    );
  }

  updateNotificationSettingsFrequency(settings: INotificationSettings) {
    this.store.dispatch(
      UserProfileActions.updateNotificationSettingsFrequency({
        settings
      })
    );
  }
}
