import { IProfileStrengthSection } from './profile-strength-section';

export enum ProfileStrengthKeys {
  AdditionalInformations = 'AdditionalInformations',
  CareerAndProjects = 'CareerAndProjects',
  Education = 'Education',
  IndustryKnowledge = 'IndustryKnowledge',
  Languages = 'Languages',
  MainCompetenceAreas = 'MainCompetenceAreas',
  Photo = 'Photo',
  ProfileSummary = 'ProfileSummary',
  Skills = 'Skills',
  WorkRoles = 'WorkRoles',
  ApplicationTypesAndBusiness = 'ApplicationTypesAndBusiness',
  Networks = 'Networks',
  Awards = 'Awards'
}

export interface IProfileStrength {
  [ProfileStrengthKeys.AdditionalInformations]: IProfileStrengthSection;
  [ProfileStrengthKeys.CareerAndProjects]: IProfileStrengthSection;
  [ProfileStrengthKeys.Education]: IProfileStrengthSection;
  [ProfileStrengthKeys.IndustryKnowledge]: IProfileStrengthSection;
  [ProfileStrengthKeys.Languages]: IProfileStrengthSection;
  [ProfileStrengthKeys.MainCompetenceAreas]: IProfileStrengthSection;
  [ProfileStrengthKeys.Photo]: IProfileStrengthSection;
  [ProfileStrengthKeys.ProfileSummary]: IProfileStrengthSection;
  Progress: number;
  [ProfileStrengthKeys.Skills]: IProfileStrengthSection;
  Status: 'Low' | 'Medium' | 'High';
  [ProfileStrengthKeys.WorkRoles]: IProfileStrengthSection;
}

export interface IProfileStrengthResponse {
  Strength: IProfileStrength;
}
