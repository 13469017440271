import { OpenIdConfiguration } from 'angular-auth-oidc-client';

type ServicesHashMap = {
  [serviceName in ServiceNames]: string;
};

export interface IMy7nEnvConfig {
  GOOGLE_API_KEY: string;
  APPLICATION_URL: string;
  EVALUATION_APPLICATION_URL: string;
  /**
   * @Deprecated
   */
  VERSION_HASH: string;
  /**
   * Severity level for messages which should be shown on client console
   */
  LOG_VERBOSITY_LEVEL: SeverityLevel;
  /**
   * Instrumentation key for Azure Application Insights
   */
  INSIGHTS_KEY: string;
  /**
   * Severity level for messages which should be send to Application Insights
   */
  INSIGHTS_VERBOSITY_LEVEL: SeverityLevel;
  /**
   * Toggle internal Application Insights log verbosity
   */
  INSIGHTS_INTERNAL_VERBOSITY: boolean;
  allowedUrls: Array<string>;
  services: Partial<ServicesHashMap>;
  discoveryDocumentUrl?: string; /** will be mandatory after moving to b2c completely */
  auth: Partial<OpenIdConfiguration>;
}

export enum ServiceNames {
  Benefits = 'Benefits',
  Cms = 'Cms',
  Consultant = 'Consultant',
  Core = 'Core',
  Cv = 'Cv',
  Events = 'Events',
  Evaluation = 'Evaluation',
  File = 'File',
  Gdpr = 'Gdpr',
  Notification = 'Notification',
  InstantNotification = 'InstantNotification',
  Referral = 'Referral',
  Billing = 'Billing',
  StaticContent = 'StaticContent',
  KeyValueStorage = 'KeyValueStorage',
  Identity = 'Identity',
  User = 'User',
  Sharepoint = 'Sharepoint'
}

export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4
}
