import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ICmsNewsViewItem, INewsList } from '../interfaces/news';
import { Router } from '@angular/router';
import { ResolveDecorator } from '../classes/resolve-decorator';
import { NewsContentfulService } from '../services/news-contentful.service';
import { catchError, EMPTY, Observable } from 'rxjs';

@Injectable()
export class CmsNewsContentResolver implements Resolve<any> {

  constructor(private newsContentfulService: NewsContentfulService,
              private router: Router) {}

  news: INewsList[] = [];

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICmsNewsViewItem> {
    const isPreview = route.url[3]?.path === 'preview';
    return this.newsContentfulService.getNewsById$(route.params.newsId, isPreview).pipe(
      catchError((error) => {
        console.error('[CmsNewsContentResolver] There was a problem while getting news content with id', route.params.newsId);
        // Error handling must with redirection must be there because components are loaded even when return EMPTY is set.
        // Check this issue: https://github.com/angular/angular/issues/24195
        ResolveDecorator.handleError(error, this.router);
        return EMPTY;
      })
    );
  }
}
