import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ICvBaseResponse} from '../../interfaces/cv-base-response';
import {lastValueFrom, Observable, Subject} from 'rxjs';
import {AppConfig} from '../../app-config';
import {ServiceNames} from '../../interfaces/my7n-env-config';
import {IProfileStrengthResponse, ProfileStrengthKeys} from '../../interfaces/profile-strength';
import {IProfileStrengthUpdatePayload} from '../../interfaces/profile-strength-update-payload';


export enum CvUserContext {
  AgentView = 'AgentView',
  ConsultantView = 'ConsultantView'
};

@Injectable()
export class CvCommonService {
  readonly API_CV: string;

  private _userContext: CvUserContext;

  set userContext(userContext: CvUserContext) {
    this._userContext = userContext;
  }

  get userContext(): CvUserContext {
    return this._userContext;
  }

  private profileStrengthChange: Subject<IProfileStrengthUpdatePayload> = new Subject<IProfileStrengthUpdatePayload>();
  profileStrengthChange$: Observable<IProfileStrengthUpdatePayload>;

  private _profileStrengthRefresh = new Subject<void>();
  profileStrengthRefresh$ = this._profileStrengthRefresh.asObservable();

  constructor(private http: HttpClient,
              private appConfig: AppConfig) {
    this.profileStrengthChange$ = this.profileStrengthChange.asObservable();
    this.API_CV = appConfig.serviceUrl(ServiceNames.Core) + 'cvs/';
  }

  private strengthChange(strength: IProfileStrengthUpdatePayload) {
    this.profileStrengthChange.next(strength);
  }

  private static forwardFailPromiseIntercept(error) {
    return Promise.reject(error);
  }

  // profile strength
  updateProfileStrength(cvId: number) {
    this.http.get<IProfileStrengthResponse>(this.API_CV + cvId + '/strength').subscribe({
      next: (result) => {
        this.profileStrengthChange.next({strength: result.Strength});
      },
      error: (error) => {
        console.error('[CvService] Error occurred during getting profile strength');
      }
    })
  }


  updateField(field: string, apiUrlSuffix: string): (cvId: number, value: string | number | Array<number|string|{Id: number, Order: number}>) => Promise<ICvBaseResponse> {
    const self = this;

    return function (cvId: number, value: string | number | Array<number|string|{Id: number, Order: number}>) {
      const dataObject = {
        [field]: value
      };

      return lastValueFrom(self.http.patch<ICvBaseResponse>(self.API_CV + cvId + '/' + apiUrlSuffix, dataObject))
        .then((result) => {
          self.updateProfileStrength(cvId);
          return result;
        }, (error) => {
          console.debug('[CvService] Failed to update ' + field, error);
          return Promise.reject(error);
        });
    };
  }


  commonPutPostUpdate(section: ProfileStrengthKeys, apiPart: string): (cvId: number, fields: any) => Promise<ICvBaseResponse> {
    const self = this;

    return function (cvId: number, fields): Promise<ICvBaseResponse> {
      const updateId = fields.Id;

      if (!cvId) {
        console.error('[CvService] Update of ' + section + ' failed: Missing cvId');
        return Promise.reject('[CvService] Update of ' + section + ' failed: Missing cvId');
      }

      if (updateId === undefined || updateId === null) {
        return lastValueFrom(self.http.post<ICvBaseResponse>(self.API_CV + cvId + apiPart, fields))
          .then((result) => {
            if (!result.Id) {
              console.error('[CvService] Response is missing ' + section + ' Id');
            }

            fields.Id = result.Id;
            self.updateProfileStrength(cvId);
            return result;
          }, (error) => {
            console.error('[CvService] Failed to update ' + section, error);
            return Promise.reject(error);
          });
      } else {
        return lastValueFrom(self.http.put<ICvBaseResponse>(self.API_CV + cvId + apiPart + updateId, fields))
          .then((result) => {
            self.updateProfileStrength(cvId);
            return result;
          }, (error) => {
            console.error('[CvService] Failed to update ' + section + ' entry with Id ' + updateId, error);
            return Promise.reject(error);
          });
      }
    };
  }

  commonRemove(apiPart: string): (cvId: number, removeId: number) => Promise<ICvBaseResponse> {
    const self = this;
    return function (cvId, removeId) {
      return lastValueFrom(self.http.delete<ICvBaseResponse>(self.API_CV + cvId + apiPart + removeId))
        .then((result) => {
          self.updateProfileStrength(cvId);
          return result;
        });
    };
  }
  configReload() {
    this.appConfig.invalidate();
    this.appConfig.init();
  }
}
