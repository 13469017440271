import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ICv} from '../../interfaces/cv';
import {lastValueFrom} from 'rxjs';
import {ServiceNames} from '../../interfaces/my7n-env-config';
import {AppConfig} from '../../app-config';

@Injectable()
export class CvListService {
  readonly API_CV: string;

  constructor(private http: HttpClient,
              private appConfig: AppConfig) {
    this.API_CV = appConfig.serviceUrl(ServiceNames.Core) + 'cvs/';
  }

  /**
   * Returns primary CV data.
   *
   * @returns {Promise<Object>}
   */
  getPrimaryCv(): Promise<ICv> {
    return lastValueFrom(this.http.get<ICv>(this.API_CV + 'primary'));
  }

  /**
   * Returns a list of CVs
   * @returns {Promise<Array<ICv>>}
   */
  getCvList(): Promise<Array<ICv>> {
    return lastValueFrom(this.http.get<Array<ICv>>(this.API_CV));
  }
}
