<tile [backgroundImage]="details.headerImageUrl"
      [defaultImage]="defaultImageUrl"
      [link]="[linkPrefix, details.id]"
      [ngClass]="{'with-video': hasVideo}">
  <div class="card-tags"
       *ngIf="hasTags"
       data-test-id="cms-card-tags-container">
    <div class="tag pill"
         *ngFor="let tag of details.tags; index as i"
         [attr.color]="'accent'"
         [attr.data-test-id]="'cms-card-tag-' + i">
      {{ tag }}
    </div>
  </div>

  <h4 class="card-title"
      [ngClass]="{'no-tags': !hasTags}"
      data-test-id="cms-card-title">
    <a [routerLink]="[linkPrefix, details.id]"
       [title]="details.title"
       (click)="stopPropagation($event)"
       data-test-id="cms-card-title-link">{{ details.title }}</a>
  </h4>

  <div class="card-footer flex flex-between-center md:flex-start-center"
       data-test-id="cms-card-footer">
    <div class="card-footer-author flex flex-start-center md:gap-12">
      <div class="card-footer-author-avatar max-md:hidden">
        <avatar [title]="details.author.fullName" [avatarUrl]="details.author.avatar"></avatar>
      </div>
      <div class="card-footer-author-name-container flex flex-col flex-[1_1_0%]">
        <span class="card-footer-author-name" data-test-id="cms-card-footer-author-name">{{ details.author.fullName }}</span>
      </div>
    </div>

    <div class="card-footer-dates flex flex-start-center gap-12">
      <mat-icon class="max-md:hidden" svgIcon="calendar_big"></mat-icon>
      <span class="card-footer-creation-date" data-test-id="cms-card-footer-creation-date">{{
        details.creationDate | defaultDate: 'day':true
      }}</span>
    </div>

    </div>
    <reactions [contentId]="details.id" [contentType]="reactionsContentType" data-test-id="reactions"></reactions>
</tile>
