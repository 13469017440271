import { Injectable } from '@angular/core';
import { EntryCollection } from 'contentful';
import { defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CmsContentTypes } from '../interfaces/cms';
import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-html-renderer';
import { ContentfulService } from './contentful.service';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app-config';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { LegalEntityCountry } from '../interfaces/legal-entity';

export interface IGraphQLRequestCollectionOptions extends IAdditionalOptions {
  skip?: number;
  limit?: number;
  country?: LegalEntityCountry;
  order?: string;
}

export interface IGraphQLRequestOptions extends IAdditionalOptions {
  country?: LegalEntityCountry;
}

export interface IAdditionalOptions {
  [key: string]: number | number[] | string | string[] | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ContentfulGetterService {
  readonly defaultSectionRendererOptions: Partial<Options> = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        const { url } = node.data.target.fields.file;
        return `<img src=${url} />`;
      }
    }
  };

  readonly API_CMS: string;

  constructor(
    private contentfulService: ContentfulService,
    private appConfig: AppConfig,
    private http: HttpClient
  ) {
    const cmsApiPrefix = this.appConfig.serviceUrl(ServiceNames.Cms, 'v1');
    this.API_CMS = cmsApiPrefix + 'cms-proxy';
  }

  getEntries<EntryType, ReturnObj>(
    previewMode: boolean,
    contentType: CmsContentTypes,
    responseHandler: <EntriesType, Return>(
      entries: EntryCollection<EntryType>,
      options: Partial<Options>
    ) => ReturnObj,
    query?: { [key: string]: any },
    sectionRenderOptions = this.defaultSectionRendererOptions
  ): Observable<ReturnObj> {
    const defaultQuery = {
      content_type: contentType
    };

    const requestQuery = query || defaultQuery;
    const client = previewMode
      ? this.contentfulService.previewClient
      : this.contentfulService.cdaClient;

    return defer(() => client.getEntries<EntryType>(requestQuery)).pipe(
      map((entries: EntryCollection<EntryType>) => {
        return responseHandler<EntryCollection<EntryType>, ReturnObj>(
          entries,
          sectionRenderOptions
        );
      })
    );
  }

  /**
   * Get entries collection by GraphQL API
   */
  get<EntryType, ReturnType>(
    contentType: CmsContentTypes,
    previewMode: boolean,
    responseHandler: <EntriesType, Return>(
      entries: EntryType,
      options: Partial<Options>
    ) => ReturnType,
    options: IGraphQLRequestCollectionOptions,
    sectionRenderOptions = this.defaultSectionRendererOptions
  ) {
    return this.http.get(`${this.API_CMS}${ previewMode ? '/preview' : '' }/get`, {
      params: {
        contentType,
        ...options
      }
    }).pipe(
      map((response: EntryType) => {
        return responseHandler<EntryType, ReturnType>(
          // @ts-ignore
          response,
          sectionRenderOptions
        );
      })
    );
  }


  /**
   * Get entries collection by GraphQL API
   */
  getById<EntryType, ReturnType>(
    id: string,
    contentType: CmsContentTypes,
    previewMode: boolean,
    responseHandler: <EntriesType, Return>(
      entries: EntryType,
      options: Partial<Options>
    ) => ReturnType,
    options: IGraphQLRequestOptions,
    sectionRenderOptions = this.defaultSectionRendererOptions
  ) {
    return this.http.get(`${this.API_CMS}${ previewMode ? '/preview' : '' }/getById/${id}`, {
      params: {
        contentType,
        ...options
      }
    }).pipe(
      map((response: EntryType) => {
        return responseHandler<EntryType, ReturnType>(
          response,
          sectionRenderOptions
        );
      })
    );

  }
}
