<div class="file-list-element flex flex-start-center">
  <ng-container *ngIf="!converting">
    <a *ngIf="file && blobUrl"
       mat-button
       type="button"
       color="primary"
       class="file-name download"
       data-test-id="file-upload-element-fileName"
       (click)="openFile($event)"
       [attr.title]="file.name"
       >
      {{ file.name }}
    </a>
    <button mat-icon-button
            color="warn"
            class="color-on-hover remove-file-btn"
            (click)="emitFileRemoval()"
            [disableRipple]="true"
            data-test-id="file-upload-element-removeFileBtn">
      <mat-icon svgIcon="close_tiny" class="no-transition"></mat-icon>
    </button>
  </ng-container>
</div>
