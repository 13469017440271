<tile [defaultImage]="headerListImage"
      [link]="canViewCv ? ['/cv/', cv.Id]: null">
  <div class="container cv-list-element-progress-{{ cv.Progress }} flex flex-col flex-between-stretch"
       id="cv-id-{{ cv.Id }}"
       data-test-id="cv-list-element-container">
    <div class="body">
      <div class="body-header flex flex-between-center">
        <div class="labels" data-test-id="cv-list-element-label">
          <span class="pill" 
                color="accent" 
                *ngIf="cv.IsSent" 
                data-test-id="cv-list-element-label-send-pill">Sent to 7N</span>
          <span class="pill" 
                color="alternative" 
                *ngIf="!cv.IsSent" 
                data-test-id="cv-list-element-label-draft-pill">Draft</span>
          <span class="language pill" data-test-id="cv-list-element-label-language-pill">{{ cvLanguage.LanguageName }}</span>
        </div>
        <div class="options-menu">
          <button mat-icon-button
                  color="primary"
                  class="options-menu-opener color-on-hover"
                  (click)="stopPropagation($event)"
                  [matMenuTriggerFor]="menu"
                  [disableRipple]="true"
                  data-test-id="cv-list-element-menu-button"
                  *ngIf="showMenuButton$ | async">
            <mat-icon svgIcon="dots" class="no-transition"></mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="dropdown-button-panel primary" data-test-id="cv-list-element-menu-items-container">
            <button  mat-menu-item
                     [disableRipple]="true"
                     class="cv-edit-button"
                     (click)="editCv()"
                     *authCan="cvEditFeature"
                     data-test-id="cv-list-element-menu-edit-option">
              Edit
            </button>
            <button mat-menu-item
                    [disableRipple]="true"
                    class="cv-rename-button"
                    (click)="showRenameDialog()"
                    *authCan="cvRenameFeature"
                    data-test-id="cv-list-element-menu-rename-option">
              Rename
            </button>
            <button mat-menu-item
                    [disableRipple]="true"
                    class="cv-copy-button"
                    (click)="duplicateCv()"
                    *authCan="cvDuplicateFeature"
                    data-test-id="cv-list-element-menu-duplicate-option">
              Duplicate
            </button>
            <button mat-menu-item
                    [disableRipple]="true"
                    class="cv-remove-button"
                    color="warn"
                    (click)="deleteCv()"
                    *authCan="cvDeleteFeature"
                    data-test-id="cv-list-element-menu-delete-option">
              Delete
            </button>
          </mat-menu>
        </div>
      </div>
      <h3 class="cv-name"
          data-test-id="cv-list-element-cv-name-container">
        <a *ngIf="canViewCv | async" 
           [routerLink]="['/cv/', cv.Id]" 
           (click)="stopPropagation($event)"
           data-test-id="cv-list-element-cv-name-container-link">
           {{cv.Name}}
        </a>
        <span *ngIf="!(canViewCv | async)" data-test-id="cv-list-element-cv-name-container-noLink">{{cv.Name}}</span>
      </h3>
      <p class="title" data-test-id="cv-list-element-cv-jobTitle">
        {{ cv.Title || "No job title" }}
      </p>
    </div>

    <div class="footer" data-test-id="cv-list-element-footer">
      <progress-bar [progress]="cv.Progress" label="CV strength" #progressBar>
        <div class="main-info flex flex-full">
          <h5 class="progress-header font-bold">
            {{ progressBar.label }}:
            <span numberScroll [value]="progressBar.progress">{{ progressBar.progress }}</span>
            %
          </h5>
        </div>
      </progress-bar>
      <div class="created">
        <mat-icon svgIcon="calendar_big"></mat-icon>
        <span class="created-text">
          Last modified {{ cv.LastModifiedDate | defaultDate: "day" : true : false }}
        </span>
      </div>
    </div>
  </div>
</tile>
