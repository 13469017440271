import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export class FileUtils {
  static convertFileToDataUrl(file: File): Observable<string> {
    const baseSubject: Subject<string> = new Subject();
    const fileReader = new FileReader();

    fileReader.onload = (output: ProgressEvent<FileReader>) => {
      if (output && output.target) {
        baseSubject.next(<string>output.target.result);
      } else {
        baseSubject.error(`[FileUtils.convertFileToBase64] problem with reading file ${file.name}`);
      }

      baseSubject.complete();
    };

    fileReader.onerror = (event: ProgressEvent<FileReader>) => {
      baseSubject.error(event);
      baseSubject.complete();
    };

    fileReader.readAsDataURL(file);

    return baseSubject.asObservable();
  }

  static convertFileToBase64(file: File): Observable<string> {
    return FileUtils.convertFileToDataUrl(file).pipe(
      map(dataUrl => {
        // Ignore file type from dataUrl, get only base64 string
        return /;base64,(.+)/.exec(dataUrl)[1];
      })
    );
  }
}
