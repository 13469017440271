<h1 mat-dialog-title>Rename CV
  <button mat-button isIconButton="true" (click)="cancelDialog();" class="close-btn">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</h1>
<form [formGroup]="renameForm" name="renameForm" (ngSubmit)="submitDialog()" novalidate>
  <div mat-dialog-content class="flex flex-col">
    <section>
      <mat-form-field appearance="outline">
        <mat-label>New CV name</mat-label>
        <input matInput
               cdkFocusInitial
               class="form-control"
               maxlength="{{ICV_MAX_LENGTHS.CvName}}"
               type="text"
               formControlName="CvName"
               placeholder="e.g. Resume for 7N"
               name="cvName" />
        <mat-error [field]="renameForm.get('CvName')" [messages]="['required', 'maxlength']" [texts]="{ required:'Enter a CV name' }"></mat-error>
      </mat-form-field>
    </section>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button color="accent" class="small-button" type="submit">Rename CV</button>
    <button mat-button color="primary" class="small-button" type="button" (click)="cancelDialog()">Cancel</button>
  </div>
</form>
