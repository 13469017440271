import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from '../services/authorization.service';
import { AuthCanDirective } from './auth-can.directive';
import { AppConfig } from '../app-config';

@Directive({
  selector: '[authCant]'
})
export class AuthCantDirective extends AuthCanDirective {

  @Input() set authCant(privilege: string) {
    // set checking mode to negation of given privileges
    this.setAuthCanNot(true);
    // trigger checking
    this.doCheck(privilege);
  }

  constructor(private templateRefX: TemplateRef<any>,
              private viewContainerX: ViewContainerRef,
              private authorizationServiceX: AuthorizationService,
              private appConfigX: AppConfig) {
    super(templateRefX, viewContainerX, authorizationServiceX, appConfigX);
  }
}
