import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html'
})
export class TooltipComponent implements OnInit {
  @Input() text: string;

  constructor() {}

  ngOnInit() {}
}
