import {Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import { EventTypeString, IEvent } from '../../../interfaces/event';
import { EventsService } from '../../../services/events.service';
import { IEventsResponse } from '../../../interfaces/events-response';
import { MediaQueryService } from '@my7n/ui';

export interface IEventBoxConfig {
  filter: string;
  offset: number;
  size: number;
  includeUnannounced: boolean;
  type: EventTypeString;
  hideSwipeForMobile: boolean;

  texts: {
    header: string;
    allEventsButton: string;
    noEvents: string;
  };
}

@Component({
  selector: 'event-box',
  templateUrl: './event-box.component.html',
  styleUrls: ['./event-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventBoxComponent implements OnInit {
  @Input() config: IEventBoxConfig = {
    filter: 'upcoming',
    offset: 0,
    size: 3,
    includeUnannounced: false,
    type: null,
    hideSwipeForMobile: false,
    texts: {
      header: 'Upcoming events',
      allEventsButton: 'Show all events',
      noEvents: 'There are no upcoming events. If you want to suggest new event please contact your agent.'
    }
  };

  eventsList: IEvent[] = [];

  constructor(
    private eventsService: EventsService,
    public mediaQueryService: MediaQueryService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const { filter, offset, size, includeUnannounced, type } = this.config;

    this.eventsService.getEvents(filter, offset, size, includeUnannounced, type).subscribe((response: IEventsResponse) => {
      this.eventsList = response.Events;
      this.cdr.markForCheck();
    });
  }
}
