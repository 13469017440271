import 'reflect-metadata';
import { FrontendFeature, FrontendFeaturesModule } from '../feature';
import { Feature } from '../interfaces/feature';

@FrontendFeaturesModule('Academy')
export class AcademyFeatures {
  @FrontendFeature({
    summary: 'Default academy page'
  })
  static readonly Default: Feature = 'academy';

  @FrontendFeature({
    summary: 'Library academy page'
  })
  static readonly Library: Feature = 'academy/library';

  @FrontendFeature({
    summary: 'Book detail view page'
  })
  static readonly BookItemView: Feature = 'academy/books/item/view';

  @FrontendFeature({
    summary: 'Book detail preview page'
  })
  static readonly BookItemPreview: Feature = 'academy/books/item/preview';

  @FrontendFeature({
    summary: 'Trainings page'
  })
  static readonly Trainings: Feature = 'academy/trainings';

  @FrontendFeature({
    summary: 'About page'
  })
  static readonly About: Feature = 'academy/about';

  @FrontendFeature({
    summary: 'Academy Videos main page'
  })
  static readonly Videos: Feature = 'academy/videos';

  @FrontendFeature({
    summary: 'Academy video detail view page'
  })
  static readonly VideosItemView: Feature = 'academy/videos/item/view';

  @FrontendFeature({
    summary: 'Academy video detail preview page'
  })
  static readonly VideosItemPreview: Feature = 'academy/videos/item/preview';

  @FrontendFeature({
    summary: 'Academy Offers main page'
  })
  static readonly Offers: Feature = 'academy/offers';

  @FrontendFeature({
    summary: 'Academy Offers detail view page'
  })
  static readonly OffersItemView: Feature = 'academy/offers/item/view';

  @FrontendFeature({
    summary: 'Academy Offers detail preview page'
  })
  static readonly OffersItemPreview: Feature = 'academy/offers/item/preview';
}
