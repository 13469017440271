import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IUpdateAvatarResponse} from '../../interfaces/user-profile';
import {iif, lastValueFrom} from 'rxjs';
import {CvUserContext} from './cv-common.service';
import {ICvBaseResponse} from '../../interfaces/cv-base-response';
import {CvCommonService} from './cv-common.service';
import {AppConfig} from '../../app-config';
import {ServiceNames} from '../../interfaces/my7n-env-config';
import {UserService} from '../user.service';
import {AgentSearchFacadeService} from '../facades/agent-search-facade.service';

@Injectable()
export class CvProfileSummaryService {
  readonly API_CV: string;

  constructor(private http: HttpClient,
              private cvCommonService: CvCommonService,
              private appConfig: AppConfig,
              private userService: UserService,
              private agentSearchFacadeService: AgentSearchFacadeService) {
    this.API_CV = appConfig.serviceUrl(ServiceNames.Core) + 'cvs/';
  }

  updateFullName(cvId: number, fields) {
    const self = this;

    return lastValueFrom(this.http.patch<ICvBaseResponse>(this.API_CV + cvId + '/shareddata/fullname', fields))
      .then((result) => {
        self.cvCommonService.updateProfileStrength(cvId);
        this.cvCommonService.configReload();
        // @TODO refresh config only after current user changes Full Name for himself

        return result;
      }, (error) => {
         return Promise.reject(error);
      });
  }

  updatePortrait(consultantId: string, portraitData: string, cvId: number): Promise<IUpdateAvatarResponse> {
    const forAgentFlow$ = this.userService.updatePortraitForAgent(portraitData, consultantId);
    const forConsultantFlow$ = this.userService.updatePortraintForConsultant(portraitData);
    return lastValueFrom(iif(() => this.cvCommonService.userContext === CvUserContext.AgentView, forAgentFlow$, forConsultantFlow$))
      .then((result) => {
        this.cvCommonService.configReload();
        // update consultant avatar url in search results if needed
        this.agentSearchFacadeService.updateConsultantPortraitUrl(consultantId, result.PortraitUrl);
        this.cvCommonService.updateProfileStrength(cvId);
        return result;
      }, (error) => {
        console.debug('[CvService] Failed to update portrait', error);
        return Promise.reject(error);
      });
  }

  removePortrait(userId: string, cvId: number) {
    const forAgentFlow$ = this.userService.removePortraitForAgent(userId);
    const forConsultantFlow$ = this.userService.removePortraintForConsultant();
    return lastValueFrom(iif(() => this.cvCommonService.userContext === CvUserContext.AgentView, forAgentFlow$, forConsultantFlow$))
      .then((result) => {
        this.cvCommonService.updateProfileStrength(cvId);
        this.cvCommonService.configReload();
        // @TODO refresh config only after current user changes portrait for himself'
        return result;
      }, (error) => {
        console.error('[CvService] Failed to remove Portrait ', error);
        return Promise.reject(error);
      });
  }
  updateLanguage = this.cvCommonService.updateField('Language', 'language');
  updateTitle = this.cvCommonService.updateField('Title', 'profilesummary/title');
  updateBirthday = this.cvCommonService.updateField('Birthday', 'shareddata/birthday');
  updateAvailableFrom = this.cvCommonService.updateField('AvailableFrom', 'shareddata/availableFrom');
  updateExperienceSince = this.cvCommonService.updateField('ExperienceSince', 'profilesummary/experienceSince');
  updateProfileSummary = this.cvCommonService.updateField('ProfileSummary', 'profilesummary/summary');
}
