<layout-container>
  <mat-card class="container-fluid" id="terms-screen">
    <form (ngSubmit)="sendAgreement(termsForm)" name="termsForm" [formGroup]="termsForm" [ngClass]="{'loading': loading}" novalidate>
      <div class="terms-screen-container single-section-screen-container">
        <div class="terms-screen single-section-screen">
          <h4>Terms and Conditions</h4>
          <div class="terms-agreement-content single-section-screen-content">
            <p>By giving your consent, you accept that 7N will collect and process the information that you are submitting as part of the above form.</p>
            <p>7N will be processing your personal data including photos based on your consent which you will provide when signing this consent form. </p>
            <p>The information will be saved in 7N’s internal system and sent to our clients as part of your job search. Your information will also be used to create a summary of your CV, which will be made public on your profile in my7N.</p>
            <p>7N’s processing of your information entails that your information may be transferred to recipients outside of the EU/EEA. By giving your consent you agree that 7N may transfer your information to clients located inside and outside the EU/EEA.</p>
            <p>You have the right to withdraw your consent at any time. If you wish to withdraw your consent or have your data rectified or deleted, please contact your 7N consultant manager or <a href="mailto:privacy@7N.com">privacy@7N.com</a>.</p>
            <p>7N sends out monthly newsletters via emails to you if you have given your consent to this. These newsletters may include information about news, events, 7N’s services and product range. You may at any time withdraw your consent by either following the unsubscribe link at the bottom of the newsletters or on your my7N user profile.</p>
            <p>From time to time 7N sends out invitations for professional and social events and general information regarding 7N to you, if you have given your consent to this. You may at any time withdraw your consent by either following the unsubscribe link at the bottom of those emails or on your my7N user profile.</p>
            <p>Your data will be stored for as long as your profile is active. If you have not updated your profile for 5 years, we will send you a request to do so if you wish to keep your profile active. If no action is taken within 3 months, your profile will be erased.</p>
            <p>For more information, including information about other processing activities and legal bases, please see our <a href="https://www.7N.com/privacy-policy/" target="_blank">Privacy Policy</a>.</p>
          </div>
          <div class="agreements flex flex-col flex-between-start">
            <div class="terms-agreement-item">
              <div class="mat-checkbox-container">
                <mat-checkbox color="primary" formControlName="AcceptStoringAndSendingDataBy7n">Yes, I have read and consent to that the information provided by me may be stored by 7N and sent to potential clients.</mat-checkbox>
                <validation-messages [field]="termsForm.controls['AcceptStoringAndSendingDataBy7n']"
                                     messages="['required']"
                                     [texts]="{ required: 'You need to agree with the terms to proceed' }">
                                     </validation-messages>
              </div>
            </div>
            <div class="terms-agreement-item">
              <div class="mat-checkbox-container">
                <mat-checkbox color="primary" formControlName="AcceptPublicationDataOnWebsiteBy7n">Yes, I have read and consent to that the information including photos provided by me may be used to create a summary of my CV and that this summary may be made public on your profile in my7N.</mat-checkbox>
              </div>
            </div>
            <div class="terms-agreement-item">
              <div class="mat-checkbox-container">
                <mat-checkbox color="primary" formControlName="AcceptMy7nEventInvitation" name="terms-agreement-checkbox-2">Yes, I want to receive 7N event invitations and general information.</mat-checkbox>
              </div>
            </div>
            <div class="terms-agreement-item">
              <div class="mat-checkbox-container">
                <mat-checkbox color="primary" formControlName="AcceptMy7nNewsletter">By signing up for 7N’s newsletter I agree to receive e-mails with news and information about 7N’s services.</mat-checkbox>
                <small>You may withdraw your consent to receive newsletters from 7N at any time by following the unsubscribe link at the bottom of newsletters. Read more about 7N’s processing of your personal data in 7N’s <a href="https://www.7N.com/privacy-policy/" target="_blank">privacy policy</a>.</small>
              </div>
            </div>
            <mat-error *ngIf="termsForm.hasError('serverError')">There was a problem while saving agreements, try again later or contact my7N Support</mat-error>
          </div>
          <hr>
          <div class="terms-screen-actions single-section-screen-actions flex flex-end-center">
            <button mat-flat-button color="accent" type="submit" [loading]="loading">I agree</button>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</layout-container>
