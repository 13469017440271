import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CvCommonService} from './cv-common.service';

@Injectable()
export class CvUserExperienceService {
  constructor(private http: HttpClient,
              private cvCommonService: CvCommonService) {
  }

  // Industry focuses  ------------------------------------------------------------
  updateIndustryFocus = this.cvCommonService.updateField('IndustryFocus', 'industryKnowledge');

  // Work roles
  updateWorkRoles = this.cvCommonService.updateField('WorkRoles', 'workroles');

  // Competences area  ------------------------------------------------------------
  updateCompetenceAreas = this.cvCommonService.updateField('CompetenceAreas', 'competenceAreas');
}
