<ng-container [formGroup]="formGroupReference">
<p-editor #htmlEditor [formControlName]="editorControlName" [style]="{'height':'320px'}">
  <p-header>
    <span class="ql-format-group">
        <select title="Header" class="ql-header">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option selected>Normal</option>
        </select>
        <span class="ql-format-separator"></span>
        <button class="ql-bold" title="Bold" aria-label="Bold"></button>
        <button class="ql-italic" title="Italic" aria-label="Italic"></button>
        <button class="ql-underline" title="Underline" aria-label="Underline"></button>
        <button class="ql-strike" title="Strike" aria-label="Strike"></button>
        <select class="ql-align" title="Text Alignment">
          <option selected>Gauche</option>
          <option value="center" label="Center"></option>
          <option value="right" label="Right"></option>
          <option value="justify" label="Justify"></option>
        </select>
        <span class="ql-format-separator"></span>
        <button class="ql-list" value="ordered" title="Ordered list" aria-label="Ordered List"></button>
        <button class="ql-list" value="bullet" title="Unordered list" aria-label="Unordered List"></button>
        <span class="ql-format-separator"></span>
        <button class="ql-link" title="Insert Link" aria-label="Insert Link"></button>
        <span class="ql-format-separator"></span>
        <button class="ql-clean" title="Remove styles" aria-label="Remove Styles"></button>
        <button #showHtmlBtn (click)="onShowHtmlClick()" title="Show HTML source" aria-label="Show HTML source">
          <svg viewBox="0 0 18 18">
            <polyline class="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline>
            <polyline class="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline>
            <line class="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line>
          </svg>
        </button>
    </span>
  </p-header>
</p-editor>
</ng-container>
