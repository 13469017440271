import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[nav2ndLevelHost]'
})
export class Nav2ndLevelHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
