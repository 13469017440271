<ng-container [formGroup]="form">
  <div class="custom-upload-container flex flex-col flex-start-stretch gap-12 flex-[1_1_0%]">
    <div *ngIf="thumbnailImageSource"
         class="flex flex-between-center md:flex-end-center gap-21">
      <button *ngIf="isMobile || (cropper.active && cropper.ready)" 
              mat-button type="button" 
              color="primary" 
              (click)="customUploadFileInput.click()"
              class="change-photo-btn"
              data-test-id="customUpload-changePhoto-button">Change photo</button>
      <button mat-button 
              type="button" 
              color="warn" 
              (click)="removePhoto()" 
              class="remove-photo-btn"
              data-test-id="customUpload-removePhoto-button">Remove photo</button>
    </div>

    <div class="file-upload-box"
         [style.background-image]="thumbnailImageSource ? safeThumbnailImageSource : null"
         dragAndDropFile
         (fileDropped)="uploadFileChanged($event)"
         [ngClass]="{ 'box-with-image': thumbnailImageSource, error: isUploadFormInvalid() }"
         [hidden]="cropper.active">
      <div class="file-upload-box-content flex flex-col flex-center-center md:gap-12 w-full">
        <div class="desc max-md:hidden">
          Choose photo from the gallery,<br />
          drag and drop your own image here
        </div>
        <div class="or max-md:hidden">or</div>
        <div>
          <input #customUploadFileInput type="file" [accept]="acceptedMimeTypes" (change)="uploadFileChanged()"
            name="customBanner" style="display: none;" data-test-id="customUpload-uploadFile-input"/>
          <button class="max-md:hidden" mat-stroked-button type="button" color="primary" (click)="customUploadFileInput.click()">
            Add file
          </button>
          <div class="md:hidden flex flex-col flex-center-center gap-6 sm:gap-12">
            <img class="photo-upload-img" src="/assets/my7n-ui/icons/photo_upload.svg"
              (click)="customUploadFileInput.click()" />
            <button mat-button type="button" color="primary" (click)="customUploadFileInput.click()" data-test-id="customUpload-addFile-button">
              Add file
            </button>
          </div>
        </div>
        <div class="hint typography-small-paragraph">
          Recommended resolution is 1920 x 400px
        </div>
      </div>
    </div>
    <div class="cropper-container flex flex-col flex-center-center flex-auto">
      <div class="cropper-loader flex flex-col flex-center-center flex-auto"
         *ngIf="cropper.active && !cropper.ready">
        <loader color="primary"></loader>
      </div>
      <image-cropper [imageFile]="cropper.image"
                     [maintainAspectRatio]="true"
                     [aspectRatio]="1920 / 400"
                     [resizeToWidth]="1920"
                     [cropperMinWidth]="1920"
                     format="png"
                     (imageCropped)="imageCropped($event)"
                     (cropperReady)="cropperReady()"
                     (loadImageFailed)="loadImageFailed()"
                     *ngIf="cropper.active"></image-cropper>
    </div>
  </div>
</ng-container>
