import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICmsVideoUpdateItem } from '../interfaces/cms';
import { VideoUpdateContentfulService } from './video-update-contentful.service';
import { LegalEntityFeatures } from '@my7n/features';

@Injectable({
  providedIn: 'root'
})
export class VideoUpdateService {
  constructor(private contentful: VideoUpdateContentfulService) {}

  getLatestVideoUpdateByLegalEntityFeature(
    feature: LegalEntityFeatures
  ): Observable<ICmsVideoUpdateItem> {
    return this.contentful.getLatestVideoUpdateLegalEntityCountryFeature(
      feature
    );
  }

  getVideoUpdatePreviewById(id: string): Observable<ICmsVideoUpdateItem> {
    return this.contentful.getVideoUpdatePreviewById(id);
  }
}
