import {Component, OnInit, Input} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit {
  /**
   * Reference to form group object
   */
  @Input() formGroupReference:UntypedFormGroup;

  /**
   * Editor's formControlName value.
   */
  @Input() editorControlName: string;

  /**
   * Flag indicates whether HTML source view is on.
   * @type {boolean}
   */
  htmlViewMode = false;

  constructor() {}

  ngOnInit() {}

  /**
   * Handles click on 'ShowHtml' button.
   * @returns {boolean}
   */
  onShowHtmlClick() {
    this.htmlViewMode = !this.htmlViewMode;

    if (this.htmlViewMode) {
      console.debug('[HTMLEditorComponent] Escaping source...');
      this.formGroupReference.patchValue({
        'eventDescription': HtmlEditorComponent.escapeHtml(this.formGroupReference.get('eventDescription').value)
      });
    } else {
      console.debug('[HTMLEditorComponent] Unescaping source...');
      this.formGroupReference.patchValue({
        'eventDescription': HtmlEditorComponent.unescapeHtml(this.formGroupReference.get('eventDescription').value)
      });
    }

    return false;
  }

  static escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  static unescapeHtml(safe) {
    return safe
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g,'"')
      .replace(/&#039;/g, "'");
  }

}
