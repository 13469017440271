import { BenefitsFeatures } from './benefits/benefits.features';
import { ReferralsFeatures } from './referrals/referrals.features';
import { TimelineFeatures } from './timeline/timeline.features';
import { BillingFeatures } from './billing/billing.features';
import { CommonFeatures } from './common/common.features';
import { ConsultantsFeatures } from './consultants/consultants.features';
import { CvFeatures } from './cv/cv.features';
import { EvaluationsFeatures } from './evaluations/evaluations.features';
import { EventsFeatures } from './events/events.features';
import { NotificationsFeatures } from './notifications/notifications.features';
import { UserProfileFeatures } from './user-profile/user-profile.features';
import {
  getFrontendFeatureDetails,
  getFrontendFeaturesModuleName
} from './feature';
import { ClassType, FeatureDescription, FrontendFeatureDecorator } from './interfaces/feature';
import { LegalEntityFeatures } from './legal-entity/legal-entity.features';

export class FeatureDiscovery {
  private static featureModules: Array<ClassType> = [
    BenefitsFeatures,
    BillingFeatures,
    CommonFeatures,
    ConsultantsFeatures,
    CvFeatures,
    EvaluationsFeatures,
    EventsFeatures,
    NotificationsFeatures,
    ReferralsFeatures,
    TimelineFeatures,
    UserProfileFeatures,
    LegalEntityFeatures
  ];

  static getFeatures(): Array<FeatureDescription> {

    const defaultFeature: FeatureDescription = {
      Module: undefined,
      Target: 'my7N',
      Feature: undefined,
      Type: 'Frontend',
      Description: undefined
    };
    const featureList: Array<FeatureDescription> = [];

    FeatureDiscovery.featureModules.forEach((module) => {
      const moduleName = getFrontendFeaturesModuleName(module);

      if (!moduleName) {
        throw new Error(`Class '${module.name}' is missing @FrontendFeaturesModule decorator`);
      }

      const moduleFeaturesKeys = Object.keys(module);

      if (moduleFeaturesKeys.length === 0) {
        throw new Error(`Class '${module.name}' (${moduleName}) is missing features`);
      }

      moduleFeaturesKeys.forEach((featureKey) => {
        const featureMetaDetails: FrontendFeatureDecorator = getFrontendFeatureDetails(module, featureKey);
        const feature: FeatureDescription = {...defaultFeature, Module: moduleName};

        if (featureMetaDetails) {
          // Decorator may override module name
          feature.Module = featureMetaDetails.module || moduleName;
          feature.Feature = module[featureKey];
          feature.Description = featureMetaDetails.summary;
        } else {
          console.warn(`Feature details are missing in ${module.name}.${featureKey}, add @FrontendFeature decorator`);
        }

        featureList.push(feature);
      });
    });

    return featureList;
  }
}
