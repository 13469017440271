<layout-container>
  <div class="welcome-screen-headers flex flex-col flex-start-center gap-36">
    <h1>Welcome to my7N!</h1>
    <h4>Here you can create your CV. Remember that the more effort you put into your CV preparation the better job conditions we are likely to negotiate for you.</h4>
  </div>
  <div class="welcome-content-video-container">
    <mat-card class="welcome-content single-section-screen-content">
      <div class="welcome-content-video" #welcomeContentVideo [ngStyle]="{height: videoPlayerConfig.containerHeight + 'px'}">
        <div class="welcome-content-video-plug" *ngIf="!showVideo"></div>
        <button mat-stroked-button color="primary" class="introduction-button" *ngIf="!watchedVideo && !showVideo" (click)="show()">Watch introduction</button>
        <button mat-flat-button color="accent" class="create-cv-button" *ngIf="watchedVideo && !showVideo" (click)="letMeIn()">Create new CV</button>
        <my7n-video-player *ngIf="showVideo"
                           videoUrl="https://player.vimeo.com/video/400226605"
                           [module]="LogVideoModules.Welcome" 
                           title="my7N Welcome Video"
                           articleId="my7n-welcome-video"
                           [config]="videoPlayerConfig"
                           (videoEvent)="onVideoPlayerEvent($event)"></my7n-video-player>
      </div>
    </mat-card>
  </div>
  <div class="welcome-screen-actions single-section-screen-actions flex flex-center-center">
    <button mat-button isIconButton="true" color="primary" class="button-hidden-icon" [disableRipple]="true" (click)="letMeIn()">
      Skip introduction and create new CV
      <mat-icon svgIcon="arrow_right"></mat-icon>
    </button>
  </div>
</layout-container>


