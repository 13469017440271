import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Icons, SnackBarService, SnackBarTypes } from '@my7n/ui';
import { combineLatest, iif, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  switchMap,
  filter
} from 'rxjs/operators';
import { NewsHelper } from '../../utils/helpers/news.helper';

// NgRx
import * as TimelineNewsActions from '../actions/timeline-news.actions';
import * as fromTimeline from '../reducers/timeline';
import * as fromTimelineNews from '../reducers/timeline/news';

// interfaces

// services
import { TimelineService } from '../../services/timeline.service';
import { INewsBase, NewsTypes } from '../../interfaces/news';
import { NewsContentfulService } from '../../services/news-contentful.service';
import { AuthorizationService } from '../../services/authorization.service';
import { TimelineFeatures } from '@my7n/features';
import { GlobalFacadeService } from '../../services/facades/global-facade.service';

@Injectable()
export class TimelineNewsEffects {
  constructor(
    private actions$: Actions,
    private timelineService: TimelineService,
    private snackBarService: SnackBarService,
    private newsContentfulService: NewsContentfulService,
    private store$: Store<fromTimeline.TimelineModuleState>,
    private globalFacadeService: GlobalFacadeService,
    private authService: AuthorizationService
  ) {}

  queryNews$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineNewsActions.queryNews),
      concatLatestFrom(() => [
        this.store$.select(fromTimelineNews.selectLoadedInfo),
        this.store$.select(fromTimelineNews.selectPageSize),
        this.globalFacadeService.legalEntityCountryFeature$,
        this.authService.can(TimelineFeatures.ItemPreview)
      ]),
      filter(([props, loadedInfo, pageSize, legalEntityFeature, canPreview]) => !props.previewMode || (props.previewMode && canPreview)),
      distinctUntilChanged(),
      switchMap(([props, loadedInfo, pageSize, legalEntityFeature, canPreview]) => {
        const yammerNewsResponse$ = this.timelineService
        .getNews(loadedInfo.newsYammerLoadedCount || 0);
        const cmsNewsResponse$ = this.newsContentfulService.getNewsByLegalEntityCountryFeature(legalEntityFeature, loadedInfo.newsCmsLoadedCount || 0, pageSize, props.previewMode);
        const news$ = combineLatest([yammerNewsResponse$, cmsNewsResponse$]);

        return news$.pipe(
          map(([yammerNewsResponse, cmsNewsResponse]) => {
            const newsArray: Array<INewsBase> = [].concat(...[yammerNewsResponse.News, cmsNewsResponse.news]);
            const newsSorted = newsArray.sort(NewsHelper.sortNewsByDate).slice(0, pageSize);
            const cmsItemsCount = newsSorted.filter( news => news.Type === NewsTypes.CMS_NEWS).length;

            return TimelineNewsActions.queryNewsSuccess({
              news: newsSorted,
              loadedInfo: {
                newsCmsAllLoaded: cmsNewsResponse.total === loadedInfo.newsCmsLoadedCount + cmsItemsCount,
                newsCmsLoadedCount: loadedInfo.newsCmsLoadedCount + cmsItemsCount,
                newsYammerAllLoaded: yammerNewsResponse.Total === loadedInfo.newsYammerLoadedCount + (newsSorted.length - cmsItemsCount),
                newsYammerLoadedCount: loadedInfo.newsYammerLoadedCount + (newsSorted.length - cmsItemsCount)
              }
            });
          }),
          catchError(() => {
            this.snackBarService.open({
              message: 'Error while retrieving news list',
              type: SnackBarTypes.ErrorAlt
            });
            return of(TimelineNewsActions.queryNewsError());
          })
        );
      })
    );
  });

  queryNewsNoAccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineNewsActions.queryNews),
      concatLatestFrom(() => [
        this.authService.can(TimelineFeatures.ItemPreview)
      ]),
      filter(([props, canPreview]) => props.previewMode && !canPreview),
      distinctUntilChanged(),
      switchMap(() => {
        this.snackBarService.open({
          message: 'No privilege for news preview',
          type: SnackBarTypes.ErrorAlt,
          duration: 0,
          actionIcon: Icons.CLOSE
        });
        return of(TimelineNewsActions.queryNewsError());
      })
    );
  });

  queryMoreNews$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineNewsActions.queryMoreNews),
      concatLatestFrom(() => [
        this.store$.select(fromTimelineNews.selectLoadedInfo),
        this.store$.select(fromTimelineNews.selectPageSize),
        this.globalFacadeService.legalEntityCountryFeature$,
      ]),
      distinctUntilChanged(),
      switchMap(([props, loadedInfo, pageSize, legalEntityFeature]) => {
        const yammerNewsResponse$ = iif(() => loadedInfo.newsYammerAllLoaded, of({ News: [], Total: loadedInfo.newsYammerLoadedCount }), this.timelineService.getNews(loadedInfo.newsYammerLoadedCount || 0));
        const cmsNewsResponse$ = iif(() => loadedInfo.newsCmsAllLoaded, of({ news: [], total: loadedInfo.newsCmsLoadedCount }), this.newsContentfulService.getNewsByLegalEntityCountryFeature(legalEntityFeature, loadedInfo.newsCmsLoadedCount || 0, pageSize, props.previewMode));
        const news$ = combineLatest([yammerNewsResponse$, cmsNewsResponse$]);

        return news$.pipe(
          map(([yammerNewsResponse, cmsNewsResponse]) => {
            const newsArray: Array<INewsBase> = [].concat(...[yammerNewsResponse.News, cmsNewsResponse.news]);
            const newsSorted = newsArray.sort(NewsHelper.sortNewsByDate).slice(0, pageSize);
            const cmsItemsCount = newsSorted.filter( news => news.Type === NewsTypes.CMS_NEWS).length;

            return TimelineNewsActions.queryMoreNewsSuccess({
              news: newsSorted,
              loadedInfo: {
                newsCmsAllLoaded: cmsNewsResponse.total === loadedInfo.newsCmsLoadedCount + cmsItemsCount,
                newsCmsLoadedCount: loadedInfo.newsCmsLoadedCount + cmsItemsCount,
                newsYammerAllLoaded: yammerNewsResponse.Total === loadedInfo.newsYammerLoadedCount + (newsSorted.length - cmsItemsCount),
                newsYammerLoadedCount: loadedInfo.newsYammerLoadedCount + (newsSorted.length - cmsItemsCount)
              }
            });
          }),
          catchError(() => {
            this.snackBarService.open({
              message: 'Error while retrieving news list',
              type: SnackBarTypes.ErrorAlt
            });
            return of(TimelineNewsActions.queryMoreNewsError());
          })
        );
      })
    );
  });
}
