import { Injectable } from '@angular/core';
import * as fromNotifications from '../../store/reducers/notifications/index';
import { Store, select } from '@ngrx/store';
import * as NotificationsActions from '../../store/actions/notifications.actions';
import { Observable } from 'rxjs';
import { INotificationItem } from '../../interfaces/notification-item';

@Injectable({
  providedIn: 'root'
})
export class NotificationsFacade {
  constructor(private store: Store<fromNotifications.State>) {}

  queryNotifications() {
    this.store.dispatch(NotificationsActions.queryNotifications());
  }

  get notifications$(): Observable<Array<INotificationItem>> {
    return this.store.pipe(select(fromNotifications.selectAllNotifications));
  }

  get totalNotifications$(): Observable<number> {
    return this.store.pipe(select(fromNotifications.selectNotificationsTotal));
  }

  get totalUnseenNotifications$(): Observable<number> {
    return this.store.pipe(
      select(fromNotifications.selectTotalUnseenNotifications)
    );
  }

  get notificationsRequestTimestamp$(): Observable<Date> {
    return this.store.pipe(
      select(fromNotifications.selectNotificationsRequestTimestamp)
    );
  }

  get loadingNotifications$(): Observable<boolean> {
    return this.store.pipe(
      select(fromNotifications.selectLoadingNotifications)
    );
  }

  updateNotificationsTimestamp(timestamp: Date) {
    this.store.dispatch(NotificationsActions.updateNotificationsTimestamp({ timestamp }));
  }

  notificationItemRead(notificationItemId: string) {
    this.store.dispatch(
      NotificationsActions.notificationItemRead({
        notificationItemId
      })
    );
  }

  markAllNotificationsAsRead() {
    this.store.dispatch(NotificationsActions.markAllNotificationsAsRead());
  }
}
