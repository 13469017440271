import { ChangeDetectorRef, Component } from '@angular/core';
// Services

// Classes
import { BaseCvElement } from '../../../../classes/base-cv-element';
import { CvLanguageService } from '../../../../services/cv-language.service';

@Component({
  selector: 'cv-box-primary-cv',
  templateUrl: './cv-box-primary-cv.component.html',
  styleUrls: ['./cv-box-primary-cv.component.scss', '../../../cv/cv-list-view/cv-list/cv-list-element/cv-list-element.component.scss']
})
export class CvBoxPrimaryCvComponent extends BaseCvElement {
  constructor(
    cvLanguageService: CvLanguageService,
    cdr: ChangeDetectorRef
    ) {
    super(cvLanguageService, cdr);
  }
}
