<div #subNavbarContainer>
  <sub-navbar></sub-navbar>
</div>
<div class="news-view flex flex-col flex-start-stretch flex-full"
     [ngClass]="{'with-banner': getBanner()}">
  <layout-container [mode]="LayoutContainerModes.fullWidth">
    <div class="news-view-container flex flex-col flex-full">
      <div class="news-view-header flex flex-start-stretch flex-auto"
           *ngIf="getBanner()"
           [ngStyle]="{'background-image': 'url(' + getBanner() + ')'}">
      </div>
      <div class="news-view-content flex flex-col flex-initial">
        <mat-card class="gradient-box-shadow flex flex-col flex-[0_1_auto]">
          <mat-card-title>
            <span class="pill" color="primary">News</span>
            <ng-container *ngIf="news.Title">
              <h2 class="news-title max-md:hidden">{{news.Title}}</h2>
              <h3 class="news-title md:hidden">{{news.Title}}</h3>
            </ng-container>
          </mat-card-title>
          <mat-card-content>
            <div class="news-description" #newsDescription>
              <div class="news-description-container">
                <div class="news-video"
                     *ngIf="newsSubtype === newsSubtypes.VIDEO_ONLY"
                     [ngClass]="{'iframe-video': news.Attachments[0].Type !== ATTACHMENT_TYPES.OTHER}"
                     [ngStyle]="{height: videoPlayerConfig.containerHeight + 'px'}">
                  <div class="video-plug"
                       (click)="show()"
                       *ngIf="!showVideo"
                       [ngStyle]="{'background-image': 'url(' + news.Attachments[0].ThumbnailUrl + ')',
                                    width: videoPlayerConfig.containerWidth + 'px', height: videoPlayerConfig.containerHeight + 'px'}">
                  </div>
                  <my7n-video-player *ngIf="showVideo && news.Attachments[0].Type !== ATTACHMENT_TYPES.OTHER"
                                     [videoUrl]="news.Attachments[0].Url"
                                     [module]="LogVideoModules.News" 
                                     [title]="news.Title"
                                     [articleId]="news.Id"
                                     [config]="videoPlayerConfig"></my7n-video-player>
                  <video [src]="trustedResourceUrl"
                         *ngIf="showVideo && news.Attachments[0].Type === ATTACHMENT_TYPES.OTHER"
                         [width]="videoPlayerConfig.containerWidth"
                         [height]="videoPlayerConfig.containerHeight"
                         autoplay
                         controls
                         controlsList="nodownload"
                  ></video>
                </div>
                <div *ngIf="news.Description" [innerHTML]="news.Description" class="news-text">
                </div>
                <div class="gallery" *ngIf="newsSubtype === newsSubtypes.GALLERY">
                  <gallery [items]="news.Attachments"
                           [newsId]="news.Id"
                           [limitToOneRow]="false"
                           [itemHeight]="252"
                           [rowItems]="galleryItemsInRowNumber">
                  </gallery>
                </div>
              </div>
            </div>
            <hr/>
            <!-- news footer -->
            <div class="news-view-footer flex flex-row flex-between-center"
                 data-test-id="cms-news-view-container-news-footer">
              <div class="news-footer flex max-sm:flex-col sm:gap-24 max-sm:flex-start-start sm:max-md:flex-between-center md:flex-start-center">
                <!-- news author info -->
                <div class="news-author-info flex flex-start-center md:gap-12">
                  <div class="news-author-info-avatar max-md:hidden">
                    <secure-avatar [avatarUrl]="news.AuthorAvatar" [title]="news.AuthorName"></secure-avatar>
                  </div>
                  <div class="news-author-info-name-posted flex flex-col flex-[1_1_0%]">
                    <span class="news-author-info-name">{{news.AuthorName}}</span>
                    <span class="news-author-info-job-title">{{news.AuthorJobTitle}}</span>
                  </div>
                </div>
              
                <!-- news dates -->
                <div class="news-dates flex flex-start-center gap-12">
                  <mat-icon class="max-md:hidden" svgIcon="calendar_big"></mat-icon>
                  <span class="news-author-info-posted"><span class="max-md:hidden"></span> {{news.Published | defaultDate: 'minute': true}}</span>
                </div>
              </div>
              <reactions [contentId]="news.Id" [contentType]="contentType" data-test-id="reactions"></reactions>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </layout-container>
</div>
