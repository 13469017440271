import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { of, race, timer } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { PwaService } from '../services/pwa.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateAndReloadResolver implements Resolve<any> {
  constructor(private router: Router, private pwaService: PwaService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let updateAvailable = false;

    // first disable reload dialog
    this.pwaService.updateReloadDialogDisabled(true);

    // wait max 4s (usually it takes 0-4s for a new SW to activate)
    const waitUntil$ = timer(5000).pipe(take(1));

    // it emits when SW sends a message about an update to the app
    const newSwActivated$ = this.pwaService.newSwActivated$.pipe(
      filter((isActivated: boolean) => isActivated === true),
      take(1)
    );

    // wait for the update info, but no longer than 5s
    const race$ = race(waitUntil$, newSwActivated$);

    return race$.pipe(
      tap((value: number | boolean) => {
        if (typeof value === 'boolean' && value === true) {
          updateAvailable = true;
          // if the return type is from the newSwActivated to the reload
          window.location.reload();
        }
      }),
      switchMap((value: number | boolean) => {
        // reset the reloadDialogDisabled flag
        this.pwaService.updateReloadDialogDisabled(false);
        // return something
        return of(updateAvailable);
      })
    );
  }
}
