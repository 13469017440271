import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ICV_MAX_LENGTHS } from '../../../../../../interfaces/cv';
// Validators
import { RequiredTrimmedValidator } from '../../../../../../validators/required-trimmed.validator';

@Component({
  selector: 'cv-rename-dialog',
  templateUrl: './cv-rename-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvRenameDialogComponent implements OnInit {
  renameForm: UntypedFormGroup;
  ICV_MAX_LENGTHS = ICV_MAX_LENGTHS;

  constructor(
    public dialogRef: MatDialogRef<CvRenameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.renameForm = new UntypedFormGroup({
      CvName: new UntypedFormControl(this.data.name, [RequiredTrimmedValidator, Validators.maxLength(ICV_MAX_LENGTHS.CvName)])
    });
  }

  submitDialog() {
    if (this.renameForm.valid) {
      this.dialogRef.close({
        changed: true,
        name: this.renameForm.get('CvName').value
      });
    } else {
      console.debug('[CvRenameDialogComponent] Rename form is invalid');
    }
  }

  cancelDialog(): void {
    this.dialogRef.close({
      changed: false
    });
  }
}

