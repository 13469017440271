import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';

import {IGalleryConfig} from '../../../interfaces/gallery-config';
import {ISharepointFile} from '../../../interfaces/sharepoint';

import {GalleryLightboxOverlayRef} from '../gallery/gallery-lightbox/gallery-lightbox-overlay-ref';
import {GalleryLightboxOverlayService} from '../gallery/gallery-lightbox/gallery-lightbox-overlay.service';
import {SharepointHelper} from '../../../utils/helpers/sharepoint.helper';

@Component({
  selector: 'sharepoint-gallery',
  templateUrl: './sharepoint-gallery.component.html',
  styleUrls: ['./sharepoint-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharepointGalleryComponent implements OnInit {

  readonly DEFAULT_CONFIG: IGalleryConfig = {
    rowItems: 3,
    limitToOneRow: false,
    itemHeight: 120
  }

  @Input() items: ISharepointFile[];
  @Input() newsId: string;
  @Input() config: IGalleryConfig = this.DEFAULT_CONFIG;

  @ViewChild('thumbContainer', { static: true }) thumbContainer: ElementRef;

  limitTo: number;

  thumbHeight: number;

  constructor(
    private galleryLightboxOverlayService: GalleryLightboxOverlayService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.setLimitTo();
  }

  ngAfterViewInit() {
    // timeout is necessary because we need to display square and element width is responsive
    setTimeout(() => this.resizeContent(), 0);
    window.addEventListener('resize', () => this.resizeContent());
  }

  showPreview(index) {
    const dialogRef: GalleryLightboxOverlayRef = this.galleryLightboxOverlayService.open(
      {
        galleryData: {
          galleryConfig: this.config,
          attachments: SharepointHelper.convertSharePointItemsToNewsAttachments(this.items),
          index: index
        }
      }
    );
  }

  private setLimitTo() {
    let limit: number;

    if (this.config.limitToOneRow) {
      if (this.items.length > this.config.rowItems) {
        limit = this.config.rowItems;
      } else {
        limit = this.items.length;
      }
    } else {
      limit = Infinity;
    }

    this.limitTo = limit;
  }


  resizeContent() {
    // we want thumb to be a square so if width is changing height is also updated
    // If there are less items than requested in one row the height of each item is always as requested in itemHeight
    this.thumbHeight = this.items.length >= this.config.rowItems ?
      this.thumbContainer.nativeElement.children[0].offsetWidth : this.config.itemHeight;
    this.config.itemHeight = this.thumbHeight;
    this.cdr.markForCheck();
  }

  getThumbHeight(): string {
    return this.config?.limitToOneRow ? this.thumbHeight + 'px' : 'inherit';
  }

  getThumbWidth(): string {
    return this.config?.limitToOneRow ? 'calc(' + 100/this.config.rowItems + '% - 1px)' : 'inherit';
  }
}
