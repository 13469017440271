import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutContainerModes } from '@my7n/ui';
import { INewsResponse } from '../../interfaces/news-response';
import { TimelineFeatures } from '@my7n/features';
import { AuthorizationService } from '../../services/authorization.service';
import { filter, take, delay } from 'rxjs/operators';
import { TimelineNewsFacadeService } from '../../services/facades/timeline-news-facade.service';
import { NEWS_DETAIL_URLS } from '../../interfaces/news';


@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnDestroy {
  LayoutContainerModes = LayoutContainerModes;
  newsList: INewsResponse;
  videoUpdateId: string;

  get timelineCvBoxFeature(): TimelineFeatures {
    return TimelineFeatures.CvBox;
  }

  get timelineEventBoxFeature(): TimelineFeatures {
    return TimelineFeatures.EventBox;
  }

  get timelineRequestTransferFeature(): TimelineFeatures {
    return TimelineFeatures.RequestTransfer;
  }

  get timelineAsideFeatures (): string {
    // we have to create a string with features separated with comma
    return [
      TimelineFeatures.CvBox,
      TimelineFeatures.EventBox,
      TimelineFeatures.RequestTransfer,
      TimelineFeatures.VideoUpdateBox
    ].join(',');
  }

  get timelineNewsFeature(): TimelineFeatures {
    return TimelineFeatures.Default;
  }

  get timelineVideoUpdateBoxFeature(): TimelineFeatures {
    return TimelineFeatures.VideoUpdateBox;
  }

  prieviewMode$ = this.authService
    .can(TimelineFeatures.VideoUpdatePreviewBox)
    .pipe(
      filter(
        () =>
          this.activatedRoute.snapshot.queryParamMap.get('videoUpdatePreview') === 'true'
      )
    );

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthorizationService,
    private timelineNewsFacade: TimelineNewsFacadeService
  ) {}

  ngOnInit() {
    this.newsList = this.activatedRoute.snapshot.data.newsList;
    this.videoUpdateId = this.activatedRoute.snapshot.queryParamMap.get('videoUpdateId');

    // delay 10 because we want images on list to resize before
    this.timelineNewsFacade.scrollY$.pipe(take(1), delay(10)).subscribe(scrollY => {
      if (scrollY) {
        window.scrollTo(0, scrollY);
      }
    });
  }

  ngOnDestroy() {
    const gointToNewsView = NEWS_DETAIL_URLS.some((url) => this.router.url.startsWith(url));
    if (gointToNewsView) {
      this.timelineNewsFacade.saveScrollYPosition(window.scrollY);
    } else {
      this.timelineNewsFacade.clearNews();
    }
  }
}
