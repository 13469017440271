import { Entry, EntryCollection } from 'contentful';
import { ICmsVideoUpdateItem } from '../../interfaces/cms';

export class VideoUpdateHelper {
    static getVideoUpdateHandler(videoEntries: EntryCollection<ICmsVideoUpdateItem>): ICmsVideoUpdateItem {
        const results: Array<ICmsVideoUpdateItem> = videoEntries.items.map((entry: Entry<ICmsVideoUpdateItem>) => {
            return {
                id: entry.sys.id,
                title: entry.fields.title,
                videoDate: new Date(entry.fields?.videoDate),
                countries: entry.fields.countries,
                videoUrl: entry.fields.videoUrl
            };
        });

        return results[0];
    }
}
