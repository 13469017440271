import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoDataComponent {
  @Input() icon = 'error_outline';
  @Input() message = 'There was a problem with loading data.'
}

// TODO: move to @my7n/ui