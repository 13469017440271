import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ProfileStrengthKeys} from '../../interfaces/profile-strength';
import {CvCommonService} from './cv-common.service';

@Injectable()
export class CvEducationService {
  constructor(private http: HttpClient,
              private cvCommonService: CvCommonService) {
  }

  updateEducation = this.cvCommonService.commonPutPostUpdate(ProfileStrengthKeys.Education, '/education/');
  removeEducation = this.cvCommonService.commonRemove('/education/');
}
