import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject } from 'rxjs';

export class NgSelectDecorator {

  static readonly BACKSPACE_KEYCODE = 8;

  lastItemSelectedForDeletion: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  selectEl = null;

  constructor(selectEl: NgSelectComponent) {
    this.selectEl = selectEl;
  }

  private backspaceListener(event) {
      if (event.keyCode === NgSelectDecorator.BACKSPACE_KEYCODE && !this.selectEl.searchTerm) {
        if (this.lastItemSelectedForDeletion.getValue()) {
          this.selectEl.unselect(this.selectEl.itemsList.lastSelectedItem);
          this.lastItemSelectedForDeletion.next(false);
        } else {
          this.lastItemSelectedForDeletion.next(true);
        }
      } else {
        this.lastItemSelectedForDeletion.next(false);
      }
  }

  public addBackspaceListener(): EventListener {
    const nativeElement = this.selectEl.searchInput.nativeElement;
    const backspaceListener = (e) => this.backspaceListener(e);

    nativeElement.addEventListener('keydown', backspaceListener);

    return backspaceListener;
  }

}
