import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CmsContentTypes,
  CmsDictionaryTypes,
  ICmsDictionaryItem
} from '../interfaces/cms';

import { ContentfulGetterService } from './contentful-getter.service';
import { EntryCollection } from 'contentful';
import { Options } from '@contentful/rich-text-html-renderer';

@Injectable({
  providedIn: 'root'
})
export class DictionariesContentfulService {
  constructor(private contentfulGetterService: ContentfulGetterService) {}

  getDictionaryItems$(
    types: Array<CmsDictionaryTypes>
  ): Observable<Array<ICmsDictionaryItem>> {
    const query = {
      content_type: CmsContentTypes.Dictionary,
      'fields.type[in]': types,
      order: 'fields.title'
    };

    return this.contentfulGetterService.getEntries<
      ICmsDictionaryItem,
      Array<ICmsDictionaryItem>
    >(false, CmsContentTypes.Dictionary, this.getDictionaryHandler, query);
  }

  private getDictionaryHandler(
    dictionaryEntries: EntryCollection<ICmsDictionaryItem>,
    sectionRendererOptions: Partial<Options>
  ): Array<ICmsDictionaryItem> {
    const results: Array<ICmsDictionaryItem> = [];

    dictionaryEntries.items.forEach((entry) => {
      const item: ICmsDictionaryItem = {
        id: entry.sys.id,
        value: entry.fields.value,
        type: entry.fields.type,
        title: entry.fields.title,
        legalEntityCountries: (entry.fields as any).countries
      };

      results.push(item);
    });

    return results;
  }
}
