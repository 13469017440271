<div class="flex flex-col">
  <h4 class="font-bold">My primary CV</h4>
  <cv-box-primary-cv [cv]="cv" *ngIf="cv"></cv-box-primary-cv>
  <mat-card class="flex flex-col"*ngIf="!cv">
    <mat-card-title>
      <h5 class="font-bold">
        You don't have any CV
      </h5>
    </mat-card-title>
    <mat-card-content>
      <p>To help your agent find best offers for you,<br />tell us about your professional experience.</p>
      <button mat-flat-button 
              type="button" 
              color="accent" 
              class="small-button" 
              routerLink="/cv/wizard"
              data-test-id="cv-box-create-cv-btn">
        Create your CV
      </button>
    </mat-card-content>
  </mat-card>
</div>
