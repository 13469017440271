import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaQueryService } from '@my7n/ui';

export interface ITestimonial {
  ImageUrl: string;
  Text: string;
  Author: {
    Name: string;
    Position: string
  };
  Link?: {
    Link: string;
    LinkText: string;
  };
}

@Component({
  selector: 'testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestimonialComponent {
  @Input() imageLeft = true;
  @Input() testimonial: ITestimonial;

  constructor( public mediaQueryService: MediaQueryService ) { }
}
