// MODULES
import { NgModule } from '@angular/core';
import { My7nCommonModule } from './my7n-common.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { My7nMaterialModule } from './my7n-material.module';

// COMPONENTS
import { UserProfileComponent } from '../components/user-profile/user-profile.component';
import { EditNotificationFrequencyComponent } from '../components/user-profile/edit-notification-frequency/edit-notification-frequency.component';
import { NotificationFrequencyComponent } from '../components/user-profile/notification-frequency/notification-frequency.component';
import { PersonalDetailsComponent } from '../components/user-profile/personal-details/personal-details.component';
import { BusinessDetailsComponent } from '../components/user-profile/business-details/business-details.component';
import { NotificationSettingsComponent } from '../components/user-profile/notification-settings/notification-settings.component';
import { PreferredWorkLocationsComponent } from '../components/user-profile/preferred-work-locations/preferred-work-locations.component';
import { TermsAndConditionsComponent } from '../components/user-profile/terms-and-conditions/terms-and-conditions.component';

// RESOLVERS
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserProfileEffects } from '../store/effects/user-profile.effects';

// NGRX
import * as fromUserProfile from '../store/reducers/user-profile/index';

@NgModule({
  imports: [
    CommonModule,
    My7nCommonModule,
    RouterModule,
    My7nMaterialModule,
    StoreModule.forFeature(
      fromUserProfile.userProfileFeatureKey,
      fromUserProfile.reducers
    ),
    EffectsModule.forFeature([UserProfileEffects])
  ],
  exports: [
    UserProfileComponent,
    NotificationFrequencyComponent,
    EditNotificationFrequencyComponent
  ],
  declarations: [
    UserProfileComponent,
    NotificationFrequencyComponent,
    EditNotificationFrequencyComponent,
    PersonalDetailsComponent,
    BusinessDetailsComponent,
    PreferredWorkLocationsComponent,
    TermsAndConditionsComponent,
    NotificationSettingsComponent
  ]
})
export class My7nUserProfileModule {}
