<div class="files-upload-container flex flex-col">
    <!-- loader element is always invisible -->
    <input #fileUploadButton
           class="file-input"
           data-test-id="file-upload-pdf-input"
           type="file" />
    <div *ngIf="!file && !converting"
         class="flex flex-col flex-center-start">
      <button mat-button
              type="button"
              color="primary"
              class="add-file-btn"
              data-test-id="file-upload-addFileBtn"
              (click)="fileUploadButton.click()">
        Add file
      </button>
    </div>
    <div *ngIf="converting" class="loading-dots">Converting</div>
    <file-upload-pdf-element *ngIf="file"
                          [file]="file"
                          [fileArrayBuffer]="fileArrayBuffer"
                          [blobUrl]="blobUrl"
                          [converting]="converting"
                          (removeFile)="reset()"></file-upload-pdf-element>
</div>
