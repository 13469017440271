import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromTimeline from '../../store/reducers/timeline/index';
import * as fromTimelineNews from '../../store/reducers/timeline/news';
import * as TimelineNewsActions from '../../store/actions/timeline-news.actions';
import { INewsBase } from '../../interfaces/news';
import { INewsLoadedInfo } from '../../store/reducers/timeline/news/timeline-news.reducer';

@Injectable({
  providedIn: 'root'
})
export class TimelineNewsFacadeService {
  constructor(private store: Store<fromTimeline.TimelineModuleState>) {}

  queryNews(previewMode: boolean) {
    this.store.dispatch(TimelineNewsActions.queryNews({ previewMode }));
  }

  queryMoreNews(previewMode: boolean) {
    this.store.dispatch(TimelineNewsActions.queryMoreNews({ previewMode }));
  }

  get news$(): Observable<Array<INewsBase>> {
    return this.store.pipe(select(fromTimelineNews.selectAllNews));
  }

  get loading$(): Observable<boolean> {
    return this.store.pipe(select(fromTimelineNews.selectLoading));
  }

  get loadingMore$(): Observable<boolean> {
    return this.store.pipe(select(fromTimelineNews.selectLoadingMore));
  }

  get loadingError$(): Observable<boolean> {
    return this.store.pipe(select(fromTimelineNews.selectLoadingError));
  }

  get loadingMoreError$(): Observable<boolean> {
    return this.store.pipe(select(fromTimelineNews.selectLoadingMoreError));
  }

  get scrollY$(): Observable<number> {
    return this.store.pipe(select(fromTimelineNews.selectScrollY));
  }

  saveScrollYPosition (scrollY: number) {
    this.store.dispatch(TimelineNewsActions.saveScrollYPosition({ scrollY }));
  }

  get loadedInfo$(): Observable<INewsLoadedInfo> {
    return this.store.pipe(select(fromTimelineNews.selectLoadedInfo));
  }

  get initialized$(): Observable<boolean> {
    return this.store.pipe(select(fromTimelineNews.selectInitialized));
  }

  clearNews() {
    return this.store.dispatch(TimelineNewsActions.clearNews());
  }
}
