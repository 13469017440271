import { from, forkJoin, Observable, Subject, combineLatest } from 'rxjs';
import { FileUtils } from '../utils/file-utils';
import { jsPDF } from 'jspdf';
import * as EXIF from 'exifr';
import { map, mergeMap } from 'rxjs/operators';
import { EXIF_ROTATION_TYPES, IExifrRotation } from '../interfaces/exif';

// IMPORTANT INFORMATIONS!!!!
// Positions used to always center image horizontally and vertically (if there is space)
// jsPDF can only rotate counterclockwise so if you want to rotate image 90 degrees clockwise you need to rotate by 270 degress in jsPDF
// Center point of rotation is bottom left corner - NOT center of image - thats why we need to calculate positions based on image width and height (see get[rotation]RotationSettings methods)
// Images are displayed always in portrait mode thats why images whose width is bigger than height are rotated clockwise 90 degrees and for checking conditions imageAspectRatio flag was created

export interface ImageToPdfAddImageConfig {
  positionX: number;
  positionY: number;
  imageWidth: number;
  imageHeight: number;
  rotation: number;
}

export interface IGeneratedPdfFiles {
  arrayBuffer: ArrayBuffer;
  file: File;
}

export class ImageToPdfConverter {
  private _pdfArrayBuffer: ArrayBuffer;
  private _pdfFile: File;

  doc: jsPDF;

  imageWidth: number;
  imageHeight: number;

  imageInLandscape = false;

  docWidth: number;
  docHeight: number;

  imageAspectRatio: number;

  canvas = document.createElement('canvas');
  ctx = this.canvas.getContext('2d');

  get pdfArrayBuffer(): ArrayBuffer {
    if (this._pdfArrayBuffer) {
      return this._pdfArrayBuffer;
    }

    console.error('[ImageToPDFConverter] First run generatePdfMethod');
  }

  get pdfFile(): File {
    if (this._pdfFile) {
      return this._pdfFile;
    }

    console.error('[ImageToPDFConverter] First run generatePdfMethod');
  }

  constructor() {}

  generatePdf(imageFile: File): Observable<IGeneratedPdfFiles> {
    this.doc = this.createJsPdfObject();

    this.docWidth = this.doc.internal.pageSize.width;
    this.docHeight = this.doc.internal.pageSize.height;

    const imageFilePropertiesArray$ = forkJoin([
      FileUtils.convertFileToDataUrl(imageFile),
      this.getRotation$(imageFile)
    ]);

    const ImageWithCorrectRotation$ = imageFilePropertiesArray$.pipe(
      mergeMap((imageFileProperties) =>
        this.getImageWithFixedRotation$(
          imageFileProperties[0],
          imageFileProperties[1]
        )
      )
    );

    return combineLatest([
      imageFilePropertiesArray$,
      ImageWithCorrectRotation$
    ]).pipe(
      map(([imageFilePropertiesArray, imageWithCorrectRotation]) => {
        const rotation = imageFilePropertiesArray[1];
        this.imageWidth = imageWithCorrectRotation.width;
        this.imageHeight = imageWithCorrectRotation.height;

        if (this.imageWidth === 0 || this.imageHeight === 0) {
          console.error(
            '[ImageToPdfConverter] Width or height of image equal 0'
          );
          return {
            arrayBuffer: null,
            file: null
          };
        } else {
          this.imageAspectRatio = this.imageWidth / this.imageHeight;
        }

        this.imageInLandscape = this.imageAspectRatio > 1;

        this.addImageWithSettings(
          imageWithCorrectRotation.imageDataUrl,
          imageFile,
          this.getNoRotationSettings()
        );

        // ArrayBuffer needed to preview PDFs

        this._pdfArrayBuffer = this.doc.output('arraybuffer');

        this._pdfFile = new File(
          [this.doc.output('blob')],
          `${imageFile.name}.pdf`,
          { type: 'application/pdf' }
        );

        return {
          arrayBuffer: this._pdfArrayBuffer,
          file: this._pdfFile
        };
      })
    );
  }

  addImageWithSettings(
    imageDataUrl: string,
    file: File,
    settings: ImageToPdfAddImageConfig
  ) {
    this.doc.addImage(
      imageDataUrl,
      'JPEG',
      settings.positionX,
      settings.positionY,
      settings.imageWidth,
      settings.imageHeight,
      file.name,
      // Compression of the image added to pdf. We wan't to pdf be as small as possible. Was tested with warious files and it looks good. Conversion times are also ok.
      'SLOW',
      settings.rotation
    );
  }

  getNoRotationSettings(): ImageToPdfAddImageConfig {
    if (!this.imageInLandscape) {
      this.setPortraitImageDimensionsToCoverPage();

      return {
        positionX: Math.abs(this.imageWidth - this.docWidth) / 2,
        positionY: Math.abs(this.imageHeight - this.docHeight) / 2,
        imageWidth: this.imageWidth,
        imageHeight: this.imageHeight,
        rotation: 0
      };
    } else {
      this.setLandscapeImageDimensionsToCoverPage();

      return {
        positionX: Math.abs(this.imageHeight - this.docWidth) / 2,
        positionY:
          -this.imageHeight + Math.abs(this.imageWidth - this.docHeight) / 2,
        imageWidth: this.imageWidth,
        imageHeight: this.imageHeight,
        rotation: 270
      };
    }
  }

  setPortraitImageDimensionsToCoverPage() {
    this.imageWidth = this.docWidth;
    this.imageHeight = this.imageWidth / this.imageAspectRatio;

    if (this.imageHeight > this.docHeight) {
      this.imageHeight = this.docHeight;
      this.imageWidth = this.imageHeight * this.imageAspectRatio;
    }
  }

  setLandscapeImageDimensionsToCoverPage() {
    this.imageWidth = this.docHeight;
    this.imageHeight = this.imageWidth / this.imageAspectRatio;

    if (this.imageHeight > this.docWidth) {
      this.imageHeight = this.docWidth;
      this.imageWidth = this.imageHeight * this.imageAspectRatio;
    }
  }

  getOrientation$(file: File): Observable<number> {
    return from(EXIF.orientation(file));
  }

  getRotation$(file: File): Observable<IExifrRotation> {
    return from(EXIF.rotation(file));
  }

  getImageWithFixedRotation$(
    base64string: string,
    rotation: IExifrRotation
  ): Observable<{ width: number; height: number; imageDataUrl: string }> {
    const image = document.createElement('img');
    const imageWithFixedRotation$ = new Subject<{
      width: number;
      height: number;
      imageDataUrl: string;
    }>();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    image.addEventListener('load', () => {
      let width = image.width;
      let height = image.height;
      let dataUrl: string;

      if (rotation.canvas && rotation.dimensionSwapped) {
        width = image.height;
        height = image.width;
      }

      this.canvas.height = height;
      this.canvas.width = width;
      this.ctx.save();

      if (rotation.canvas) {
        this.ctx.translate(width / 2, height / 2);
        this.ctx.rotate(rotation.rad);
        this.ctx.scale(rotation.scaleX, rotation.scaleY);
        this.ctx.drawImage(
          image,
          -image.width / 2,
          -image.height / 2,
          image.width,
          image.height
        );
      } else {
        this.ctx.drawImage(image, 0, 0);
      }
      this.ctx.restore();

      dataUrl = this.canvas.toDataURL('image/jpeg', 0.92);

      imageWithFixedRotation$.next({
        width,
        height,
        imageDataUrl: dataUrl
      });
    });

    image.src = base64string;

    return imageWithFixedRotation$;
  }

  createJsPdfObject() {
    return new jsPDF({ unit: 'px', compress: true, format: 'a4' });
  }
}
