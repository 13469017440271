import { UntypedFormControl, ValidationErrors } from '@angular/forms';
// Interfaces
import { DEFAULT_VALID_FILE_EXT } from '../interfaces/file-upload.interface';

export const FilesLimitValidator = (limit: number) => {
  return function (control: UntypedFormControl): ValidationErrors | null {
    const files: File[] = control.value;
    if (files && files.length > limit) {
      return {
        filesCountLimit: true
      };
    }

    return null;
  };
};

export const FilesSizeLimitValidator = (limit: number) => {
  // limit value in MB
  return function (control: UntypedFormControl): ValidationErrors | null {
    const files: File[] = control.value;

    if (files && files.length > 0) {
      const tooBigFilesIndexes = [];
      for (let i = 0; i < files.length; i++) {
        const fileSizeMB = (files[i].size / (1024 * 1024)).toFixed(2);
        if (+fileSizeMB > limit) {
          tooBigFilesIndexes.push(i);
        }
      }

      return tooBigFilesIndexes.length
        ? { filesSizeLimit: tooBigFilesIndexes }
        : null;
    }

    return null;
  };
};

export const FileSizeLimitValidator = (limit: number) => {
  // limit value in MB
  return function (control: UntypedFormControl): ValidationErrors | null {
    const file: File = control.value;

    if (file && file.size) {
      const fileSizeMB = (file?.size / (1024 * 1024)).toFixed(2);

      if ( +fileSizeMB > limit) {
        return { fileSizeLimit: true };
      }

      return null;
    }

    return null;
  };
};

export const FilesExtensionValidator = (
  validFileExtensions: string[] = DEFAULT_VALID_FILE_EXT
) => {
  return function (control: UntypedFormControl): ValidationErrors | null {
    const files: File[] = control.value;

    if (files && files.length > 0) {
      const wrongExtensionFilesIndexes = [];
      for (let i = 0; i < files.length; i++) {
        const fileExt = files[i].name.toUpperCase().split('.').pop();
        if (!validFileExtensions.includes(fileExt)) {
          wrongExtensionFilesIndexes.push(i);
        }
      }

      return wrongExtensionFilesIndexes.length
        ? { filesExtension: wrongExtensionFilesIndexes }
        : null;
    }

    return null;
  };
};

export const FileExtensionValidator = (
  validFileExtensions: string[] = DEFAULT_VALID_FILE_EXT
) => {
  return function (control: UntypedFormControl): ValidationErrors | null {
    const file: File = control.value;

    if (file && file.name) {
      const fileExt = file.name.toUpperCase().split('.').pop();

      if (!validFileExtensions.includes(fileExt)) {
        return { fileExtension: true };
      }

      return null;
    }

    return null;
  };
};

export const FilesDuplicatesValidator = (
  control: UntypedFormControl
): ValidationErrors | null => {
  const files: File[] = control.value;

  if (files && files.length > 0) {
    const occuranceObj: { [key: string]: number[] } = {};
    let filesDuplicatesIndexes: number[] = [];

    files.forEach((element, index) => {
      if (!occuranceObj[element.name]) {
        occuranceObj[element.name] = [];
      }

      // Checking for sizes in previously occured files with the same name
      occuranceObj[element.name].forEach((prevOccurenceIndex) => {
        if (files[prevOccurenceIndex].size === files[index].size) {
          filesDuplicatesIndexes.push(prevOccurenceIndex, index);
        }
      });

      // Push own index at the end (not checking for own index)
      occuranceObj[element.name].push(index);
    });

    // remove duplicated indexes from duplicates
    filesDuplicatesIndexes = [...new Set(filesDuplicatesIndexes)];
    return filesDuplicatesIndexes.length
      ? { filesDuplicates: filesDuplicatesIndexes }
      : null;
  }

  return null;
};
