import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app-config';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class CvWizardService {

  readonly API_CV: string;
  readonly API_CV_CREATE: string;

  constructor(private http: HttpClient,
              private appConfig: AppConfig) {

    const apiPrefix = this.appConfig.serviceUrl(ServiceNames.Core);
    const apiCvCreatePrefix = this.appConfig.serviceUrl(ServiceNames.Cv, 'v1');

    this.API_CV = apiPrefix + 'cvs/';
    this.API_CV_CREATE = apiCvCreatePrefix + 'cv/create';
  }

  getWizardData(cvId: number): Promise<any> {
    return lastValueFrom(this.http.get(this.API_CV + cvId)).then((result) => {
      return result;
    }).catch((error) => {
      console.error('[CvWizardService] Failed to getWizardData with Id ' + cvId, error);
      return Promise.reject(error);
    });
  }

  sendWizardData(dataToSend: any) {
    return lastValueFrom(this.http.post(this.API_CV_CREATE, dataToSend)).then((result) => {
      return result;
    }).catch((error) => {
      console.error('[CvWizardService] Failed to send wizard data', error);
      return Promise.reject(error);
    });
  }

}
