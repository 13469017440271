<div *ngIf="Agent && CurrentUser" class="fab-agent" [ngClass]="{'active': active}" (click)="toggleMessageView($event)" role="button">
  <secure-avatar class="fab-agent-portrait" [avatarUrl]="Agent.PortraitUrl" [title]="Agent.FirstName + ' ' + Agent.LastName"></secure-avatar>

  <div class="fab-agent-details">
    <span class="fab-agent-fullname headline">{{Agent.FirstName}} {{Agent.LastName}}</span>
    <span class="fab-agent-subtitle caption-primary">Your Agent</span>
  </div>
  <div #fabAgentMessageContainer [@msgContainerVisibility]="msgContainerVisibilityState" (@msgContainerVisibility.done)="onAnimationDone($event)" class="fab-agent-message-container" [ngSwitch]="messageType">
    <div class="fab-agent-message update-info" *ngSwitchCase="0">
      <h3 class="fab-agent-message-title subheading">Hello {{CurrentUser.FirstName}}!</h3>
      <div class="fab-agent-message-content">
        please don't forget to update your contact data. It allows us contact you easily in case of interesting job opportunity.
      </div>
      <hr/>
      <div class="fab-agent-message-actions">
        <button type="button" class="btn btn-link" (click)="remindLater()">Remind me later</button>
        <button type="button" (click)="updateContactData()" class="btn btn-primary">Let's do it!</button>
      </div>
      <button class="fab-agent-message-close icon-btn icon-remove" (click)="remindLater()"></button>
    </div>
    <div class="fab-agent-message provide-help" *ngSwitchCase="1">
      <h3 class="fab-agent-message-title subheading">Hello {{CurrentUser.FirstName}}!</h3>
      <div class="fab-agent-message-content">How can I help you? If you have any questions don't hesitate to contact me.</div>
      <div class="fab-agent-message-contact">
        <span *ngIf="Agent.Phone">Tel: <a href="tel:{{Agent.Phone}}">{{Agent.Phone}}</a></span>
        <span *ngIf="Agent.Email">Email: <a href="mailto:{{Agent.Email}}">{{Agent.Email}}</a></span>
      </div>
      <button class="fab-agent-message-close icon-btn icon-remove" (click)="remindLater()"></button>
    </div>
    <div class="fab-agent-message-arrow"></div>
  </div>
</div>
