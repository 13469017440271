import {
  Component,
  OnInit, Input,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
// Services
import { UserProfileService } from '../../../services/user-profile.service';
import { AddressEditService } from '../../../services/address-edit.service';
import { MediaQueryService } from '@my7n/ui';
// Interfaces
import { ICountry } from '../../../interfaces/country';
import { IAddress } from '../../../interfaces/address';
import { COMMON_BUTTONS_TEXTS } from '../../../interfaces/common-texts';

@Component({
  selector: 'address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressEditComponent implements OnInit, OnDestroy {
  addressEditForm: UntypedFormGroup;

  @Input() consultantId: string;
  @Input() address: IAddress;
  @Input() updateMethodName: string;
  @Input() readonly = false;
  @Input() sidePanel = false;
  @Input() canEdit = true;

  @Output() serverError = new EventEmitter<boolean>();
  @Output() updateSuccess = new EventEmitter<IAddress>();

  COMMON_BUTTONS_TEXTS = COMMON_BUTTONS_TEXTS;

  editMode = false;
  error = false;
  loading = true;
  isEmpty = true;

  countryList: ICountry[];
  _copiedAddress: IAddress;

  private valueChangeSubscription: Subscription;

  constructor(
    public mediaQueryService: MediaQueryService,
    private userProfileService: UserProfileService,
    private addressEditService: AddressEditService,
    private fb: UntypedFormBuilder,
    private changeDetRef: ChangeDetectorRef
    ) {
    this.userProfileService.getAllCountries().pipe(
      take(1)
    ).subscribe((result) => {
      this.countryList = result;
      this.loading = false;
    }, _ => {
      this.error = true;
      this.loading = true;
    });
  }

  ngOnInit() {
    this.addressEditForm = this.fb.group({
      addressLine1: this.fb.control(this.address.AddressLine1),
      addressLine2: this.fb.control(this.address.AddressLine2),
      city: this.fb.control(this.address.City),
      country: this.fb.control({Id: this.address.CountryId, CrmId: this.address.CountryId, Name: this.address.CountryName}),
      postalCode: this.fb.control(this.address.PostalCode)
    });

    this.isEmpty = !this.address.AddressLine1 && !this.address.AddressLine2 && !this.address.City && !this.address.CountryName && !this.address.PostalCode;

    this.valueChangeSubscription = this.addressEditForm.valueChanges.subscribe((value) => {
      this.isEmpty = !value.addressLine1 && !value.addressLine2 && !value.city && !value.country && !value.postalCode;
    });
  }

  activate() {
    if (!this.editMode && !this.readonly) {
      this.editMode = true;
      this.error = false;
      this._copiedAddress = {
        AddressLine1: this.addressEditForm.value.addressLine1,
        AddressLine2: this.addressEditForm.value.addressLine2,
        City: this.addressEditForm.value.city,
        Country: this.addressEditForm.value.country,
        CountryId: this.addressEditForm.value.country?.CrmId,
        CountryName: this.addressEditForm.value.country?.Name,
        PostalCode: this.addressEditForm.value.postalCode
      };
    }
  }

  keyListener($event) {
    switch ($event.which) {
      // Bind ESC key
      case 27:
        this.reset();
        break;
    }
  }

  reset() {
    this.addressEditForm.reset(
      {
        addressLine1: this._copiedAddress.AddressLine1,
        addressLine2: this._copiedAddress.AddressLine2,
        city: this._copiedAddress.City,
        country: this._copiedAddress.Country,
        postalCode: this._copiedAddress.PostalCode
      }

    );

    this.address = {...this._copiedAddress};

    this.editMode = false;
    this.loading = false;
  }

  update() {
    if (this.loading || this.readonly) {
      return false;
    }

    if (!this.canEdit) {
      console.debug('[AddressEditComponent] User doesn\'t have permission to edit');
      return false;
    }

    if (this.addressEditForm.invalid) {
      console.warn('[AddressEditComponent] Form is invalid');
      return false;
    }

    this.error = false;
    this.loading = true;

    this.addressEditForm.controls.addressLine1.setValue(this.getTrimmed(this.addressEditForm.controls.addressLine1.value));
    this.addressEditForm.controls.addressLine2.setValue(this.getTrimmed(this.addressEditForm.controls.addressLine2.value));
    this.addressEditForm.controls.city.setValue(this.getTrimmed(this.addressEditForm.controls.city.value));
    this.addressEditForm.controls.postalCode.setValue(this.getTrimmed(this.addressEditForm.controls.postalCode.value));

    const address: IAddress = {
      AddressLine1: this.addressEditForm.controls.addressLine1.value,
      AddressLine2: this.addressEditForm.controls.addressLine2.value,
      City: this.addressEditForm.controls.city.value,
      CountryId: this.addressEditForm.controls.country.value.CrmId,
      PostalCode: this.addressEditForm.controls.postalCode.value
    };

    this.addressEditService.addressEditSave(address, this.updateMethodName, this.consultantId).then(() => {
      this.error = false;
      this.editMode = false;
      this.loading = false;
      this.address = { ...address, CountryName: this.addressEditForm.controls.country.value.Name };
      this.updateSuccess.emit(this.address);
    }, () => {
      this.reset();
      this.error = true;
      this.serverError.emit(true);
    }).finally(() => {
      this.changeDetRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }

  private getTrimmed(value: string): string | null {
    return value ? value.trim() : null;
  }

}
