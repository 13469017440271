import { NgModule } from '@angular/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatListModule } from '@angular/material/list';
import { MatDialogService } from '@my7n/ui';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { PortalModule } from '@angular/cdk/portal';
import { A11yModule } from '@angular/cdk/a11y';
import {
  DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionModule
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import {
  MomentDateAdapter, MomentDateModule
} from '@angular/material-moment-adapter';

import { CustomPaginatorIntl } from '../classes/custom-paginator-intl';
import { OverlayModule, ScrollStrategyOptions } from '@angular/cdk/overlay';

export const DATETIME_MOMENT_FORMATS = {
  parse: {
    dateInput: ['DD.MM.YYYY', 'DD/MM/YYYY']
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export const MONTHYEAR_MOMENT_FORMATS = {
  parse: {
    dateInput: ['MMM YYYY', 'MM/YYYY', 'MM.YYYY']
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};



@NgModule({
  imports: [
    MatAutocompleteModule,
    MatSelectModule,
    MatOptionModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatChipsModule,
    MatDatepickerModule,
    MomentDateModule,
    PortalModule,
    A11yModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatTabsModule,
    MatCardModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    ScrollingModule,
    MatExpansionModule,
    MatListModule,
    MatBottomSheetModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatTableModule,
    OverlayModule
  ],
  exports: [
    MatAutocompleteModule,
    MatSelectModule,
    MatOptionModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatChipsModule,
    MatDatepickerModule,
    MomentDateModule,
    PortalModule,
    A11yModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatTabsModule,
    MatCardModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    ScrollingModule,
    MatExpansionModule,
    MatListModule,
    MatBottomSheetModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatTableModule,
    OverlayModule
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: DATETIME_MOMENT_FORMATS },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' }
    },
    MatDialogService,
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: (scrollStrategyOptions: ScrollStrategyOptions) => scrollStrategyOptions.close,
      deps: [ScrollStrategyOptions]
    }
  ]
})
export class My7nMaterialModule {}
