import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilChanged, filter, Subscription, take } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
// Services
import { CvParseService } from '../../../../services/cv/cv-parse.service';
import { MatDialogService, SnackBarService, SnackBarTypes } from '@my7n/ui';
// Validators
import { PDF_VALID_FILE_EXT } from '../../../../interfaces/file-upload.interface';
import { FileExtensionValidator, FileSizeLimitValidator } from '../../../../validators/files-upload.validator';
import { CvParseStateService, PARSE_STATE } from '../cv-parse-state.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

export interface ICvParseDialogData {
  userId?: string;
  cvId?: number;
}

@Component({
  selector: 'cv-parse-dialog',
  templateUrl: './cv-parse-dialog.component.html',
  styleUrls: ['./cv-parse-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvParseDialogComponent implements OnInit, OnDestroy {
  CV_VALID_FILE_EXT = [PDF_VALID_FILE_EXT];
  CV_FILE_MAX_SIZE = 5;

  cvParseForm: FormGroup<{
    cvToParse: FormControl<File | null>;
  }>;

  protected subscriptions$ = new Subscription();

  get cvToParseControl(): AbstractControl<File, File> {
    return this.cvParseForm.get('cvToParse');
  }

  constructor(
    public dialogRef: MatDialogRef<CvParseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICvParseDialogData,
    private cvParseService: CvParseService,
    private snackBarService: SnackBarService,
    public dialog: MatDialogService,
    private cdr: ChangeDetectorRef,
    private cvParseStateService: CvParseStateService
  ) { }

  ngOnInit() {
    this.setupCvParseForm();
  }

  private setupCvParseForm() {
    this.cvParseForm = new FormGroup({
      'cvToParse': new FormControl<File | null>(null, [
        FileSizeLimitValidator(this.CV_FILE_MAX_SIZE),
        FileExtensionValidator(this.CV_VALID_FILE_EXT)
      ])
    });

    this.registerCvUploadHandler();
  }

  private registerCvUploadHandler() {
    if((this.data.userId && this.data.cvId) || (!this.data.userId && !this.data.cvId)) {
      console.error('[CvParseDialogComponent] Please provide userId or cvId.');

      return;
    }

    this.subscriptions$.add(this.cvToParseControl.valueChanges.pipe(
      distinctUntilChanged(),
      filter(() => this.cvToParseControl.valid), // only proceed when we have valid files uploaded
    ).subscribe((uploadedFile) => {
      this.cvParseStateService.setParseState(PARSE_STATE.UPLOADING);
      this.snackBarService.openPending({
        message: 'Your CV is being uploaded...',
        type: SnackBarTypes.Notification
      });
      this.closeDialog();

      if(this.data.userId) {
        this.handleCreateCvFromFile(uploadedFile);
      }
      else if(this.data.cvId) {
        this.handleParseCvFile(uploadedFile);
      }
    }));
  }

  private handleCreateCvFromFile(file: File) {
    this.cvParseService.createCvFromFile(this.data.userId, file)
        .pipe(take(1))
        .subscribe({
          next: (response: HttpResponse<number>) => {
            this.cvParseStateService.setParseState(PARSE_STATE.PARSING);
            this.snackBarService.openPending({
              message: 'Your CV is being parsed... <br/> Please be patient. It may take a while.',
              type: SnackBarTypes.Notification
            });
            this.cvParseStateService.subscribeForResult(response.body);
          },
          error: (e: HttpErrorResponse) => {
            console.error('An error occurred', e);

            if(e.status === 409) {
              this.snackBarService.open({
                message: 'Your other cv is being parsed.',
                type: SnackBarTypes.ErrorAlt
              });
            } else {
              this.snackBarService.open({
                message: 'Error while parsing cv.',
                type: SnackBarTypes.ErrorAlt
              });
            }

            this.cvParseStateService.setParseState(PARSE_STATE.READY_TO_PARSE);
          },
          complete: () => {
            this.cdr.detectChanges();
          }
    });
  }

  private handleParseCvFile(file: File) {
    this.cvParseService.parseCvFile(this.data.cvId, file)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.cvParseStateService.setParseState(PARSE_STATE.PARSING);
            this.snackBarService.openPending({
              message: 'Your CV is being parsed... <br/> Please be patient. It may take a while.',
              type: SnackBarTypes.Notification
            });
            this.cvParseStateService.subscribeForResult(this.data.cvId);
          },
          error: (e: HttpErrorResponse) => {
            console.error('An error occurred', e);
            if(e.status === 409) {
              this.snackBarService.open({
                message: 'Your other cv is being parsed.',
                type: SnackBarTypes.ErrorAlt
              });
            } else {
              this.snackBarService.open({
                message: 'Error while parsing cv.',
                type: SnackBarTypes.ErrorAlt
              });
            }

            this.cvParseStateService.setParseState(PARSE_STATE.READY_TO_PARSE);
          },
          complete: () => {
            this.cdr.detectChanges();
          }
      });
  }

  closeDialog(resetParsingStatus?: boolean) {
    if(resetParsingStatus) {
      this.cvParseStateService.setParseState(PARSE_STATE.READY_TO_PARSE);
    }
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}

