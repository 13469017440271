import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  ContentReactionTypes,
  ContentTypes
} from '../../../../interfaces/reactions';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { RxState, selectSlice } from '@rx-angular/state';
import { LetModule } from '@rx-angular/template';
import { CommonModule } from '@angular/common';
import { My7nMaterialModule } from '../../../../modules/my7n-material.module';
import { HammerModule } from '@angular/platform-browser';
import { MediaQueryService } from '@my7n/ui';

export interface IReactionsPanelLocalState {
  panelVisible: boolean;
  liked: ContentReactionTypes;
  triggerBtnMouseHover: boolean;
  reactionsPanelMouseHover: boolean;
  isMobile: boolean;
}

const initialState: IReactionsPanelLocalState = {
  panelVisible: false,
  liked: undefined,
  triggerBtnMouseHover: false,
  reactionsPanelMouseHover: false,
  isMobile: false
};

@Component({
  standalone: true,
  imports: [LetModule, CommonModule, My7nMaterialModule, HammerModule],
  selector: 'reactions-button',
  templateUrl: './reactions-button.component.html',
  styleUrls: ['./reactions-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class ReactionsButtonComponent implements AfterViewInit, OnDestroy {
  @Input() contentId: string;
  @Input() contentType: ContentTypes;
  @Input()
  set liked(liked: ContentReactionTypes) {
    this.state.set({ liked });
  }
  @Output() reactionSave = new EventEmitter<ContentReactionTypes>();

  ContentReactionTypes = ContentReactionTypes;
  reactions: Array<ContentReactionTypes> = [
    ContentReactionTypes.LIKE,
    ContentReactionTypes.INSIGHTFUL,
    ContentReactionTypes.FUNNY,
    ContentReactionTypes.CELEBRATE
  ];
  overlayRef: OverlayRef;
  @ViewChild('reactionsPanel') templateRef: TemplateRef<TemplatePortal>;
  @ViewChild('trigger', { read: ElementRef }) trigger: ElementRef;

  ractionsPanelVisibility$ = this.state
    .select('panelVisible')
    .pipe(distinctUntilChanged());

  liked$ = this.state
    .select('liked')
    .pipe(distinctUntilChanged(), debounceTime(100));

  hammer: HammerManager;

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private state: RxState<IReactionsPanelLocalState>,
    private mediaQuery: MediaQueryService
  ) {
    this.state.set({ ...initialState });
  }

  isActive(reactionType: ContentReactionTypes): boolean {
    return this.state.get('liked') === reactionType;
  }

  ngAfterViewInit() {
    if (this.trigger.nativeElement) {
      this.attachOverlay();
      this.initLocalStateConnectionsAndEffects();
    }
  }

  ngOnDestroy() {
    this.detachOverlay();
    if (this.hammer) {
      this.hammer.destroy();
    }
  }

  private initLocalStateConnectionsAndEffects() {
    this.state.connect(
      'isMobile',
      this.mediaQuery.breakpoints$.pipe(
        map(
          (breakpoints) =>
            breakpoints.handsetPortrait || breakpoints.handsetLandscape
        )
      )
    );

    this.hammer = new Hammer(this.trigger.nativeElement);

    this.state.hold(this.state.select('isMobile'), (isMobile: boolean) => {
      if (isMobile) {
        if (this.hammer) {
          // destroy old listener and create new one
          this.hammer.destroy();
          this.hammer = new Hammer(this.trigger.nativeElement);
        }
        this.hammer.on('press', (event) => {
          this.state.set({ panelVisible: true });
        });
      } else {
        if (this.hammer) {
          this.hammer.destroy();
        }
      }
    });

    this.registerTriggerBtnHoverListeners();
    this.registerPanelHoverListeners();

    const hover$ = this.state.select().pipe(
      selectSlice(['triggerBtnMouseHover', 'reactionsPanelMouseHover']),
      debounceTime(300),
      map(
        (state) => state.triggerBtnMouseHover || state.reactionsPanelMouseHover
      )
    );

    const scroll$ = fromEvent(window, 'scroll');

    this.state.hold(scroll$, () => {
      this.state.set({ panelVisible: false });
    });

    this.state.hold(hover$, (isHover) => {
      if (isHover) {
        this.state.set({ panelVisible: true });
      } else {
        this.state.set({ panelVisible: false });
      }
    });
  }

  private registerTriggerBtnHoverListeners() {
    const isTriggerBtnHover$ = fromEvent(
      this.trigger.nativeElement,
      'mouseover'
    );

    this.state.hold(isTriggerBtnHover$, () => {
      if (!this.state.get('isMobile')) {
        this.state.set({
          triggerBtnMouseHover: true
        });
      }
    });

    const isTriggerBtnNotHover$ = fromEvent(
      this.trigger.nativeElement,
      'mouseleave'
    );
    this.state.hold(isTriggerBtnNotHover$, () => {
      if (!this.state.get('isMobile')) {
        this.state.set({
          triggerBtnMouseHover: false
        });
      }
    });
  }

  private registerPanelHoverListeners() {
    const isReactionsPanelHover$ = fromEvent(
      this.overlayRef.hostElement,
      'mouseover'
    );

    this.state.hold(isReactionsPanelHover$, () => {
      if(!this.state.get('isMobile')) {
        this.state.set({
          reactionsPanelMouseHover: true
        });
      }
    });

    const isReactionsPanelNotHover$ = fromEvent(
      this.overlayRef.hostElement,
      'mouseleave'
    );

    this.state.hold(isReactionsPanelNotHover$, () => {
      if(!this.state.get('isMobile')) {
        this.state.set({
          reactionsPanelMouseHover: false
        });
      }
    });
  }

  private detachOverlay() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

  private attachOverlay() {
    this.overlayRef = this.overlay.create({
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.trigger)
        .withPositions([
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom'
          }
        ])
        .withDefaultOffsetY(-10)
    });
    if (this.overlayRef) {
      let templatePortal = new TemplatePortal(
        this.templateRef,
        this.viewContainerRef
      );
      this.overlayRef.attach(templatePortal);
    }
  }

  like() {
    if (this.state.get('liked')) {
      this.reactionSave.emit(null);
      this.state.set({ liked: null });
    } else {
      this.reactionSave.emit(ContentReactionTypes.LIKE);
      this.state.set({ liked: ContentReactionTypes.LIKE });
    }
    this.state.set({ panelVisible: false });
  }

  react(reaction: ContentReactionTypes) {
    if (reaction !== this.state.get('liked')) {
      this.reactionSave.emit(reaction);
      this.state.set({ liked: reaction });
    }

    this.state.set({ panelVisible: false });
  }
}
