import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IProfileStrength, ProfileStrengthKeys } from '../interfaces/profile-strength';
import { CvCommonService } from './cv/cv-common.service';
import { takeUntil } from 'rxjs/operators';
import { IProfileStrengthUpdatePayload } from '../interfaces/profile-strength-update-payload';

@Injectable()
export class ProfileStrengthService implements OnDestroy {

  public static readonly DICTIONARY = {
    [ProfileStrengthKeys.Photo]: {
      label: 'Photo (optional)',
      order: 0
    },
    [ProfileStrengthKeys.ProfileSummary]: {
      label: 'Profile intro',
      order: 1
    },
    [ProfileStrengthKeys.WorkRoles]: {
      label: 'Work roles',
      order: 2
    },
    [ProfileStrengthKeys.MainCompetenceAreas]: {
      label: 'Main competence areas',
      order: 3
    },
    [ProfileStrengthKeys.CareerAndProjects]: {
      label: 'Career & projects',
      order: 4
    },
    [ProfileStrengthKeys.Skills]: {
      label: 'Skills',
      order: 5
    },
    [ProfileStrengthKeys.Languages]: {
      label: 'Languages',
      order: 6
    },
    [ProfileStrengthKeys.IndustryKnowledge]: {
      label: 'Industry knowledge',
      order: 7
    },
    [ProfileStrengthKeys.Education]: {
      label: 'Education, courses & certifications',
      order: 8
    },
    [ProfileStrengthKeys.AdditionalInformations]: {
      label: 'Additional information (optional)',
      order: 9
    }
  };

  private unsubscribe$: Subject<void> = new Subject<void>();
  private _profileStrength: BehaviorSubject<IProfileStrength> = new BehaviorSubject<IProfileStrength>(null);
  private _update: Subject<IProfileStrengthUpdatePayload> = new Subject<IProfileStrengthUpdatePayload>();
  private _activate: Subject<string> = new Subject<string>();

  changes$: Observable<IProfileStrengthUpdatePayload> = this._update.asObservable();
  activate$: Observable<string> = this._activate.asObservable();

  get minimumRequirementMet(): boolean {
    const strength = this._profileStrength.getValue();

    return !Object.keys(strength).some(key => {
      const value = strength[key];
      return value.IsDone === false && value.IsMandatory === true;
    });
  }

  /**
   * PLEASE NOTE
   * This service is created once per module instead of once per cv-view instance.
   * Due to mixed dependencies moving this to cv-view[providers] is very difficult
   *    - CvViewComponent contains profile-strength with depends on ProfileStrengthService (this is fine, ProfileStrengthService
   *      could be provided in cv-view if that would be the only case but...)
   *    - MinimumRequirementsDialogComponent contains profile-strength with depends on the same service but this cannot
   *      be loaded because service is provided by component and the Dialog itself is must be provided by NgModule,
   *      angular won't find the service in module and throw error
   */

  constructor(private cvCommonService: CvCommonService) {
    // Subscribe to changes which come from backend as response to cv updates
    this.cvCommonService.profileStrengthChange$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((payload: IProfileStrengthUpdatePayload) => {
      this.update(payload.strength);
    });
  }

  update(strength: IProfileStrength) {
    this._profileStrength.next(strength);
    this._update.next({strength});
  }

  activate(section: string) {
    this._activate.next(section);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }
}
