<div class="flex flex-col flex-start-stretch gap-12">
  <div class="files-upload-container flex flex-col flex-center-center" dragAndDropFile
    (fileDropped)="filesDropped($event)">
    <div class="typography-paragraph files-upload-upload-text flex flex-col flex-center-center">
      <div class="flex flex-col flex-center-center max-md:hidden">
        <span>{{ mainDescription }}</span>
        <span class="files-upload-upload-text-button">
          <input #filesUploadButton class="file-input" type="file" multiple />
          <span>{{ secondaryDescription }}</span>
          <button mat-button color="primary" type="button" [ngClass]="{ 'small-button': (mediaQueryService.breakpoints$ | async).ltMd }"
            (click)="filesUploadButton.click()">
            {{ filesUploadButtonLabel }}
          </button>
        </span>
      </div>
      <div class="flex flex-col flex-center-center max-sm:gap-6 gap-12 md:hidden">
        <img class="files-upload-upload-img" src="/assets/my7n-ui/icons/photo_upload.svg"
          (click)="filesUploadButton.click()" />
        <button mat-button type="button" color="primary" (click)="filesUploadButton.click()">
          Add files
        </button>
      </div>
    </div>
  </div>
  <div class="files-list form-group flex flex-col flex-start-start" *ngIf="files.length > 0">
    <mat-label>Added files</mat-label>
    <div class="files-list-container">
      <files-upload-element *ngFor="let file of files; let idx = index" [file]="file" [index]="idx"
        [currentValidationMessage]="currentValidationMessage" [controlErrorObject]="controlErrorObject"
        (removeFile)="removeFile($event)"></files-upload-element>
    </div>
  </div>
</div>
