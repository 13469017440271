import 'reflect-metadata';
import { FrontendFeature, FrontendFeaturesModule } from '../feature';
import { Feature } from '../interfaces/feature';

@FrontendFeaturesModule('CareerDevProgram')
export class CareerDevProgramFeatures {
  @FrontendFeature({
    summary: 'Default career development program page'
  })
  static readonly Default: Feature = 'career-dev-program';

  @FrontendFeature({
    summary: 'About career development program page'
  })
  static readonly About: Feature = 'career-dev-program/about';

  @FrontendFeature({
    summary: 'About career development program join'
  })
  static readonly Join: Feature = 'career-dev-program/join';
}
