import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { IEnvironment } from '../interfaces/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService implements IEnvironment {
  production: boolean;
  API_BASE: string;
  contentful: { spaceId: string; token: string; previewToken: string };

  /**
   * This service should be used for every code which needs unit tests.
   * Services may be replaced with mocks so any value may be passed to the test code.
   * Builtin `environment constant` is not connected with Angular Dependency Injection so there is no other way to mock it.
   */

  constructor() {
    Object.assign(this, environment);
  }
}
