import { ICvSkill } from '../interfaces/cv-skill';

export class CvSkill implements Partial<ICvSkill> {
  Id: number;
  Name: string;
  SkillId: number;
  CategoryName: string;
  CategoryId: number;

  constructor(name: string,
              skillId: number,
              categoryName: string,
              categoryId: number) {
    this.Name = name;
    this.SkillId = skillId;
    this.CategoryName = categoryName;
    this.CategoryId = categoryId;
  }
}
