import { Injectable } from '@angular/core';
import { CvParseService } from '../../../services/cv/cv-parse.service';
import { BehaviorSubject, Observable, map, Subscription, Subject } from 'rxjs';
import { distinctUntilChanged, repeat } from 'rxjs/operators';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

export enum PARSE_STATE {
    READY_TO_PARSE = 'ready_to_parse',
    UPLOADING = 'uploading',
    PARSING = 'parsing',
    PARSED = 'parsed'
}

const PARSING_RESULT_DELAY = 5000;

@Injectable()
export class CvParseStateService {
  private _parseState$: BehaviorSubject<PARSE_STATE> = new BehaviorSubject(PARSE_STATE.READY_TO_PARSE);
  private _parseResult$: Subject<{ response: HttpResponse<void>, cvId: number}> = new Subject();

  constructor(private cvParseService: CvParseService,
              private snackBarService: SnackBarService) { }

  private parsingResultSubscription$: Subscription;

  get parseState$(): Observable<PARSE_STATE> {
    return this._parseState$.asObservable().pipe(distinctUntilChanged());
  }

  get isParsing$(): Observable<boolean> {
    return this._parseState$.pipe(map(state => state === PARSE_STATE.PARSING));
  }

  get parseResult$(): Observable<{ response: HttpResponse<void>, cvId: number}> {
    return this._parseResult$.asObservable();
  }

  setParseState(state: PARSE_STATE) {
    this._parseState$.next(state);
  }

  subscribeForResult(cvId: number) {
    this.parsingResultSubscription$ = this.cvParseService.getParsingResult(cvId).pipe(repeat({ delay: PARSING_RESULT_DELAY })).subscribe(
      {
        next: (response: HttpResponse<void>) => {
          if(response.status === 200) {
            this.setParseState(PARSE_STATE.PARSED);
            this._parseResult$.next({ response, cvId });
            this.snackBarService.open({
              type: SnackBarTypes.Success,
              message: 'CV has been parsed successfully.'
            });
            this.unsubscribeFromResult();
          } else {
            console.info('[ParseService] No parsing result yet.');
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('[ParseService] Parsing request error.', err);
          this.snackBarService.open({
            message: 'Error while parsing cv.',
            type: SnackBarTypes.ErrorAlt
          });
          this._parseResult$.error(err);
          this.setParseState(PARSE_STATE.READY_TO_PARSE);
          this.unsubscribeFromResult();
        },
      }
    );
  }

  unsubscribeFromResult() {
    if(this.parsingResultSubscription$) {
      this.parsingResultSubscription$.unsubscribe();
    }
  }
}
