import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
// Interfaces
import { ICmsCard } from '../../../interfaces/cms-card';
import { CmsCardService } from './cms-card.service';
import { ContentTypes } from '../../../interfaces/reactions';
import { ReactionsService } from '../../../services/reactions.service';

@Component({
  selector: 'cms-card',
  templateUrl: './cms-card.component.html',
  styleUrls: ['./cms-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmsCardComponent implements OnInit {
  @Input() details: ICmsCard;
  linkPrefix: string;
  reactionsContentType: ContentTypes;

  constructor(
    private cmsCardService: CmsCardService,
    private reactionsService: ReactionsService
  ) {}

  ngOnInit() {
    this.linkPrefix = this.cmsCardService.getLinkPrefix(
      this.details.contentType
    );
    this.reactionsContentType =
      this.reactionsService.getContentTypeFromCmsContentType(
        this.details.contentType
      );
  }

  get hasTags(): boolean {
    return this.details.tags && this.details.tags.length > 0;
  }

  get hasVideo(): boolean {
    return !!this.details.headerVideoUrl;
  }

  defaultImageUrl =
    'assets/images/common/backgrounds/header-section-background-circles.png'; // @TODO change when

  stopPropagation($event) {
    // Prevent from double router navigate calling
    $event.stopPropagation();
    $event.stopImmediatePropagation();
  }
}
