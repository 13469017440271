import { LegalEntityCountry } from './legal-entity';

export const CMS_SPACE_ID = 'my7n-space'; // local space id for the CMS Proxy service

export interface ICmsAuthor {
  id: string;
  avatar: string;
  fullName: string;
}

export enum CmsContentTypes {
  AcademyItem = 'academyItem',
  AcademyVideo = 'academyVideo',
  AuthorItem = 'author',
  AcademyBookItem = 'book',
  AcademyOfferItem = 'academyOffer',
  Dictionary = 'dictionary',
  VideoUpdate = 'videoUpdate',
  News = 'news',
  BenefitItem = 'benefitItem'
}

export enum CmsDictionaryTypes {
  AcademyVideoLanguage = 'AcademyVideoLanguage',
  AcademyVideoCategory = 'AcademyVideoCategory',
  AcademyBookCategory = 'AcademyBookCategory',
  AcademyBookLanguage = 'AcademyBookLanguage',
  AcademyBookGenre = 'AcademyBookGenre'
}

export interface ICmsItem {
  id: string;
  tags?: Array<string>;
  headerImageUrl?: string;
  title: string;
  content: string;
  creationDate: Date;
  author: ICmsAuthor;
}

// Item types
export interface ICmsAcademyItem extends ICmsItem {
  headerVideoUrl?: string;
  countries: Array<LegalEntityCountry>;
}

export interface ICmsAcademyItemsQueryConfig {
  sortingMode?: CmsSortingModes;
}

// View interfaces
export interface ICmsViewMediaSize {
  width: number;
  height: number;
  ratio?: number;
}

export enum CmsSortingModes {
  NewestFirst = 'Newest First',
  OldestFirst = 'Oldest First'
}

export interface ICmsVideoUpdateItem {
  id: string;
  title: string;
  videoUrl: string;
  videoDate: Date;
  countries?: Array<LegalEntityCountry>;
}

export interface ICmsDictionaryItem {
  id: string;
  type: CmsDictionaryTypes;
  value: string;
  title: string;
  legalEntityCountries?: Array<LegalEntityCountry>;
}
