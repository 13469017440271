import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  HostListener
} from '@angular/core';
import { INewsBase, INewsList } from '../../../../interfaces/news';
import { INewsResponse } from '../../../../interfaces/news-response';
import { TimelineNewsFacadeService } from 'projects/my7n-app/src/services/facades/timeline-news-facade.service';
import { combineLatest, Observable, take, throwError } from 'rxjs';
import { INewsLoadedInfo } from 'projects/my7n-app/src/store/reducers/timeline/news/timeline-news.reducer';
import { isEqual } from 'lodash-es';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('newsList') public resolvedNewsList: INewsResponse;
  @HostListener('window:scroll') onScroll() {
    this.loadMoreIfAvailable();
  }

  /**
   * Distance in pixels. When overscrolled triggers news loading.
   * @type {number}
   */
  readonly loadMoreThreshold = 100;

  /**
   * Timeout identifier.
   * @type {number|null}
   */
  loadMoreTimeout = null;

  /**
   * Collection of news.
   */
  news$: Observable<INewsBase[]>;

  /**
   * News loading state..
   */
  newsLoading$: Observable<boolean>;
  /**
   * News loading more state.
   */
  newsLoadingMore$: Observable<boolean>;

  /**
   * News loading error state.
   */
   loadingError$: Observable<boolean>;

  /**
   * News loading more error state.
   */
   loadingMoreError$: Observable<boolean>;
  /**
   * News initiaLIZED state.
   */
  initialized$: Observable<boolean>;
  /**
   * Scroll value for restoring.
   */
  scrollY$: Observable<number>;
  /**
   * Info about loaded news.
   */
  loadedInfo$: Observable<INewsLoadedInfo>;
  /**
   * Info about last loaded news.
   */
  lastLoadedInfo: INewsLoadedInfo;
  /**
   * Flag if news in preview mode.
   */
  previewMode: boolean;
  /**
   * Id of previewed news.
   */
  previewNewsId: string;

  constructor(
    private hostElement: ElementRef,
    private timelineNewsFacadeService: TimelineNewsFacadeService,
    private activatedRoute: ActivatedRoute
  ) {}

  /**
   * Initializes component.
   * Saves required information got from news resolver.
   * Registers scroll event listener.
   */
  ngOnInit() {
    this.previewMode = this.activatedRoute.snapshot.queryParams['newsPreviewMode'] === 'true';
    this.previewNewsId = this.activatedRoute.snapshot.queryParams['previewNewsId'];

    this.news$ = this.timelineNewsFacadeService.news$;
    this.newsLoading$ = this.timelineNewsFacadeService.loading$;
    this.newsLoadingMore$ = this.timelineNewsFacadeService.loadingMore$;
    this.loadingError$ = this.timelineNewsFacadeService.loadingError$;
    this.loadingMoreError$ = this.timelineNewsFacadeService.loadingMoreError$;
    this.scrollY$ = this.timelineNewsFacadeService.scrollY$;
    this.loadedInfo$ = this.timelineNewsFacadeService.loadedInfo$;
    this.initialized$ = this.timelineNewsFacadeService.initialized$;

    this.initialized$.pipe(take(1)).subscribe(initialized => {
      if (!initialized) {
        this.timelineNewsFacadeService.queryNews(this.previewMode);
      }
    });
  }

  /**
   * Launches loader after view init when we have access to native element API.
   */
  ngAfterViewInit() {
    this.initialized$.pipe(take(1)).subscribe(initialized => {
      if (!initialized) {
        window.setTimeout(() => this.loadMoreIfAvailable());
      }
    });
  }

  /**
   * Loads more news if available.
   */
  loadMoreIfAvailable() {
    let rect;

    combineLatest([this.newsLoading$, this.newsLoadingMore$, this.loadedInfo$, this.loadingError$, this.initialized$]).pipe(take(1)).subscribe(([loading, loadingMore, loadedInfo, loadingError, initialized]) => {
      if (loading || loadingMore || (loadedInfo.newsCmsAllLoaded && loadedInfo.newsYammerAllLoaded) || loadingError || !initialized) {
        return null;
      }

      if (this.loadMoreTimeout) {
        window.clearTimeout(this.loadMoreTimeout);
      }

      this.loadMoreTimeout = window.setTimeout(() => {
        rect = this.hostElement.nativeElement.getBoundingClientRect();
        if (rect.bottom - this.loadMoreThreshold < window.innerHeight) {
          this.nextPage(loadedInfo).subscribe((moreError) => {
            if (!moreError) {
              this.loadMoreTimeout = null;
              this.loadMoreIfAvailable();
            }
          });
        }
      }, 50);
    });
  }

  /**
   * Gets new portion of news and adds them to class property.
   *
   * @returns {Promise<void>}
   */
  nextPage(loadedInfo: INewsLoadedInfo) {
    if (isEqual(this.lastLoadedInfo, loadedInfo)) {
      return throwError(() => false);
    }

    this.lastLoadedInfo = loadedInfo;
    this.timelineNewsFacadeService.queryMoreNews(this.previewMode);

    return this.loadingMoreError$;
  }

  trackById(index: number, news: INewsList) {
    return news.Id;
  }

  ngOnDestroy() {
    // @TODO do scroll litener angular way :)
    window.clearTimeout(this.loadMoreTimeout);
  }
}
