import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TimelineService } from '../services/timeline.service';
import { INewsList } from '../interfaces/news';
import { Router } from '@angular/router';
import { ResolveDecorator } from '../classes/resolve-decorator';

@Injectable()
export class NewsContentResolver implements Resolve<any> {

  constructor(private timelineService: TimelineService,
              private router: Router) {}

  news: INewsList[] = [];

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.timelineService.get(route.params.newsId).catch((error) => {
      console.error('[NewsContentResolver] There was a problem while getting news content with id', route.params.newsId);
      ResolveDecorator.handleError(error, this.router);
      return null;
    });
  }
}
